import React from 'react';
import WhatDoingItemsList from '../WhatDoingItemsList/WhatDoingItemsList';
import './WhatWeDoingContent.scss';
import { useTranslation } from 'react-i18next';

import arrowRight from '../../../../images/WhatWeDoing/arrow.svg'

const WhatWeDoingContent = ({title, itemsList, moveToPage, moveToPageIndex}) => {
  const { t } = useTranslation();

  return (
    <div className="what-doing__content">
      <span className="what-doing__content-title">{title}</span>
      <div className="what-doing__content-list">
        {
          itemsList.map(item => (
            <WhatDoingItemsList 
              {...item}
              key={item.id}
            />
          ))
        } 
      </div>
      <button onClick={() => moveToPage(moveToPageIndex)} className="what-doing__content-button">
        <img src={arrowRight} alt="" loading="lazy"/>
        <span>{t('SEE_MORE')}</span>
      </button>
    </div>
  )
}

export default WhatWeDoingContent