import React from 'react';
import './DubaiExpoTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import dubaiExpoSlide1 from '../../../../images/DubaiExpo/dubai-expo-tablet-1.png';
import dubaiExpoSlide4 from '../../../../images/DubaiExpo/dubai-gif.gif';
import { useTranslation } from 'react-i18next';

const DubaiExpoTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='dubai-expo-tablet'>
      <div className='dubai-expo-tablet__content'>
        <div className="main-swiper-tablet__title">
          {t('DUBAI_EXPO')}
          <span className="swiper-main-tablet__ampersand">&nbsp;&&nbsp;</span>
          <span className="swiper-title-tablet__underline">{t('DATA_2020')}</span>
        </div>

        <div className="main-swiper-tablet__wrapper dubai-expo-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet dubai-expo-tablet__swiper"
          >  
            <SwiperSlide>
              <div className="dubai-expo-tablet__wrapp">
                <img src={dubaiExpoSlide1} alt="dubai expo slide" className="dubai-expo-tablet__slide" loading="lazy"/>
                <div className="dubai-expo-tablet__slide">
                  <div className="dubai-expo-tablet__slide-wrapp">
                    <div className="dubai-expo-tablet__wrapp-block">
                      <div className="dubai-expo-tablet__wrapp-block-text">{t('dubai_expo.GLOBAL_EXHIBITION')}</div>
                    </div>
                    <img src={dubaiExpoSlide4} alt="dubai expo gif" loading="lazy"/>
                  </div>
                  <div className="dubai-expo-tablet__slide-last">
                    <div className="dubai-expo-tablet__slide-last-text">{t('dubai_expo.FOR_DTEK')}</div>
                  </div>
                </div>
                <div className="dubai-expo-tablet__title-bottom">{t('EXHIBITION_INSTALLATION')}</div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default DubaiExpoTablet