import React from 'react';
import './Team.scss';

import PersonCard from '../Desktop/PersonCard/PersonCard';
import { getTeamData } from '../teamData';
import { allAnimationsTeamPage, TEAM_WRAPP } from './utils/animations';
import { AnimationComponent } from '../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const Team = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();

  const team = getTeamData(t);
  
  return (
    <div className="team" id="team">
      <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsTeamPage} 
        prevPage={prevPage}
        currentPageIndex={currentPageIndex}
        pageIndex={5}
        isTransform
      >
        <div className="team__wrapp" id={TEAM_WRAPP}>
          <div className="team__content" id={TEAM_WRAPP}>
            {
              team.map(item => (
                <PersonCard
                  currentPageIndex={currentPageIndex}
                  key={item.id}
                  {...item}
                />
              ))
            }
          </div>
        </div>
      </AnimationComponent>
    </div>
  )
}

export default Team