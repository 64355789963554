import React from 'react';
import './SwiperPrintingDesign.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import printingDesignMain from '../../../../images/PrintingDesign/printing-design-main.svg'

import printingDesign1 from '../../../../images/PrintingDesign/printing-design-1.png';
import printingDesign2 from '../../../../images/PrintingDesign/printing-design-2.png';
import printingDesign3 from '../../../../images/PrintingDesign/printing-design-3.png';

import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsPrintingDesignPage, PRINTING_DESIGN_SWIPER_WRAPPER, PRINTING_DESIGN_TITLE } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperPrintingDesign = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsPrintingDesignPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={12}
        // isTransform
    >
        <span className="swiper-main__title printing-design__title" id={PRINTING_DESIGN_TITLE}>
         {t('PRINTING_DESIGN')} <span className="swiper-title__underline"></span> 
        </span>

        <div className="priting-design__wrapper" id={PRINTING_DESIGN_SWIPER_WRAPPER}>
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper"
          >
            <SwiperSlide>
              <div className="printing-design">
                <div className="printing-design__block">
                    <img src={printingDesign1} alt="" loading="lazy"/>
                    <div className="priting-design__block-wrapp">
                        <div className="printing-design__block-title">{t('ANNUAL_REPORT')}</div> 
                    </div>
                </div>
                <div className="printing-design__block">
                    <img src={printingDesign2} alt="" loading="lazy"/>
                    <div className="priting-design__block-wrapp">
                        <div className="printing-design__block-title">{t('POSM')}</div>
                    </div>
                </div>
                <div className="printing-design__block">
                    <img src={printingDesign3} alt="" loading="lazy"/>
                    <div className="priting-design__block-wrapp">
                        <div className="printing-design__block-title">{t('CALENDARS')}</div>
                    </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </AnimationComponent>
    </div>
  )
}

export default SwiperPrintingDesign