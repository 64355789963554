const animationCreationProd = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCreationProd");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCreationProd");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCreationProd");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCreationProd");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopCreationProd");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopCreationProd");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownCreationProd");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownCreationProd");
            }, 2000)
        },
    }
}


const animationCreationProdTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCreationProdTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCreationProdTitle");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCreationProdTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCreationProdTitle");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopCreationProdTitle");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopCreationProdTitle");
              currentElement.classList.remove("block");
            }, 1600)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownCreationProdTitle");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownCreationProdTitle");
            }, 1600)
        },
    }
}






export const CREATION_PROD_WRAPPER = 'CREATION_PROD_WRAPPER';
export const CREATION_PROD_TITLE = 'CREATION_PROD_TITLE';


export const allAnimationsCreationProdPage = {
    CREATION_PROD_WRAPPER: animationCreationProd(),
    CREATION_PROD_TITLE: animationCreationProdTitle(),
}
