import React from 'react';
import './WhatDoingContentTablet.scss';
import WhatDoingListTablet from '../WhatDoingListTablet/WhatDoingListTablet'
import { useTranslation } from 'react-i18next';

import arrowRight from '../../../../images/WhatWeDoing/arrow.svg'

const WhatDoingContentTablet = ({title, itemsList, moveToPageTablet}) => {
  const { t } = useTranslation();

  return (
    <div className="what-doing-tablet__content">
      <div className="what-doing-tablet__content-title">{title}</div>

      <div className="what-doing-tablet__content-list">
          {
            itemsList.map(item => (
              <WhatDoingListTablet
                {...item}
                key={item.id}
              />
            ))
          } 
      </div>
      <a href={moveToPageTablet} className="what-doing-tablet__content-button">
        <img src={arrowRight} alt="" loading="lazy"/>
        <span className="what-doing-tablet__content-button-text">{t('SEE_MORE')}</span>
      </a>
    </div>
  )
}

export default WhatDoingContentTablet