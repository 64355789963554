import React from 'react';
import './PageEnjoyTablet.scss';

import mainImg from '../../../images/Enjoy/main-img-tablet.svg';
import rectanglesBottom from '../../../images/Enjoy/rectangles-bottom-tablet.svg';
import logoFooter from '../../../images/be-brave.png';
import scretch from '../../../images/Enjoy/scretch-tablet.svg';
import fenix from '../../../images/Enjoy/fenix.svg';

import { useTranslation } from 'react-i18next';

const PageEnjoyTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='enjoy-tablet'>
      <div className='enjoy-tablet__title'>
        <span className='enjoy-tablet__title-text'>
          <b style={{color: "#FDB813"}}>E</b>NJOY<br/> 
          <b style={{color: "#FDB813"}}>Y</b>OUR<br/>
          <b style={{color: "#FDB813"}}>E</b>XPERIENCE</span>
        <img src={scretch} alt="scretch" className='enjoy-tablet__title-scretch' loading="lazy"/>
      </div>
      <img src={mainImg} alt="mainImg" className='enjoy-tablet__main-img' loading="lazy"/>
      <img src={rectanglesBottom} alt="rectangles bottom" className='enjoy-tablet__rectangles-bottom' loading="lazy"/>
      <img src={logoFooter} alt="logo footer" className='enjoy-tablet__logo-footer' loading="lazy"/>

      <div className="copyright-block">
           <div className="copyright">{t('copyright.COPYRIGHT')}</div>
           <a href="https://fnx.com.ua/ua/ecommerce-development" target="_blank" className="developed" rel="noreferrer">
              <span>{t('copyright.DEVELOPED')}</span>
              <img src={fenix} alt="fenix" />
           </a>
        </div>
    </div>
  )
}

export default PageEnjoyTablet