import React from 'react';
import './CreationPacksTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";
import { useTranslation } from 'react-i18next';

import creationPacksSlide1 from '../../../../images/CreationPacks/creation-packs-tablet-1.png';
import creationPacksSlide2 from '../../../../images/CreationPacks/creation-packs-tablet-2.png';

const CreationPacksTablet = () => {
  const { t } = useTranslation();

  return (
    <div className="creation-packs-tablet">
      <div className="creation-packs-tablet__content">
        <div className="main-swiper-tablet__title creation-packs-tablet__content-title">
           <span>{t('CREATION')} <span className="swiper-main-tablet__ampersand"> {t('AND')} </span>{t('creation_packs.PRODUCTION')}</span>
           <span>{t('creation_packs.CONFERENCE_WELCOME')}<span className="swiper-title-tablet__underline"></span></span> 
          
        </div>

        <div className="main-swiper-tablet__wrapper creation-packs-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet"
          >  
            <SwiperSlide>
              <div className="creation-packs-tablet__wrapp">
                <img src={creationPacksSlide1} alt="creation packs slide" className="creation-packs-tablet__slide" loading="lazy"/>
                <img src={creationPacksSlide2} alt="creation packs slide" className="creation-packs-tablet__slide" loading="lazy"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default CreationPacksTablet