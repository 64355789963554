import React from 'react';
import './VideoProduction.scss';

import videoProd1 from '../../../images/VideoProd/video-prod-1.png';
import videoProd2 from '../../../images/VideoProd/video-prod-2.png';
import {ReactComponent as Play} from '../../../images/play.svg';
import arrowRight from '../../../images/ContentAnimations/arrow-right.svg';

import { AnimationComponent } from '../../AnimationComponent';
import { allAnimationsVideoProdPage, VIDEO_PROD_BUTTON, VIDEO_PROD_TITLE, VIDEO_PROD_WRAPPER } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const VideoProduction = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
      <AnimationComponent         
        animationAction={animationAction} 
        animation={allAnimationsVideoProdPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={18}
        isTransform
        isMultiAnimation
      >
      <span className="video-prod__title" id={VIDEO_PROD_TITLE}>{t('video_prod_page.TITLE')} <span className="swiper-title__underline">{t('video_prod_page.TITLE_VIDEOS')}</span> </span>
        <div className="video-prod__wrapper" id={VIDEO_PROD_WRAPPER}>
          <a href="https://www.youtube.com/watch?v=VbDoLnsFUQ8&list=PLPFysJtDmSWokC3ZHq2JZ9dVML93esMu8&index=6" target="_blank" rel="noreferrer" className="video-prod__wrapper-img-link">
            <img src={videoProd1} alt="video production" className="video-prod__wrapper-img" loading="lazy"/>
            <div className="video-prod__wrapper-play-img">
              <Play className="video-prod__play-img"/>
            </div>
          </a>
          <a href="https://youtu.be/uuUi99pWc9U" target="_blank" rel="noreferrer"  className="video-prod__wrapper-img-link">
            <img src={videoProd2} alt="video production" className="video-prod__wrapper-img" loading="lazy"/>
            <div className="video-prod__wrapper-play-img">
              <Play className="video-prod__play-img"/>
            </div>
          </a>
        </div>
        <a href='https://www.youtube.com/watch?v=uuUi99pWc9U&list=PLPFysJtDmSWokC3ZHq2JZ9dVML93esMu8' target="_blank" className="video-prod__button-watch" id={VIDEO_PROD_BUTTON} rel="noreferrer">
          <img src={arrowRight} alt="arrow"  className="video-prod__button-arrow" loading="lazy"/>
          <span className="video-prod__button-text">{t('WATCH_ALL')}</span> 
        </a>
      </AnimationComponent>
    </div>
  )
}

export default VideoProduction