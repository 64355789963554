const animationCorporateBook = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCorporateBook");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCorporateBook");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCorporateBook");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCorporateBook");
            }, 2000)
        },
    }
}


const animationCorporateBookTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCorporateBookTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCorporateBookTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCorporateBookTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCorporateBookTitle");
            }, 1800)
        },
    }
}





export const CORPORATE_BOOK_TITLE = 'CORPORATE_BOOK_TITLE';
export const CORPORATE_BOOK_SWIPER_WRAPPER = 'CORPORATE_BOOK_SWIPER_WRAPPER';


export const allAnimationsCorporateBookPage = {
    CORPORATE_BOOK_SWIPER_WRAPPER: animationCorporateBook(),
    CORPORATE_BOOK_TITLE: animationCorporateBookTitle(),

}
