import React from 'react';
import './ContentAnimations.scss';
import { AnimationComponent } from '../../AnimationComponent';

import contentAnimation1 from '../../../images/ContentAnimations/content-animations-1.png';
import contentAnimation2 from '../../../images/ContentAnimations/content-animations-2.png';
import contentAnimation3 from '../../../images/ContentAnimations/content-animations-3.png';
import contentAnimation4 from '../../../images/ContentAnimations/content-animations-4.jpg';
import contentAnimation5 from '../../../images/ContentAnimations/content-animations-5.jpg';
import contentAnimation6 from '../../../images/ContentAnimations/content-animations-6.svg';
import arrowRight from '../../../images/ContentAnimations/arrow-right.svg';

import { allAnimationsContentAnimationsPage, CONTENT_ANIMATION_TITLE, CONTENT_ANIMATION_WRAPPER } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const ContentAnimations = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();

  return (
    <div>
      <AnimationComponent          
        animationAction={animationAction} 
        animation={allAnimationsContentAnimationsPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={16}
        isTransform
        isMultiAnimation
      >

        <span className="content-animations__title" id={CONTENT_ANIMATION_TITLE}>{t('visual_brand_page.TITLE')} <span className="swiper-title__underline">{t('visual_brand_page.TITLE_ANIMATION')}</span></span>

        <div className="content-animations__wrapper" id={CONTENT_ANIMATION_WRAPPER}>
          <div className="content-animations__content">
              <div className="content-animations__block">
                <img src={contentAnimation1} alt="content animation" className="content-animation__img" loading="lazy"/>
                <div className="content-animation__rhomb"></div>
                <a href="https://www.youtube.com/watch?v=ii4x0C2XRpI&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u&index=5" target="_blank" rel="noreferrer">
                  <img src={contentAnimation2} alt="content animation" className="content-animation__img" loading="lazy"/>
                </a>
              </div>
            <div className="content-animations__block">
              <img src={contentAnimation4} alt="content animation" className="content-animation__img" loading="lazy"/>
              <div className="content-animation__rhomb"></div>
              <a href="https://www.youtube.com/watch?v=Bwp3yH1RIzE&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u&index=2" target="_blank" rel="noreferrer">
                <img src={contentAnimation3} alt="content animation" className="content-animation__img" loading="lazy"/>
              </a>
            </div>
            <div className="content-animations__block">
              <img src={contentAnimation5} alt="content animation" className="content-animation__img" loading="lazy"/>
              <div className="content-animation__rhomb"></div>
              <div className="content-animation__block-watch">
                <a href="https://www.youtube.com/watch?v=mF4HM4GsylI&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u&index=3" target="_blank" rel="noreferrer">
                  <img src={contentAnimation6} alt="content animation" className="content-animation__img-last" loading="lazy"/>
                </a>
                <a href='https://www.youtube.com/watch?v=qn470XWkk_o&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u' target="_blank" className="content-animation__watch-button" rel="noreferrer">
                  <img src={arrowRight} alt="content animation" className="content-animation__watch-arrow" loading="lazy"/>
                  {t('visual_brand_page.WATCH_ALL')}
                </a>
              </div>
            </div>
          </div>
        </div>

      </AnimationComponent>
    </div>
  )
}

export default ContentAnimations