import React from 'react';
import './SwiperDesign.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";
import designInstallationMain from '../../../../images/DesignSwiper/design-installation-main.svg'

import swiperDesign1 from '../../../../images/DesignSwiper/design-swiper-1.jpg';
import swiperDesign2 from '../../../../images/DesignSwiper/design-swiper-2.jpg';
import { allAnimationsDesignInstallationPage, DESIGN_INSTALLATION_DESCR_BOTTOM, DESIGN_INSTALLATION_SWIPER_WRAPPER, DESIGN_INSTALLATION_TITLE } from './utils/animations';
import { AnimationComponent } from '../../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const SwiperDesign = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsDesignInstallationPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={14}
    >
        <span className="swiper-main__title design-installation__title" id={DESIGN_INSTALLATION_TITLE}>
          {t('DESIGNS')} 
          <span className="swiper-main__ampersand"> {t('AND')}  </span><span className="swiper-title__underline">{t('INSTALLATIONS')}</span> 
        </span>

        <div className="main-swiper__wrapper design-installation__wrapper" id={DESIGN_INSTALLATION_SWIPER_WRAPPER}>
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper"
          >
            <SwiperSlide>
              <div className="swiper-design">
                <img src={swiperDesign1} alt="design installation" className="swiper-design-slide" loading="lazy"/>
                <img src={swiperDesign2} alt="design installation" className="swiper-design-slide" loading="lazy"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <span className="swiper-design__descr" id={DESIGN_INSTALLATION_DESCR_BOTTOM}>{t('PHOTO_ZONES')}</span>
      </AnimationComponent>
    </div>
  )
}

export default SwiperDesign