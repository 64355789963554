const animationCreationProd = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCreationsPacks");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCreationsPacks");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCreationsPacks");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCreationsPacks");
            }, 2000)
        },
    }
}


const animationCreationProdTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCreationsPacksTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCreationsPacksTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCreationsPacksTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCreationsPacksTitle");
            }, 1800)
        },
    }
}





export const CREATIONS_PACKS_TITLE = 'CREATIONS_PACKS_TITLE';
export const CREATIONS_PACKS_SWIPER_WRAPPER = 'CREATIONS_PACKS_SWIPER_WRAPPER';


export const allAnimationsCreationsPacksPage = {
    CREATIONS_PACKS_TITLE: animationCreationProdTitle(),
    CREATIONS_PACKS_SWIPER_WRAPPER: animationCreationProd(),

}
