import React from 'react';
import './PageRocheTablet.scss';

// import phone from '../../../images/Roche/phone-tablet.svg';
import rhomb from '../../../images/Roche/rhomb-tablet.svg';
import phone from '../../../images/Roche/phone-tablet.png';
import { useTranslation } from 'react-i18next';

const PageRocheTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='roche-tablet'>
      <div className='roche-tablet__top-content'>
        <div className='roche-tablet__titles'>
          <div className='roche-tablet__titles-main'>{t('roche_page.LEARNING_PLATFORM')}</div>
          <div className='roche-tablet__titles-subtitle'>{t('roche_page.ROCHE')}</div>
        </div>
        <div className='roche-tablet__text'>
          <span>{t('roche_page.TEXT_LINE_1')} </span>
          <span>{t('roche_page.TEXT_LINE_2')}</span>
        </div>
      </div>

      <img src={phone} alt="phone" className='roche-tablet__phone' loading="lazy"/>
      <img src={rhomb} alt="rhomb" className='roche-tablet__rhomb' loading="lazy"/>
    </div>
  )
}

export default PageRocheTablet