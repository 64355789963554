const animationWebsitesLaptops = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWebsitesLaptops");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenWebsitesLaptops");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWebsitesLaptops");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showWebsitesLaptops");
            }, 1800)
        },
    }
}

const animationWebsitesTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWebsitesTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenWebsitesTitle");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWebsitesTitle");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showWebsitesTitle");
            }, 1800)
        },
    }
}

const animationWebsitesBorderRhomb = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWebsitesBorderRhomb");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenWebsitesBorderRhomb");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWebsitesBorderRhomb");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showWebsitesBorderRhomb");
            }, 1800)
        },
    }
}

const animationWebsitesWaves = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWebsitesWaves");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenWebsitesWaves");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWebsitesWaves");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showWebsitesWaves");
            }, 1800)
        },
    }
}

const animationWebsitesDots = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWebsitesDots");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenWebsitesDots");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWebsitesDots");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showWebsitesDots");
            }, 1800)
        },
    }
}


const animationWebsitesStrokes = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWebsitesStrokes");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenWebsitesStrokes");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWebsitesStrokes");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showWebsitesStrokes");
            }, 1800)
        },
    }
}




export const WEBSITES_RHOMB_BORDER = 'WEBSITES_RHOMB_BORDER';
export const WEBSITES_TITLE = 'WEBSITES_TITLE';
export const WEBSITES_LAPTOPS = 'WEBSITES_LAPTOPS';
export const WEBSITES_DOTS = 'WEBSITES_DOTS';
export const WEBSITES_WAVES = 'WEBSITES_WAVES';
export const WEBSITES_STROKES = 'WEBSITES_STROKES';


export const allAnimationsWebsitesPage = {
    WEBSITES_LAPTOPS: animationWebsitesLaptops(),
    WEBSITES_TITLE: animationWebsitesTitle(),
    WEBSITES_RHOMB_BORDER: animationWebsitesBorderRhomb(),
    WEBSITES_WAVES: animationWebsitesWaves(),
    WEBSITES_DOTS: animationWebsitesDots(),
    WEBSITES_STROKES: animationWebsitesStrokes(),
}