import React from 'react';
import './PrintingDesignTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import printingDesignSlide2 from '../../../../images/PrintingDesign/printing-design-1.png';
import printingDesignSlide1 from '../../../../images/PrintingDesign/printing-design-2.png';
import printingDesignSlide3 from '../../../../images/PrintingDesign/printing-design-3.png';
import { useTranslation } from 'react-i18next';

const PrintingDesignTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='printing-design-tablet'>
      <div className='printing-design-tablet__content'>
        <div className="main-swiper-tablet__title">
          {t('PRINTING')}
          <span className="swiper-main-tablet__ampersand">&nbsp;{t('AND')}&nbsp;</span>
          <span className="swiper-title-tablet__underline">{t('DESIGNS')}</span>
        </div>

        <div className="main-swiper-tablet__wrapper printing-design-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet printing-design-tablet__swiper"
          >  
            <SwiperSlide>
              <div className="printing-design-tablet__wrapp">
                <img src={printingDesignSlide1} alt="printing desing" className="printing-design-tablet__slide" loading="lazy"/>
                <div className="printing-design-tablet__title-bottom">{t('ANNUAL_REPORT')}</div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="printing-design-tablet__wrapp">
                <img src={printingDesignSlide2} alt="printing desing" className="printing-design-tablet__slide" loading="lazy"/>
                <div className="printing-design-tablet__title-bottom">{t('POSM')}</div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="printing-design-tablet__wrapp">
                <img src={printingDesignSlide3} alt="printing desing" className="printing-design-tablet__slide" loading="lazy"/>
                <div className="printing-design-tablet__title-bottom">{t('CALENDARS')}</div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default PrintingDesignTablet