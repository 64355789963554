const animationVideoProd = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenVideoProd");
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenVideoProd");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showVideoProd");
            currentElement.classList.add("flex");
    
            setTimeout(() => {
                currentElement.classList.remove("showVideoProd");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopVideoProd");

            setTimeout(() => {
              currentElement.classList.remove("transformToTopVideoProd");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownVideoProd");

            setTimeout(() => {
              currentElement.classList.remove("transformToDownVideoProd");
            }, 2000)
        },
    }
}

const animationVideoProdTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenVideoProdTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenVideoProdTitle");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showVideoProdTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showVideoProdTitle");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopVideoProdTitle");
    
            setTimeout(() => {
              currentElement.classList.remove("transformToTopVideoProdTitle");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownVideoProdTitle");

            setTimeout(() => {
              currentElement.classList.remove("transformToDownVideoProdTitle");
            }, 2000)
        },
    }
}


const animationVideoProdButton = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenVideoProdButton");
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenVideoProdButton");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showVideoProdButton");
            currentElement.classList.add("flex");
    
            setTimeout(() => {
                currentElement.classList.remove("showVideoProdButton");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopVideoProdButton");

            setTimeout(() => {
              currentElement.classList.remove("transformToTopVideoProdButton");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownVideoProdButton");

            setTimeout(() => {
              currentElement.classList.remove("transformToDownVideoProdButton");
            }, 2000)
        },
    }
}

export const VIDEO_PROD_TITLE = 'VIDEO_PROD_TITLE';
export const VIDEO_PROD_WRAPPER = 'VIDEO_PROD_WRAPPER';
export const VIDEO_PROD_BUTTON = 'VIDEO_PROD_BUTTON';


export const allAnimationsVideoProdPage = {
    VIDEO_PROD_TITLE: animationVideoProdTitle(),
    VIDEO_PROD_WRAPPER: animationVideoProd(),
    VIDEO_PROD_BUTTON: animationVideoProdButton(),
}
