const animationTitleConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("transformToTopTitleConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("transformToTopTitleConceptDev");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToDownTitleConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("transformToDownTitleConceptDev");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("hiddenTitleConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("hiddenTitleConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("showTitleConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("showTitleConceptDev");
            }, 2000)
        },
    }
}


const animationTopRectanglesConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenTopRectanglesConceptDev");
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenTopRectanglesConceptDev");
            }, 1500)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showTopRectanglesConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showTopRectanglesConceptDev");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopRectanglesTopConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopRectanglesTopConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownRectanglesTopConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownRectanglesTopConceptDev");
            }, 2000)
        },
    }
}


const animationBorderGrayConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenBorderGrayConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenBorderGrayConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showBorderGrayConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showBorderGrayConceptDev");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopBorderGrayConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopBorderGrayConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownBorderGrayConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownBorderGrayConceptDev");
            }, 2000)
        },
    }
}


const animationRectanglesBottomConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenRectanglesBottomConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenRectanglesBottomConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showRectanglesBottomConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showRectanglesBottomConceptDev");
            }, 2000)
        },
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopRectanglesBottomConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopRectanglesBottomConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownRectanglesBottomConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownRectanglesBottomConceptDev");
            }, 2000)
        },
    }
}


const animationScretchConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenScretchConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenScretchConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showScretchConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showScretchConceptDev");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopScretchConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopScretchConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownScretchConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownScretchConceptDev");
            }, 2000)
        },
    }
}


const animationLogoFooterConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenFooterLogoConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenFooterLogoConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showFooterLogoConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showFooterLogoConceptDev");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopLogoFooterConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopLogoFooterConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownLogoFooterConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownLogoFooterConceptDev");
            }, 2000)
        },
    }
}


const animationPlayArrowsConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenPlayArrowsConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenPlayArrowsConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showPlayArrowsConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showPlayArrowsConceptDev");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopPlayArrowsConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopPlayArrowsConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownPlayArrowsConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownPlayArrowsConceptDev");
            }, 2000)
        },
    }
}


const animationBorderYellowConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenBorderYellowConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenBorderYellowConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showBorderYellowConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showBorderYellowConceptDev");
            }, 2000)
        },
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopBorderYellowConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopBorderYellowConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownBorderYellowConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownBorderYellowConceptDev");
            }, 2000)
        },
    }
}


const animationDotsConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDotsConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDotsConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDotsConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDotsConceptDev");
            }, 2000)
        },
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopDotsConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopDotsConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
        transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownDotsConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownDotsConceptDev");
            }, 2000)
        },
    }
}


const animationStrokesConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenStrokesConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenStrokesConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showStrokesConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showStrokesConceptDev");
            }, 2000)
        },
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopStrokesConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopStrokesConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
        transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownStrokesConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownStrokesConceptDev");
            }, 2000)
        },
    }
}


const animationWavesConceptDev = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWavesConceptDev");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenWavesConceptDev");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWavesConceptDev");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showWavesConceptDev");
            }, 2000)
        },
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopWavesConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopWavesConceptDev");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
        transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownWavesConceptDev");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownWavesConceptDev");
            }, 2000)
        },
    }
}





export const CONCEPT_DEV_RECTANGLES_TOP = 'CONCEPT_DEV_RECTANGLES_TOP';
export const CONCEPT_DEV_BORDER_GRAY = 'CONCEPT_DEV_BORDER_GRAY';
export const CONCEPT_DEV_BORDER_YELLOW = 'CONCEPT_DEV_BORDER_YELLOW';
export const CONCEPT_DEV_TITLE = 'CONCEPT_DEV_TITLE';
export const CONCEPT_DEV_PLAY_ARROWS = 'CONCEPT_DEV_PLAY_ARROWS';
export const CONCEPT_DEV_DOTS = 'CONCEPT_DEV_DOTS';
export const CONCEPT_DEV_STROKES = 'CONCEPT_DEV_STROKES';
export const CONCEPT_DEV_SCRATCH = 'CONCEPT_DEV_SCRATCH';
export const CONCEPT_DEV_WAVES = 'CONCEPT_DEV_WAVES';
export const CONCEPT_DEV_LOGO_FOOTER = 'CONCEPT_DEV_LOGO_FOOTER';
export const CONCEPT_DEV_RECTANGLES_BOTTOM = 'CONCEPT_DEV_RECTANGLES_BOTTOM';


export const allAnimationsConceptDevPage = {
    CONCEPT_DEV_TITLE: animationTitleConceptDev(),
    CONCEPT_DEV_RECTANGLES_TOP: animationTopRectanglesConceptDev(),
    CONCEPT_DEV_BORDER_GRAY: animationBorderGrayConceptDev(),
    CONCEPT_DEV_RECTANGLES_BOTTOM: animationRectanglesBottomConceptDev(),
    CONCEPT_DEV_PLAY_ARROWS: animationPlayArrowsConceptDev(),
    CONCEPT_DEV_LOGO_FOOTER: animationLogoFooterConceptDev(),
    CONCEPT_DEV_SCRATCH: animationScretchConceptDev(),
    CONCEPT_DEV_BORDER_YELLOW: animationBorderYellowConceptDev(),
    CONCEPT_DEV_DOTS: animationDotsConceptDev(),
    CONCEPT_DEV_WAVES: animationWavesConceptDev(),
    CONCEPT_DEV_STROKES: animationStrokesConceptDev(),
}
