import React from 'react';
import { AnimationComponent } from '../../AnimationComponent';
import './MarketingServices.scss';

import scretch from '../../../images/ConceptDev/scretch.svg';
import playArrows from '../../../images/ConceptDev/play-arrows.svg';
import logo from '../../../images/MarketingServices/marketing-services-logo.svg';
import sculptureBlock from '../../../images/MarketingServices/marketing-services-sculpture-block.svg';
import { allAnimationsMarketingServicesPage,
         MARKETING_SERV_SCUPLTURE, 
         MARKETING_SERV_BORDER_GRAY,
         MARKETING_SERV_TITLE,
         MARKETING_SERV_TOP_RECTANGLES,
         MARKETING_SERV_LOGO_FOOTER,
         MARKETING_SERV_PLAY_ARROWS,
         MARKETING_SERV_SCRETCH,
         MARKETING_SERV_RECTANGLES_BOTTOM
      } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const MarketingServices = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();

  return (
    <div>
      <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsMarketingServicesPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={19}
        isTransform
      >

        <div className="marketing-services__rectangles" id={MARKETING_SERV_TOP_RECTANGLES}>
          <div className="marketing-services__rectangle-gradient"></div>
          <div className="marketing-services__rectangle-border"></div>
        </div>

        <div className="marketing-services__border-gray" id={MARKETING_SERV_BORDER_GRAY}></div>

        <img src={sculptureBlock} alt="sculptureBlock" className="marketing-services__sculpture" id={MARKETING_SERV_SCUPLTURE} loading="lazy"/>

        <span className="marketing-services__title" id={MARKETING_SERV_TITLE}>
        {t('marketing_serv.TITLE_MARKETING')} <br />{t('marketing_serv.TITLE_SERVICES')}
        </span>

        <img src={playArrows} alt="play arrows" className="marketing-services__play-arrows" id={MARKETING_SERV_PLAY_ARROWS} loading="lazy"/>

        <img src={scretch} alt="scretch" className="marketing-services__scretch" id={MARKETING_SERV_SCRETCH} loading="lazy"/>

        <img src={logo} alt="logo" className="marketing-services__logo" id={MARKETING_SERV_LOGO_FOOTER} loading="lazy"/>

        <div className="marketing-services__rectangles-bottom" id={MARKETING_SERV_RECTANGLES_BOTTOM}>
          <div className="marketing-services__rectangle-gradient-bottom">
            <div className="marketing-services__rectangle-border-bottom"></div>
          </div>
         </div>

      </AnimationComponent>
    </div>
  )
}

export default MarketingServices