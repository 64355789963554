import React from 'react';
import './WhoContentText.scss';
import ukraine from '../../../../images/WhoWeAre/ukraine.svg'
import { useTranslation } from 'react-i18next';

const WhoContentText = ({id}) => {
  const { t } = useTranslation();

  return (
    <div className="who-content__text" id={id}>
      <span className="who-content__text-line">{t('who_are_page.TEXT_LINE_1')}</span>
      <div className="who-content__text-line">
        {t('who_are_page.TEXT_LINE_2')}
        <img src={ukraine} alt="flag of Ukraine" className="who-ukraine" loading="lazy"/> 
        {t('who_are_page.TEXT_LINE_3')}</div>
      <span className="who-content__text-line">{t('who_are_page.TEXT_LINE_4')}</span>
      <span className="who-content__text-line">{t('who_are_page.TEXT_LINE_5')}</span>
    </div>
  )
}

export default WhoContentText