import React from 'react';
import './whoAre.scss';

import whoare from '../../../images/WhoWeAre/who-we-are.svg';
import playArrows from '../../../images/WhoWeAre/play-arrows.svg';

import WhoContentText from '../Desktop/WhoContentText/WhoContentText';
import WhoTop from '../Desktop/WhoTop/WhoTop';
import { AnimationComponent } from '../../AnimationComponent';
import { allAnimationsInWhoArePage, WHO_CONTENT_TEXT, WHO_LOGO_FOOTER, WHO_PLAY_ARROWS, WHO_TOP } from '../Desktop/utils/animations';
// import { useTranslation } from 'react-i18next';

export const WhoAre = ({currentPageIndex, animationAction, prevPage}) => {
  // const { t } = useTranslation();
  
  return (
    <div className="who">
      <AnimationComponent 
        animationAction={animationAction} 
        animation={allAnimationsInWhoArePage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={2}
      >
        <WhoTop id={WHO_TOP}/>

        <WhoContentText id={WHO_CONTENT_TEXT}/>

        <img src={playArrows} alt="play-arrows" className="who__play-arrows" id={WHO_PLAY_ARROWS} loading="lazy"/>

        <img src={whoare} alt="logo-footer" className="who__logo-footer" id={WHO_LOGO_FOOTER} loading="lazy"/>

      </AnimationComponent>
    </div>
  )
}

export default WhoAre
