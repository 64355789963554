const animationContentAnimation = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenContentAnimation");
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenContentAnimation");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showContentAnimation");
            currentElement.classList.add("flex");
    
            setTimeout(() => {
                currentElement.classList.remove("showContentAnimation");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopContentAnimation");
    
            setTimeout(() => {
              currentElement.classList.remove("transformToTopContentAnimation");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownContentAnimation");

            setTimeout(() => {
              currentElement.classList.remove("transformToDownContentAnimation");
            }, 2000)
        },
    }
}

const animationContentAnimationTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenContentAnimationTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenContentAnimationTitle");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showContentAnimationTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showContentAnimationTitle");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopContentAnimationTitle");
    
            setTimeout(() => {
              currentElement.classList.remove("transformToTopContentAnimationTitle");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownContentAnimationTitle");

            setTimeout(() => {
              currentElement.classList.remove("transformToDownContentAnimationTitle");
            }, 2000)
        },
    }
}


export const CONTENT_ANIMATION_TITLE = 'CONTENT_ANIMATION_TITLE';
export const CONTENT_ANIMATION_WRAPPER = 'CONTENT_ANIMATION_WRAPPER';


export const allAnimationsContentAnimationsPage = {
    CONTENT_ANIMATION_TITLE: animationContentAnimationTitle(),
    CONTENT_ANIMATION_WRAPPER: animationContentAnimation(),
}
