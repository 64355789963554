const animationOurContactContent = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenContactUsContent");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenContactUsContent");
            }, 1500)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showContactUsContent");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showContactUsContent");
            }, 1800)
        },
    }
}


const animationOurContactTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenContactUsTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenContactUsTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showContactUsTitle");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showContactUsTitle");
            }, 1800)
        },
    }
}


const animationOurContactRectanglesTop = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenContactUsRectanglesTop");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenContactUsRectanglesTop");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showContactUsRectanglesTop");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showContactUsRectanglesTop");
            }, 1800)
        },
    }
}


  export const OUR_CONTACTS_TITLE = 'OUR_CONTACTS_TITLE';
  export const OUR_CONTACTS_CONTENT = 'OUR_CONTACTS_CONTENT';
  export const OUR_CONTACTS_RECTANGLES_TOP = 'OUR_CONTACTS_RECTANGLES_TOP';

  
  export const allAnimationsOurContactsPage = {
    OUR_CONTACTS_CONTENT: animationOurContactContent(),
    OUR_CONTACTS_TITLE: animationOurContactTitle(),
    OUR_CONTACTS_RECTANGLES_TOP: animationOurContactRectanglesTop(),
  }
  