import React from 'react';
import './SwiperLogoIdentity.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

// import logoIdentityMainDEMO from '../../../../images/LogoIdentity/demoGIF.svg'
import logoIdentityMain from '../../../../images/LogoIdentity/logo-identity.svg'
// import logoIdentityMainSlide2 from '../../../../images/LogoIdentity/logo-identity-slide2.svg'

// import logoIdentity1 from '../../../images/LogoIdentity/logo-identity-1.png';
// import logoIdentity2 from '../../../images/LogoIdentity/logo-identity-2.png';
// import logoIdentity3 from '../../../images/LogoIdentity/logo-identity-3.png';
// import logoIdentity4 from '../../../images/LogoIdentity/logo-identity-4.png';
import logoIdentity5 from '../../../../images/LogoIdentity/logo-identity-5.png';
// import logoIdentity6 from '../../../../images/LogoIdentity/logo-identity-6.png';
import logoIdentity7 from '../../../../images/LogoIdentity/logo-identity-7.png';
import leftBottomGroup from '../../../../images/LogoIdentity/left-bottom-group.svg'

import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsLogoIdentityPage, LOGO_IDENTITY_TITLE, LOGO_IDENTITY_SWIPER_WRAPPER } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperLogoIdentity = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsLogoIdentityPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={9}
        isTransform
    >
        <span className="swiper-main__title" id={LOGO_IDENTITY_TITLE}>
          {t('LOGO')} 
          <span className="swiper-main__ampersand"> {t('AND')} </span>
          <span className="swiper-title__underline">{t('IDENTITY')}</span>
        </span>

        <div className="main-swiper__wrapper logo-identity__wrapper" id={LOGO_IDENTITY_SWIPER_WRAPPER}>
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper"
          >   
            <SwiperSlide>
              <div className="logo-identity">
                <img src={logoIdentityMain} alt="logoIdentityMain" className="logo-identity-slide" loading="lazy"/>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="logo-identity logo-identity-slide">
                <div className="logo-identity-second__left">
                    <div className="logo-identity-second__left-top">
                        <img src={logoIdentity5} alt="logoIdentity" loading="lazy"/>
                        <div className="logo-identity-second__left-top-block">
                          <div className="logo-identity-second__left-top-block-text">{t('logo_identity.TEXT_TOP')}</div>
                        </div>
                    </div>
                    <img src={leftBottomGroup} alt="logoIdentity" className="logo-identity-second__left-bottom" loading="lazy"/>
                </div>

                <div className="logo-identity-second__right">
                    <img src={logoIdentity7} alt="logoIdentity" loading="lazy"/>
                    <div className="logo-identity-second__right-bottom">
                      <div className="logo-identity-second__right-bottom-text">{t('logo_identity.TEXT_BOTTOM')}</div>
                    </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </AnimationComponent>
    </div>
  )
}

export default SwiperLogoIdentity