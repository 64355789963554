import React from 'react';
import './CorporateBookTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import corporateBook1 from '../../../../images/CorporateBook/corporate-book-1.jpg';
import corporateBook2 from '../../../../images/CorporateBook/corporate-book-2.jpg';
import corporateBook3 from '../../../../images/CorporateBook/corporate-book-3.jpg';
import corporateBook4 from '../../../../images/CorporateBook/corporate-book-4.jpg';

import { useTranslation } from 'react-i18next';

const CorporateBookTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='corporate-book-tablet'>
      <div className='corporate-book-tablet__content'>
        <div className="main-swiper-tablet__title">
           <span> {t('PRINTING_DESIGN')}</span>
            <span>{t('CORPORATE_BOOK')}<span className="swiper-title-tablet__underline"></span></span> 
        </div>

        <div className="main-swiper-tablet__wrapper corporate-book-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet"
          >  
            <SwiperSlide>
              <div className="corporate-book-tablet__wrapp">
                <div className="corporate-book-tablet__slide">
                  <img src={corporateBook1} alt="" className="corporate-book-tablet__top" loading="lazy"/>
                  <div className="corporate-book-tablet__top-bottom">
                    <img src={corporateBook2} alt="" className="corporate-book-tablet__top-bottom-first" loading="lazy"/>
                    <img src={corporateBook3} alt="" className="corporate-book-tablet__top-bottom-second" loading="lazy"/>
                  </div>
                </div>
                <div className="corporate-book-tablet__slide">
                  <img src={corporateBook4} alt="" className="corporate-book-tablet__bottom" loading="lazy"/>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default CorporateBookTablet
