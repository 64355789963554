import React from 'react';
import './Roche.scss';

import phone from '../../../images/Roche/phone-tablet.png';
import dots from '../../../images/QueenMary/dots.svg';
import strokes from '../../../images/QueenMary/strokes.svg';
import waves from '../../../images/QueenMary/waves.svg';

import { allAnimationsRochePage, ROCHE_DESCR, ROCHE_DOTS, ROCHE_PHONE, ROCHE_RHOMB_BORDER, ROCHE_STROKES, ROCHE_WAVES } from './utils/animations';
import { AnimationComponent } from '../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const Roche = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div className="roche">
        <AnimationComponent           
          animationAction={animationAction} 
          animation={allAnimationsRochePage} 
          prevPage={prevPage} 
          currentPageIndex={currentPageIndex}
          pageIndex={24}
        > 
        <div className="roche__rombh-border" id={ROCHE_RHOMB_BORDER}></div>

        <div className="roche__title" id={ROCHE_DESCR}>
          <span className="roche__title-top">{t('roche_page.LEARNING_PLATFORM')}</span>
          <span className="roche__title-main">{t('roche_page.ROCHE')}</span>
            <div className="roche__text">
                <span>{t('roche_page.TEXT_LINE_1')}</span>
                <span>{t('roche_page.TEXT_LINE_2')}</span>
            </div>
          </div>     

        <img src={phone} alt="phone" className="roche__phone" id={ROCHE_PHONE} loading="lazy"/>
        
        <img src={dots} alt="dots" className="roche__dots" id={ROCHE_DOTS} loading="lazy"/>
        
        <img src={strokes} alt="strokes" className="roche__strokes" id={ROCHE_STROKES} loading="lazy"/>
                
        <img src={waves} alt="waves" className="roche__waves" id={ROCHE_WAVES} loading="lazy"/>
        </AnimationComponent>
    </div>
  )
}

export default Roche
