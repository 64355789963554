import React from 'react';
import './SwiperExhibition.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import exhibitionMain from '../../../../images/Exhibition/exhibition-main.svg'

import exhibition1 from '../../../../images/Exhibition/exhibition-slide2-1.jpg';
import exhibition2 from '../../../../images/Exhibition/exhibition-slide2-2.jpg';
import exhibition3 from '../../../../images/Exhibition/exhibition-slide2-3.jpg';

import exhibition4 from '../../../../images/Exhibition/exhibition-1.jpg';
import exhibition5 from '../../../../images/Exhibition/exhibition-2.jpg';
import exhibition6 from '../../../../images/Exhibition/exhibition-3.jpg';

import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsExhibitionPage, EXHIBITION_SWIPER_WRAPPER, EXHIBITION_TITLE } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperExhibition = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div className='swiper-exhibition__main'>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsExhibitionPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={13}
        // isTransform
    >
        <span className="swiper-main__title exhibition__title" id={EXHIBITION_TITLE}>
          {t('DESIGNS')} 
          <span className="swiper-main__ampersand"> {t('AND')} </span>
          {t('EXHIBITION')} <span className="swiper-title__underline">{t('BOOTH')}</span> 
        </span>

        <div className="main-swiper__wrapper exhibition__wrapper" id={EXHIBITION_SWIPER_WRAPPER}>
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper"
          >
            <SwiperSlide>
              <div className="swiper-exhibition">
                  <img src={exhibition1} alt="" className="swiper-exhibition__left" loading="lazy"/>
                  <div className="swiper-exhibition__right">
                      <img src={exhibition2} alt="" loading="lazy"/>
                      <img src={exhibition3} alt="" loading="lazy"/>
                  </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="swiper-exhibition">
                  <img src={exhibition4} alt="" className="swiper-exhibition__left"/>
                  <div className="swiper-exhibition__right">
                      <img src={exhibition5} alt="" loading="lazy"/>
                      <img src={exhibition6} alt="" loading="lazy"/>
                  </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </AnimationComponent>
    </div>
  )
}

export default SwiperExhibition