import React from 'react';
import './WhatDoingItemsList.scss';

const WhatDoingItemsList = ({img, title, subtitle}) => {
  return (
    <div className="what-doing-list">
      <img src={img} alt="pic in paragraphs" className="what-doing-list__img" loading="lazy"/>
      <div className="what-doing-list__wrapp">
        <span className="what-doing-list__title">{title}</span>
        <span className="what-doing-list__subtitle">{subtitle}</span>
      </div>
    </div>
  )
}

export default WhatDoingItemsList