import React from 'react';
import { AnimationComponent } from '../../AnimationComponent';
import './Enjoy.scss';

import scretch from '../../../images/Enjoy/scretch.png';
import logoBig from '../../../images/Enjoy/EC-Logo-new_Final-white.png';
import logo from '../../../images/be-brave.png';
import rectGroup from '../../../images/Enjoy/rect-group.svg';
import fenix from '../../../images/Enjoy/fenix.svg';

import { allAnimationsEnjoyPage, ENJOY_LOGO_BIG, ENJOY_LOGO_FOOTER, ENJOY_RECTANGLES_TOP, ENJOY_RECTANGLE_YELLOW, ENJOY_SCRETCH, ENJOY_TITLE, ENJOY_TITLE_EXPERIENCE, COPYRIGHT } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const Enjoy = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();

  return (
    <div>
      <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsEnjoyPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={28}
      >
        <div className="enjoy__rectangles" id={ENJOY_RECTANGLES_TOP}>
          <div className="enjoy__rectangle-gradient"></div>
          <div className="enjoy__rectangle-border"></div>
        </div>

        <img src={rectGroup} alt="rectangle group" className="enjoy__rectangle-yellow" id={ENJOY_RECTANGLE_YELLOW} loading="lazy"></img>

        <span className="enjoy__title" id={ENJOY_TITLE}>
        {t('enjoy_page.ENJOY_YOUR')}
        </span>

        <span className="enjoy__title-experience" id={ENJOY_TITLE_EXPERIENCE}>
        {t('enjoy_page.EXPERIENCE')}
        </span>

        <img src={logoBig} alt="big logo" id={ENJOY_LOGO_BIG} className="enjoy__logo-big" loading="lazy"/>

        <img src={scretch} alt="scretch" className="enjoy__scretch" id={ENJOY_SCRETCH} loading="lazy"/>

        <img src={logo} alt="logo" className="enjoy__logo-footer" id={ENJOY_LOGO_FOOTER} loading="lazy"/>

        <div className="copyright-block" id={COPYRIGHT}>
           <div className="copyright">{t('copyright.COPYRIGHT')}</div>

           <a href="https://fnx.com.ua/ua/ecommerce-development" target="_blank" className="developed" rel="noreferrer">
              <span>{t('copyright.DEVELOPED')}</span>
              <img src={fenix} alt="fenix" />
           </a>
        </div>

      </AnimationComponent>
    </div>
  )
}

export default Enjoy