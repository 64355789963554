export const UK_TRANSLATION = {
  AND: 'ТА',
  ANNUAL_REPORT: 'Річний звіт',
  BOOTH: 'ВИСТАВКОВИХ СТЕНДІВ',
  CONCEPT_DEV_VISUAL: 'РОЗРОБКА КОНЦЕПЦІЙ, ВІЗУАЛІЗАЦІЯ',
  CONCEPT: 'розробка',
  CREATION: 'РОЗРОБКА',
  CORPORATE_BOOK: 'КОРПОРАТИВНА КНИГА',
  CALENDARS: 'Календарі',
  DUBAI_EXPO: 'DUBAI EXPO',
  DATA_2020 : '2020',
  DESIGN: 'ДИЗАЙН',
  DESIGNS: 'ДИЗАЙН',
  DESIGN_LOWER_CASE: 'Дизайн',
  DEVELOPMENT: 'Концепцій',
  EXHIBITION_INSTALLATION: 'Виставкова інсталяція',
  EXHIBITION: 'ВИГОТОВЛЕННЯ',
  IDENTITY: 'БРЕНДИНГ',
  INSTALLATIONS: 'ВИГОТОВЛЕННЯ ІНСТАЛЯЦІЙ',
  LOGO: 'ЛОГОТИП',
  MENU_BUTTON: 'Меню',
  KEY_VISUAL: 'КІ-ВІЖУАЛИ',
  NAMING: 'НЕЙМІНГ',
  PRINTING: 'ПОЛІГРАФІЯ',
  PRINTING_DESIGN: 'ДИЗАЙН ПОЛІГРАФІЇ',
  PHOTO_ZONES: 'Фотозони',
  POSM: 'POS матеріали',
  PRODUCTION: 'Виробництво',
  SEE_MORE: 'Дізнатись більше',
  VISUAL: 'візуалізація',
  WATCH_ALL: 'Дивитись більше відео',

  menu: { 
    ITEM_MENU_1: 'Про нас',
    ITEM_MENU_2: 'Наші нагороди',
    ITEM_MENU_3: 'Наша спеціалізація',
    ITEM_MENU_4: 'Розробка концепцій, візуалізація та дизайн',
    ITEM_MENU_5: 'Маркетингові сервіси',
    ITEM_MENU_6: 'Наші клієнти',
    ITEM_MENU_7: 'Контакти',
  },
  main_page: {
    EYE_COMMS: "EYE COMMS",
    AGENCY: "AGENCY",
    CONCEPT_DEV: 'Concept development',
    VISUAL_DESIGN: 'Visual&design',
    MARKETING_SERVICES: 'Marketing services'
  },
  who_are_page: {
    TITLE: 'ПРО НАС',
    TEXT_LINE_1: 'Привіт, ми з України!',
    TEXT_LINE_2: `З країни сильних людей, закоханих у життя. У нашій українській ДНК закладена свобода думки, творчість, незалежність,`,
    TEXT_LINE_2_DESKTOP: 'З країни сильних людей, закоханих у життя. У нашій українській ДНК закладена свобода думки, творчість, незалежність, сміливість і здатність бачити красу у всьому.',
    TEXT_LINE_3: ' сміливість і здатність бачити красу у всьому. ',
    TEXT_LINE_4: 'Усі ці цінності є основою для кожного нашого проекту, а кожен із членів нашої команди робить свій вагомий внесок в унікальність створеного продукту. ',
    TEXT_LINE_5: 'Всі разом ми - EYE COMMS - агенція МАРКЕТИНГОВИХ КОМУНІКАЦІЙ, що радо працює над культурним та креативним розмаїттям світу, долучаючи долю українського креативу',
  },
  unique_page: {
    TITLE: 'НАША',
    TITLE_UNIQUE: 'УНІКАЛЬНІСТЬ',
    TEXT: 'Наша агенція – унікальне поєднання креативності, традицій та ефективного менеджменту. Працюючи з нами, вам ніколи не доведеться турбуватися про деталі та втілення концепції в реальність. Результати наших проектів завжди перевершують очікування'
  },
  our_team_page: {
    TITLE: 'НАША КОМАНДА',
    DESCRIPTION_LINE_1: 'Наша команда - це спільнота з різними захопленнями та стилем життя.',
    DESCRIPTION_LINE_2: 'Та нас об‘єднує пристрасть до створення успішних комунікаційних проектів, любов до своєї справи і задоволення від результату.'
  },
  team_people: {
    person_1: 'Евгенія Тимофеєва',
    person_2: 'Олександра Штойко',
    person_3: 'Ольга Терешко',
    person_4: 'Вячеслав Сливка',
    person_5: 'Олена Дідківська',
    person_6: 'Ольга Висоцька',
    person_7: 'Віктор Сіонський',
    person_8: 'Вікторія Індиченко',
    person_9: 'Тамара Овчаренко',
    person_10: 'Олександр Мартинюк',
    person_11: 'Олександр Гриценко',
    person_12: 'Анастасія Бабенко',
    person_13: 'Дмитро Худолій',
    person_14: 'Влад Пономаренко',
    person_15: 'Влад Трусов',
  },
  our_rewards_page: {
    TEXT: 'Eye Comms посіла перше місце у міжнародному конкурсі Eyes&Ears Awards в категорії SPECIALS & CROSS-MEDIA, B2B HYBRID CONFERENCE, Best live 2021 з проектом «Нова стратегія ДТЕК 2030». Eyes & Ears Awards — це міжнародна премія в області дизайну та комунікацій, яка відзначає дизайн, цифрові комунікації, просування, крос-медійні комунікації та унікальні проекти.'
  },
  what_we_doing: {
    TITLE_LEFT: 'НАША',
    TITLE_RIGHT: 'СПЕЦІАЛІЗАЦІЯ',
    CONCEPT_DEV_TITLE: 'РОЗРОБКА КОНЦЕПЦІЙ, ВІЗУАЛІЗАЦІЯ ТА ДИЗАЙН',
    MARKETING_SERVICES_TITLE: 'МАРКЕТИНГОВІ СЕРВІСИ',
    BRAND_IDENTITY: 'Брендинг / ',
    NAMING_KEY_VISUAL: 'неймінг / кі-віжуали',
    MATERIAL_DESIGN: 'Дизайн матеріалів',
    MATERIAL_DESIGN_ETC: '(поліграфія, банери, виставкові стенди, інсталяції, тощо)',
    VISUAL_BRAND_CONTENT: 'Візуальний бренд-контент та відеопродакшн',
    VISUAL_BRAND_CONTENT_ETC: '(анімація, 3d графіка, моушн дизайн)',
    CREATION_PRODUCTION: 'Розробка та виготовлення брендованої сувенірної продукції',
    WEBSITES_LEARNING: 'Створення сайтів та навчальних платформ',
    EVENT_ORGANIZATION: 'Організація івентів та спеціальних подій'
  },
  logo_identity: {
    TEXT_TOP: 'Нами був розроблений логотип заходу урочистого відкриття Академії ДТЕК',
    TEXT_BOTTOM: 'Який пізніше ліг у основу всієї бренд айдентики Академії'
  },
  visual_brand_page: {
    TITLE: 'ВІЗУАЛЬНИЙ БРЕНД КОНТЕНТ/',
    TITLE_ANIMATION: 'АНІМАЦІЯ',
    WATCH_ALL: 'Дивитись більше відео'
  },
  visual_3d_page: {
    TITLE: 'ВІЗУАЛЬНИЙ БРЕНД КОНТЕНТ/',
    TITLE_GRAPHICS: '3D ГРАФІКА',
  },
  video_prod_page: {
    TITLE: 'ВІДЕОПРОДАКШН / СТВОРЕННЯ',
    TITLE_VIDEOS: 'ВІДЕО',
    VIDEO_PROD: 'ВІДЕОПРОДАКШН /',
    CREATING: 'СТВОРЕННЯ ВІДЕО'
  },
  marketing_serv: {
    TITLE_MARKETING: 'МАРКЕТИНГОВІ',
    TITLE_SERVICES: 'СЕРВІСИ'
  },
  creation_prod_page: {
    TITLE_CREATION: 'РОЗРОБКА',
    TITLE_PRODUCTION: 'ВИРОБНИЦТВО СУВЕНІРНОЇ',
    TITLE_B2B: 'ПРОДУКЦІЇ ДЛЯ B2B',
    B2B: 'B2B',
    PRESENTS: 'PRESENTS'
  },
  creation_packs: {
    CREATION: 'РОЗРОБКА',
    PRODUCTION: 'ВИРОБНИЦТВО WELCOME PACKS',
    CONFERENCE_WELCOME: 'НА КОНФЕРЕНЦІЇ',
    PACKS: 'PACKS' 
  },
  creation_gifts_page: {
    CREATION: 'РОЗРОБКА',
    PRODUCTION_PROMO: 'ВИГОТОВЛЕННЯ СУВЕНІРНОЇ ПРОДУКЦІЇ',
    TEXT_TOP_1: 'Новорічний подарунковий набір, складається з вишуканого китайського чаю, термопляшки з індикатором температури та листівки. Упаковано в преміальну коробку',
    TEXT_TOP_2: 'Термопляшка з креативним дизайном – сувенір, який завжди потрібен',
    TEXT_BOTTOM: 'Розробка та виготовлення унікальних нагород та пінів для кращих співробітників',
  },
  dubai_expo: {
    GLOBAL_EXHIBITION: 'Глобальна виставка – це унікальна подія всесвітнього масштабу, яка демонструє новітні технології та інновації.',
    FOR_DTEK: 'Для Групи ДТЕК – найбільшого приватного інвестора в українську енергетику – створено та встановлено технологічні вітряки в українському павільйоні на Expo 2020 Dubai як символ нашого сталого майбутнього. Альтернативна енергетика, яку ДТЕК впроваджує в Україні, є ключовим шляхом до зниження викидів CO2 та врешті- трансформації енергетичного сектору'
  },
  queen_mary_page: {
    QUEEN_MARY: 'QUEEN MARY',
    LEARNING_PLATFORM: 'НАВЧАЛЬНА ПЛАТФОРМА',
    TEXT_LINE_1: 'Навчальна платформа розроблена відповідно до вимог підвищеної безпеки.',
    TEXT_LINE_2: 'Проведено навчання для 150 осіб за участю лекторів із 7 країн.',
    TEXT_LINE_3: 'На платформі учасникам був наданий доступ до особистих кабінетів, онлайн-занять, Q&A, тестування. 15 днів онлайн навчання протягом чотирьох місяців',
  },
  roche_page: {
    ROCHE: 'ROCHE',
    LEARNING_PLATFORM: 'НАВЧАЛЬНА ПЛАТФОРМА',
    TEXT_LINE_1: 'Платформа правового навчання створена як єдиний інформаційний портал з окремим підрозділом – Школою пацієнтських організацій.',
    TEXT_LINE_2: 'Після реєстрації на платформі користувач має доступ в особистий кабінет, можливість позначати цікаві матеріали та приєднуватися до лекцій, семінарів та робочих сесій',
  },
  websites: {
    TITLE: 'СТВОРЕННЯ САЙТІВ ТА ЛЕНДИНГ СТОРІНОК'
  },
  our_clients_page: {
    TITLE: 'НАШІ КЛІЄНТИ',
    WORLDWIDE: 'Міжнародний портфель',
    UKRAINE: 'Український портфель'
  },
  contact_us_page: {
    TITLE: 'КОНТАКТИ',
    MAIN_OFFICE: 'головний офіс',
    ADDRESS: '04077, Київ, вул. Оболонська 29, оф.205',
    WORK_TOGETHER: 'Давайте працювати',
    CLIENT_SERVICE_DEPARTMENT: 'NEW BUSINESS DIRECTOR',
    MANAGING_DIRECTOR: 'Managing Director',
    ISPIRATION_LEADER: 'ISPIRATION LEADER, CO-OWNER',
    CREATIVE_DEPARTAMENT: 'CREATIVE DEPARTMENT',
    PERSON_NAME_1: 'Евгенія Тимофеєва',
    PERSON_NAME_2: 'Ольга Висоцька',
    PERSON_NAME_3: 'Віктор Сіонський',
    PERSON_NAME_4: 'Олександра Штойко',
  },
  popup_feedback: {
    YOUR_NAME: `Ваше ім'я`,
    YOUR_EMAIL: 'Ваш e-mail',
    COMMENT: 'Залишіть Ваш коментар',
    SEND_REQUEST: 'Відправити',
    LETS: 'Давайте',
    WORK: 'працювати',
    TEXT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl diam mauris massa aenean eget. Aenean sed interdum risus nunc morbi magna. Accumsan mattis aliquet eu odio vulputate leo eget.',
    EMPTY_NAME_FIELD: `Поле Ваше ім'я не може бути пустим`,
    EMPTY_EMAIL_FIELD: `Поле Ваш e-mail не може бути пустим`,
    INVALID_EMAIL: 'Некоректний e-mail',
  },
  enjoy_page: {
    ENJOY_YOUR: 'ENJOY YOUR',
    EXPERIENCE: 'EXPERIENCE!',
    ENJOY: 'ENJOY',
    YOUR: 'YOUR',
  },
  copyright: {
    COPYRIGHT: '© Усі права захищено. 2023р.',
    DEVELOPED: 'Розробка сайту',
  }
};
