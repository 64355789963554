import React, { useState } from 'react';
import i18next from 'i18next';
import './Menu.scss';
import { useCookies } from 'react-cookie';

import logo from '../../images/Menu/Logo.png';
import playArrow from '../../images/Menu/play-arrows.svg';
import strokes from '../../images/Menu/strokes.svg';
import waves from '../../images/Menu/waves.svg';
import heroMain from '../../images/Menu/HeroImage.svg';
import close from '../../images/Menu/close.svg';

import {ReactComponent as ArrowGray} from '../../images/Menu/ArrowGray.svg';
import {ReactComponent as Arrow} from '../../images/Menu/arrow.svg';
import { useTranslation } from 'react-i18next';

const Menu = ({setIsMenuOpen, currentPageIndex, navToPage}) => {
  const { t } = useTranslation();
  const [menuClose, setMenuClose] = useState(false);
  const [cookies, setCookie] = useCookies(['name']);

  return (
    <div className={`menu ${menuClose ? 'menu__none' : '' } `}>
      <div className="menu__header">
        <img src={logo} alt="logo" className="menu__header-logo" loading="lazy"
          onClick={() => {
            if (window.innerWidth >= 1200) {
              navToPage(currentPageIndex, 1)   
            }
            setIsMenuOpen()
          }} 
        />

        <div className="menu__buttons-wrapper">
          <div className="languages">
              <button
                className={`${cookies.i18next === 'uk' ? 'is-active' : 'languages-uk' } `}
                onClick={() => {
                  setCookie()
                  i18next.changeLanguage('uk')
                  setIsMenuOpen()
                }}
              >
                UA
              </button>
              <button
                className={`${cookies.i18next === 'en' ? 'is-active' : 'languages-en' } `}
                onClick={() => {
                  setCookie()
                  i18next.changeLanguage('en')
                  setIsMenuOpen()
                }}
              >
                EN
              </button>
            </div>

            <div className="menu__close"         
              onClick={() => {
                setMenuClose(prev => !prev)
                document.body.style.overflow = 'visible';
                setTimeout(() => {
                  setIsMenuOpen()
                }, 100)
              }}
            >
            <img src={close} alt="close" loading="lazy"/>
          </div>
        </div>
      </div>
      
      <div className="menu__list">
        <a href="#about-us" className="menu__list-item"
          onClick={() => {
            if (window.innerWidth >= 1200) {
              navToPage(currentPageIndex, 2)
            }  
            setIsMenuOpen()
          }}
        >
          <span className="menu__list-item-title">{t('menu.ITEM_MENU_1')}</span>
          <div>
            <ArrowGray className="menu__list-item-arrow"/>
            <Arrow className="menu__list-item-arrow-hover"/>
          </div>
        </a>
        <div className="menu__list-separate"></div>
        <a href="#our-rewards" className="menu__list-item"
            onClick={() => {
              if (window.innerWidth >= 1200) {
                navToPage(currentPageIndex, 6)
              }  
              setIsMenuOpen()
            }}
          >
          <span className="menu__list-item-title">{t('menu.ITEM_MENU_2')}</span>
          <div>
            <ArrowGray className="menu__list-item-arrow"/>
            <Arrow className="menu__list-item-arrow-hover"/>
          </div>
        </a>
        <div className="menu__list-separate"></div>
        <a href="#what-are-doing" className="menu__list-item"
          onClick={() => {
            if (window.innerWidth >= 1200) {
              navToPage(currentPageIndex, 7)
            }  
            setIsMenuOpen()
          }}
        >
          <span className="menu__list-item-title">{t('menu.ITEM_MENU_3')}</span>
          <div>
            <ArrowGray className="menu__list-item-arrow"/>
            <Arrow className="menu__list-item-arrow-hover"/>
          </div>
        </a>
        <div className="menu__list-separate"></div>
        <a href="#concept-development" className="menu__list-item"
          onClick={() => {
            if (window.innerWidth >= 1200) {
              navToPage(currentPageIndex, 8)
            }  
            setIsMenuOpen()
          }}
          >
          <span className="menu__list-item-title">{t('menu.ITEM_MENU_4')}</span>
          <div>
            <ArrowGray className="menu__list-item-arrow"/>
            <Arrow className="menu__list-item-arrow-hover"/>
          </div>
        </a>
        <div className="menu__list-separate"></div>
        <a href="#marketing-services" className="menu__list-item"
          onClick={() => {
            if (window.innerWidth >= 1200) {
              navToPage(currentPageIndex, 19)
            }  
            setIsMenuOpen()
          }}>
          <span className="menu__list-item-title">{t('menu.ITEM_MENU_5')}</span>
          <div>
            <ArrowGray className="menu__list-item-arrow"/>
            <Arrow className="menu__list-item-arrow-hover"/>
          </div>
        </a>
        <div className="menu__list-separate"></div>
        <a href="#our-clients" className="menu__list-item"
          onClick={() => {
            if (window.innerWidth >= 1200) {
              navToPage(currentPageIndex, 26)
            }  
            setIsMenuOpen()
          }}
        >
          <span className="menu__list-item-title">{t('menu.ITEM_MENU_6')}</span>
          <div>
            <ArrowGray className="menu__list-item-arrow"/>
            <Arrow className="menu__list-item-arrow-hover"/>
          </div>
        </a>
        <div className="menu__list-separate"></div>
        <a href="#contacts" className="menu__list-item"
          onClick={() => {
            if (window.innerWidth >= 1200) {
              navToPage(currentPageIndex, 27)
            }  
            setIsMenuOpen()
          }}
        >
          <span className="menu__list-item-title">{t('menu.ITEM_MENU_7')}</span>
          <div>
            <ArrowGray className="menu__list-item-arrow"/>
            <Arrow className="menu__list-item-arrow-hover"/>
          </div>
        </a>
        <div className="menu__list-separate"></div>
      </div>

      <img src={heroMain} alt="heroMain" className="menu__heroImg" loading="lazy"/>

      <img src={strokes} alt="strokes" className="menu__strokes" loading="lazy"/>

      <img src={waves} alt="waves" className="menu__waves" loading="lazy"/>

      <img src={playArrow} alt="play arrows" className="menu__play-arrows" loading="lazy"/>

      <div className="menu__rectangle-bottom"></div>

      <div className="menu__rhomb-border"></div>

      <div className="menu__rhomb"></div>

      <div className="menu__rectangles">
        <div className="menu__rectangles-border"></div>
      </div>
    </div>
  )
}

export default Menu