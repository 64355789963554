import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { ConceptDev, ContactUs, Content3dGraphics, ContentAnimations, Enjoy, EyesEurope, Main, MarketingServices, OurClients, QueenMary, Roche, SwiperCorporateBook, SwiperCreationGifts, SwiperCreationPacks, SwiperCreationProd, SwiperDesign, SwiperDubaiExpo, SwiperExhibition, SwiperKeyVisual, SwiperLogoIdentity, SwiperPrintingDesign, Team, TeamDescription, Unique, VideoProduction, Websites, WhatWeDoing, WhoAre } from '../../components/Components';

const MAX_COUNT_PAGES = 28;
const ANIMATION_ACTION = {
  DOWN: 'DOWN',
  UP: 'UP',
}
let canScroll = true; // Можливість скролити на наступну або попердню сторінку
let globalCurrentPage = 1; // Поточна сторінка

export const DesktopComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [animationAction, setAnimationAction] = useState(null);
  const [prevPage, setPrevPage] = useState(1);

  useEffect(() => {
    const body = document.body;
    let currentPage = 1; // Поточна сторінка
    
    globalCurrentPage = currentPage;

    body.onwheel = (e) => {
      const { deltaY } = e;

      if (canScroll) {
        canScroll = false; // Забороняємо скрол
  
        if(globalCurrentPage !== currentPage) {
          currentPage = globalCurrentPage;
        }
        
        // Скролл донизу
        if (deltaY > 0 && currentPage < MAX_COUNT_PAGES) {
          setPrevPage(currentPage);
          currentPage += 1;
          setAnimationAction(ANIMATION_ACTION.DOWN);
          setCurrentPage(currentPage);
        } 

        // Скролл доверху
        if (deltaY < 0 && currentPage > 1) {
          setPrevPage(currentPage);
          currentPage -= 1;
          setAnimationAction(ANIMATION_ACTION.UP);
          setCurrentPage(currentPage);
        }
  
        globalCurrentPage = currentPage;

        setTimeout(() => { // Дозволяємо скрол через 2 секунди
          canScroll = true; 
        }, 2000)
      }

    }
  }, [])

  const getAnimationClass = () => {

    // Якщо скролимо з 1 -> 2
    if (currentPage === 2 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBg'
    }

    // Якщо скролимо з 2 -> 1
    if (currentPage === 1 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgReverse'
    }

    // Якщо скролимо з 2 -> 3
    if (currentPage === 3 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgForward'
    }

    // Якщо скролимо з 3 -> 2
    if (currentPage === 2 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgForward'
    }

    // Якщо скролимо з 3 -> 4
    if (currentPage === 4 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgTeamDescr'
    }

    // Якщо скролимо з 4 -> 3
    if (currentPage === 3 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgTeamDescrReverse'
    }

    // Якщо скролимо з 4 -> 5
    if (currentPage === 5 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgPageTeam'
    }

    // Якщо скролимо з 5 -> 4
    if (currentPage === 4 && animationAction === ANIMATION_ACTION.UP) {
    return 'transformBgPageTeamReverse'
    }
  
    // Якщо скролимо з 5 -> 6
    if (currentPage === 6 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgPageEyesEurope'
    }

    // Якщо скролимо з 6 -> 5
    if (currentPage === 5 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgPageEyesEuropeReverse'
    }

    // Якщо скролимо з 69 -> 7
    if (currentPage === 7 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgPageWhatDoing'
    }

    // Якщо скролимо з 7 -> 6
    if (currentPage === 6 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgPageWhatDoingReverse'
    }

    // Якщо скролимо з 7 -> 8
    if (currentPage === 8 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgPageConceptDev'
    }

    // Якщо скролимо з 8 -> 7
    if (currentPage === 7 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgPageConceptDevReverse'
    }

    // Якщо скролимо з 8 -> 9
    if (currentPage === 9 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgPageLogoIdentity'
    }

    // Якщо скролимо з 9 -> 8
    if (currentPage === 8 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgPageLogoIdentityReverse'
    }


    
    if (currentPage === 9 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipers'
    }

    if (currentPage === 10 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 10 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 11 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 11 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 12 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 12 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 13 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 13 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 14 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 14 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 15 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 15 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 16 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 16 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 17 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 17 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipersReverse'
    }

    if (currentPage === 18 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 18 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgMarketingReverse'
    }

    if (currentPage === 19 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgMarketing'
    }
    if (currentPage === 19 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgPageLogoIdentityReverse'
    }

    if (currentPage === 20 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgPageLogoIdentity'
    }
    if (currentPage === 20 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipers'
    }

    if (currentPage === 21 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 21 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgSwipers'
    }

    if (currentPage === 22 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgSwipers'
    }
    if (currentPage === 22 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgPageLogoIdentity'
    }

    if (currentPage === 23 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgPageLogoIdentityReverse'
    }
    if (currentPage === 23 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgQueenMary'
    }

    if (currentPage === 24 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgQueenMary'
    }
    if (currentPage === 24 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgQueenMary'
    }    
    
    if (currentPage === 25 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgQueenMary'
    }
    if (currentPage === 25 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgReverse'
    }

    if (currentPage === 26 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBg'
    }
    if (currentPage === 26 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBgForward'
    }

    if (currentPage === 27 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgForward'
    }
    if (currentPage === 27 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBg'
    }

    if (currentPage === 28 && animationAction === ANIMATION_ACTION.DOWN) {
      return 'transformBgOurClientsReverse'
    }
    if (currentPage === 28 && animationAction === ANIMATION_ACTION.UP) {
      return 'transformBg'
    }
  
    return '';
  }
  
  const navToPage = (currentPage, newPage) => {
    if(currentPage === newPage || currentPage > MAX_COUNT_PAGES) return;
  
    canScroll = false; // Забороняємо скрол
  
    setPrevPage(currentPage);
  
    if(currentPage > newPage) {
      setAnimationAction(ANIMATION_ACTION.UP);
    } else {
      setAnimationAction(ANIMATION_ACTION.DOWN);
    }
  
    currentPage = newPage;
    globalCurrentPage = newPage;
    
    setCurrentPage(currentPage)
  
    setTimeout(() => { // Дозволяємо скрол через 2 секунди
      canScroll = true;
    }, 2000)
  }

  return (
    <div className={`App ${getAnimationClass()}`} id="App">
        {
          currentPage && (
            <Main navToPage={navToPage} currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
          )
        }

      <WhoAre currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
      
      <Unique currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <TeamDescription currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/> 
      
      <Team currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <EyesEurope currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <WhatWeDoing navToPage={navToPage} currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <ConceptDev currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
    
      
      <SwiperLogoIdentity currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
      
      <SwiperKeyVisual currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <SwiperCorporateBook currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <SwiperPrintingDesign currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <SwiperExhibition currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <SwiperDesign currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <SwiperDubaiExpo currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>


      <ContentAnimations currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
       
      <Content3dGraphics currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/> 

      <VideoProduction currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
  
      <MarketingServices currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
    
      <SwiperCreationProd currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <SwiperCreationPacks currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <SwiperCreationGifts currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
    
      <QueenMary currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
    
      <Roche currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>

      <Websites currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
       
      <OurClients currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
    
      <ContactUs currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
      
      <Enjoy currentPageIndex={currentPage} animationAction={animationAction} prevPage={prevPage}/>
    
    </div>
  );
}
