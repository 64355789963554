import React from 'react';
import './ConceptDevTablet.scss';

import rhombWhisDies from '../../../images/ConceptDev/rectangles-dies.svg';
import rectanglesBottom from '../../../images/ConceptDev/rectangles-bottom-tablet.svg';
import logoFooter from '../../../images/ConceptDev/logo-concept-dev.svg';
import rhombGray from '../../../images/ConceptDev/rhomb-gray-tablet.svg';
import { useTranslation } from 'react-i18next';

const ConceptDevTablet = () => {
const { t } = useTranslation();

  return (
    <div className='concept-dev-tablet' id='concept-development'>
			<img src={rhombGray} alt="gray rhomb" className='concept-dev-tablet__rhomb-gray' loading="lazy"/>
			<div className='concept-dev-tablet__title'> {t('CONCEPT')}<br/> {t('DEVELOPMENT')},<br/> {t('VISUAL')} <span className='concept-dev-tablet__title-ampersand'> {t('AND')} </span> {t('DESIGN')}</div>
			<img src={rhombWhisDies} alt="rhomb whis dies" className='concept-dev-tablet__rhomb-dies' loading="lazy"/>
			<img src={rectanglesBottom} alt="rectangles bottom" className='concept-dev-tablet__rectangles-bottom' loading="lazy"/>
			<img src={logoFooter} alt="footer logo" className='concept-dev-tablet__logo-footer' loading="lazy"/>
		</div>
  )
}

export default ConceptDevTablet
