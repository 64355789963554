const animationTopTitleUnique = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.add("hiddenTitleUnique");

      setTimeout(() => {
        currentElement.classList.remove("flex");
        currentElement.classList.remove("hiddenTitleUnique");
      }, 1800)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("showTitleUnique");
      currentElement.classList.add("flex");

    setTimeout(() => {
      currentElement.classList.remove("showTitleUnique");
    }, 1800)
    }
  }
}

const animationImgWithRhombUnique = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.add("hiddenImgWithRhombUnique");

      setTimeout(() => {
        currentElement.classList.remove("block");
        currentElement.classList.remove("hiddenImgWithRhombUnique");
      }, 2000)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("showImgWithRhombUnique");
      currentElement.classList.add("block");

    setTimeout(() => {
      currentElement.classList.remove("showImgWithRhombUnique");
    }, 1800)
    }
  }
}


  
const animationStickyTape = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.add("hiddenStickyTape");

      setTimeout(() => {
        currentElement.classList.remove("block");
        currentElement.classList.remove("hiddenStickyTape");
      }, 700)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("showStickyTape");
      currentElement.classList.add("block");

    setTimeout(() => {
      currentElement.classList.remove("showStickyTape");
    }, 3000)
    }
  }
}


const animationDescrUnique = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.add("hiddenDescrUnique");

      setTimeout(() => {
        currentElement.classList.remove("flex");
        currentElement.classList.remove("hiddenDescrUnique");
      }, 1000)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("showDescrUnique");
      currentElement.classList.add("flex");

      setTimeout(() => {
          currentElement.classList.remove("showDescrUnique");
      }, 2000)
    }
  }
}



export const UNIQUE_STICKY_TAPE = 'UNIQUE_STICKY_TAPE';
export const UNIQUE_TOP_RECTANGLE_GROUP = 'UNIQUE_TOP_RECTANGLE_GROUP';
export const UNIQUE_TITLE = 'UNIQUE_TITLE';
export const UNIQUE_DESCR = 'UNIQUE_DESCR';
export const UNIQUE_RECTANGLES = 'UNIQUE_RECTANGLES';
export const UNIQUE_PLAY_ARROWS = 'UNIQUE_PLAY_ARROWS';
export const UNIQUE_IMG_WITH_RHOMB = 'UNIQUE_IMG_WITH_RHOMB';


export const allAnimationsInUniquePage = {
  UNIQUE_TITLE: animationTopTitleUnique(),
  UNIQUE_IMG_WITH_RHOMB: animationImgWithRhombUnique(),
  UNIQUE_STICKY_TAPE: animationStickyTape(),
  UNIQUE_DESCR: animationDescrUnique(),
  UNIQUE_TOP_RECTANGLE_GROUP: animationDescrUnique(),
  UNIQUE_PLAY_ARROWS: animationDescrUnique(),
}
