import React from 'react';
import './Content3dGraphics.scss';

import content3dGraphics1 from '../../../images/3dGraphics/3d-graphics-1.png';
import content3dGraphics2 from '../../../images/3dGraphics/3d-graphics-2.png';
import {ReactComponent as Play} from '../../../images/play.svg';
import arrowRight from '../../../images/ContentAnimations/arrow-right.svg';

import { AnimationComponent } from '../../AnimationComponent';
import { allAnimationsContent3dGraphicsPage, CONTENT_3d_GRAPHICS_BUTTON, CONTENT_3d_GRAPHICS_TITLE, CONTENT_3d_GRAPHICS_WRAPPER } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const Content3dGraphics = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
      <AnimationComponent         
        animationAction={animationAction} 
        animation={allAnimationsContent3dGraphicsPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={17}
        isTransform
        isMultiAnimation
      >
      <span className="content-graphics__title" id={CONTENT_3d_GRAPHICS_TITLE}>{t('visual_3d_page.TITLE')} {t('visual_3d_page.TITLE_GRAPHICS')}<span className="swiper-title__underline"></span></span>

        <div className="content-graphics__wrapper" id={CONTENT_3d_GRAPHICS_WRAPPER}>
          <a href='https://www.youtube.com/watch?v=JQ-gHnXvHfE' target="_blank" className="video-prod__wrapper-img-link" rel="noreferrer">
          <img src={content3dGraphics1} alt="3d graphics" className="content-graphics__wrapper-img" loading="lazy"/>
            <div className="video-prod__wrapper-play-img">
              <Play className="video-prod__play-img"/>
            </div>
          </a>
          <a href='https://www.youtube.com/watch?v=dRmUPE03aeY' target="_blank" className="video-prod__wrapper-img-link" rel="noreferrer">
          <img src={content3dGraphics2} alt="3d graphics" className="content-graphics__wrapper-img" loading="lazy"/>
            <div className="video-prod__wrapper-play-img">
            <Play className="video-prod__play-img"/>
            </div>
          </a>
        </div>
        <a href='https://www.youtube.com/watch?v=fHcOOdwdMfo&list=PLPFysJtDmSWqFFFyq-bPeUU64x4Kun8qD' target="_blank" className="content-graphics__button-watch" id={CONTENT_3d_GRAPHICS_BUTTON} rel="noreferrer">
          <img src={arrowRight} alt="arrow right" className="content-graphics__button-arrow" loading="lazy"/>
          <span className="content-graphics__button-text">{t('WATCH_ALL')}</span> 
        </a>
      </AnimationComponent>
    </div>
  )
}

export default Content3dGraphics