import React from 'react';
import './WhoTop.scss';

import rectGroup from '../../../../images/WhoWeAre/rectangle-group.svg';
import { useTranslation } from 'react-i18next';

const WhoTop = ({id}) => {
const { t } = useTranslation();

  return (
    <div className="who-top" id={id}>
      <img src={rectGroup} alt="rectangle group" className="who-top__img" loading="lazy"/>
      <span className="who-top__title">{t('who_are_page.TITLE')}</span>
    </div>
  )
}

export default WhoTop