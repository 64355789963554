import React from 'react';
import './UniqueTitle.scss';
import { useTranslation } from 'react-i18next';

const UniqueTitle = ({id}) => {
  const { t } = useTranslation();

  return (
    <div className="unique__title" id={id}>
      <span className="unique__text">
        {t('unique_page.TITLE')} <br/> {t('unique_page.TITLE_UNIQUE')}
      </span>
    </div>
  )
}

export default UniqueTitle