const getArrayOfChildren = (children) => {
  let arrayOfChildren = [];

  if (Array.isArray(children)) {
    arrayOfChildren = children
  } else {
    arrayOfChildren.push(children)
  }
  
  return arrayOfChildren;
}

export const AnimationComponent = ({
  children,
  currentPageIndex,
  animationAction,
  animation,
  prevPage,
  pageIndex,
  isTransform = false,
  isMultiAnimation = false,
}) => {

  const arrayOfChildren = getArrayOfChildren(children);
  const allIds = arrayOfChildren.reduce((acc, item) => [...acc, item.props.id] ,[]);
  
  const isCurrentPage = currentPageIndex === pageIndex;
  const isPrevPage = prevPage === pageIndex;

  const isTransformToTop = isTransform && animationAction === 'DOWN';
  const isTransformToDOWN = isTransform && animationAction === 'UP';
  
  if (animationAction) {
    for (const id of allIds) {
      if (animation[id]) { // 
        
        if (isPrevPage) {

          if ((isTransformToTop && isMultiAnimation) || isTransformToTop) {
            if (animation[id].hasOwnProperty('transformToTop')) { //
              animation[id].transformToTop(id);
            }
          } else {
            animation[id].hide(id);
          }
        }

        if (isCurrentPage) {

          if ((isTransformToDOWN && isMultiAnimation) || isTransformToDOWN) {
            if (animation[id].hasOwnProperty('transformToDown')) {
              animation[id].transformToDown(id)
            }
          } else {
            animation[id].appearance(id);
          }
        }
      }
    }
  }

  return children;
}
