import React from 'react';
import './SwiperCreationGifts.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import creationGifts1 from '../../../../images/CreationGifts/creation-gifts-1.png';
import creationGifts2 from '../../../../images/CreationGifts/creation-gifts-2.png';
import creationGifts4 from '../../../../images/CreationGifts/creation-gifts-4.jpg';
import creationGifts5 from '../../../../images/CreationGifts/creation-gifts-5.png';

import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsCreationsGiftsPage, CREATIONS_GIFTS_SWIPER_WRAPPER, CREATIONS_GIFTS_TITLE } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperCreationGifts = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsCreationsGiftsPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={22}
    >
        <span className="swiper-main__title creation-packs__title" id={CREATIONS_GIFTS_TITLE}>
          {t('creation_gifts_page.CREATION')} 
          <span className="swiper-main__ampersand"> {t('AND')}  <br/></span> 
          {t('creation_gifts_page.PRODUCTION_PROMO')} 
          <span className="swiper-title__underline"></span>
        </span>

        <div className="main-swiper__wrapper creation-gifts__wrapper" id={CREATIONS_GIFTS_SWIPER_WRAPPER}>
          <Swiper
              speed={1000}
              freeMode={true}
              navigation={true}
              modules={[FreeMode, Pagination, Navigation]}
              className="main-swiper"
          >
            <SwiperSlide>
              <div className="creation-gifts">
                  <div className="creation-gifts__left">
                      <div className="creation-gifts__left-top">
                          <img src={creationGifts1} alt="" loading="lazy"/>
                          <div className="creation-gifts__left-top-block">
                              <span>{t('creation_gifts_page.TEXT_TOP_1')}</span>
                          </div>
                      </div>
                      <div className="creation-gifts__left-bottom">
                          <img src={creationGifts4} alt="" className="creation-gifts__left-bottom-img" loading="lazy"/>
                      </div>
                  </div>

                  <div className="creation-gifts__right">
                      <div className="creation-gifts__right-top">
                          <img src={creationGifts2} alt="" loading="lazy"/>
                          <div className="creation-gifts__right-top-block">
                              <span>{t('creation_gifts_page.TEXT_TOP_2')}</span>
                          </div>
                      </div>
                      <div className="creation-gifts__right-bottom">
                        <img src={creationGifts5} alt="" loading="lazy"/>
                        <div className="creation-gifts__right-bottom-text">{t('creation_gifts_page.TEXT_BOTTOM')} </div>
                      </div>
                  </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </AnimationComponent>
    </div>
  )
}


export default SwiperCreationGifts