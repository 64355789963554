import React from 'react';
import './Header.scss';
import logo from "../../images/Logo.png";
import { useState } from 'react';

import Menu from '../../components/Menu/Menu';
import { useTranslation } from 'react-i18next';

const Header = ({id, currentPageIndex, navToPage}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="header" id={id}>
        <div className="header__logo">
          <img src={logo} alt="logo" className="header__logo-img" loading="lazy"
            onClick={() => {
              if (window.innerWidth >= 1200) {
                // eslint-disable-next-line no-unused-expressions
                currentPageIndex !== 1 ? navToPage(currentPageIndex, 1) : null
              } else {
                window.scrollTo({
                  top: 0,
                })
              }
            }}
          />
        </div>
        <button className="header__menu"
          onClick={() => {
            setIsMenuOpen(prev => !prev)
            // document.body.style.overflow = 'hidden';
          }}
        >
          <div className="header__menu-title">{t('MENU_BUTTON')}</div>
          <div className="header__menu-border"></div>
        </button>

         {
          isMenuOpen && (
            <Menu 
              setIsMenuOpen={() => setIsMenuOpen(prev => !prev)}
              currentPageIndex={currentPageIndex}
              navToPage={navToPage}
            />
          )
         }
    </div>
  )
}

export default Header