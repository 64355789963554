const animationDesignInstallation = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDesignInstallation");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDesignInstallation");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDesignInstallation");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDesignInstallation");
            }, 2000)
        },
    }
}


const animationDesignInstallationTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDesignInstallationTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDesignInstallationTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDesignInstallationTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDesignInstallationTitle");
            }, 1800)
        },
    }
}

const animationDesignInstallationDescrBottom = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDesignInstallationDescrBottom");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenDesignInstallationDescrBottom");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDesignInstallationDescrBottom");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showDesignInstallationDescrBottom");
            }, 2000)
        },
    }
}





export const DESIGN_INSTALLATION_TITLE = 'DESIGN_INSTALLATION_TITLE';
export const DESIGN_INSTALLATION_SWIPER_WRAPPER = 'DESIGN_INSTALLATION_SWIPER_WRAPPER';
export const DESIGN_INSTALLATION_DESCR_BOTTOM = 'DESIGN_INSTALLATION_DESCR_BOTTOM';


export const allAnimationsDesignInstallationPage = {
    DESIGN_INSTALLATION_SWIPER_WRAPPER: animationDesignInstallation(),
    DESIGN_INSTALLATION_TITLE: animationDesignInstallationTitle(),
    DESIGN_INSTALLATION_DESCR_BOTTOM: animationDesignInstallationDescrBottom(),
}
