import React from 'react';
import './DesignInstallationTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import designInstallationSlide2 from '../../../../images/DesignSwiper/design-swiper-1.jpg';
import designInstallationSlide1 from '../../../../images/DesignSwiper/design-swiper-2.jpg';
import { useTranslation } from 'react-i18next';

const DesignInstallationTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='design-installation-tablet'>
      <div className='design-installation-tablet__content'>
        <div className="main-swiper-tablet__title">
          <span>{t('DESIGNS')} <span className="swiper-main-tablet__ampersand"> {t('AND')} </span></span>  
          <span>{t('INSTALLATIONS')}<span className="swiper-title-tablet__underline"></span></span> 
        </div>

        <div className="main-swiper-tablet__wrapper design-installation-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet design-installation-tablet__swiper"
          >  
            <SwiperSlide>
              <div className="design-installation-tablet__wrapp">
                <img src={designInstallationSlide2} alt="design installation slide" className="design-installation-tablet__slide" loading="lazy"/>
                <img src={designInstallationSlide1} alt="design installation slide" className="design-installation-tablet__slide" loading="lazy"/>
                <div className="design-installation-tablet__title-bottom">{t('PHOTO_ZONES')}</div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default DesignInstallationTablet