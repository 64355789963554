const animationKeyVisual = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenKeyVisual");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenKeyVisual");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showKeyVisual");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showKeyVisual");
            }, 2000)
        },
    }
}


const animationKeyVisualTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenKeyVisualTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenKeyVisualTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showKeyVisualTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showKeyVisualTitle");
            }, 1800)
        },
    }
}





export const KEY_VISUAL_TITLE = 'KEY_VISUAL_TITLE';
export const KEY_VISUAL_SWIPER_WRAPPER = 'KEY_VISUAL_SWIPER_WRAPPER';


export const allAnimationsKeyVisualPage = {
    KEY_VISUAL_SWIPER_WRAPPER: animationKeyVisual(),
    KEY_VISUAL_TITLE: animationKeyVisualTitle(),

}
