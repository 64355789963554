import React from 'react';
import './Unique.scss';

import stickyTape from '../../../images/Unique/sticky-tape.svg';
import mainImg from '../../../images/Unique/unique-main-img.png';
import rectGroup from '../../../images/Unique/rect-group-header.svg';
import UniqueTitle from '../Desktop/UniqueTitle/UniqueTitle';
import { AnimationComponent } from '../../AnimationComponent';
import { 
        allAnimationsInUniquePage, 
        UNIQUE_DESCR, UNIQUE_IMG_WITH_RHOMB, 
        UNIQUE_STICKY_TAPE, 
        UNIQUE_TITLE, 
        UNIQUE_TOP_RECTANGLE_GROUP 
      } from './utils/animations';

import UniqueDescr from '../Desktop/UniqueDescr/UniqueDescr';

export const Unique = ({currentPageIndex, animationAction, prevPage}) => {
  return (
    <div className="unique">
      <AnimationComponent             
        animationAction={animationAction} 
        animation={allAnimationsInUniquePage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={3}
      >
        <UniqueTitle id={UNIQUE_TITLE}/>

        <img src={stickyTape} alt="sticky-tape" className="unique__sticky-tape" id={UNIQUE_STICKY_TAPE} loading="lazy"/>
        
        <img src={rectGroup} alt="rectangle-group" className="unique-top__rect-group" id={UNIQUE_TOP_RECTANGLE_GROUP} loading="lazy"/>

        <UniqueDescr id={UNIQUE_DESCR}/>
      
        <div className="unique-img-with-rombh" id={UNIQUE_IMG_WITH_RHOMB}>
          <img src={mainImg} alt="mainImage" className="unique-content__img-main" loading="lazy"/>
        </div>
        
      </AnimationComponent>
    </div>
  )
}

export default Unique