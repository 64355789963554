const animationEnjoyFaceImg = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyFaceImg");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyFaceImg");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyFaceImg");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyFaceImg");
            }, 2000)
        },
    }
}


const animationEnjoyRhombYellow = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyRhombYellow");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyRhombYellow");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyRhombYellow");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyRhombYellow");
            }, 2000)
        },
    }
}


const animationEnjoyDots = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyDots");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyDots");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyDots");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyDots");
            }, 2000)
        },
    }
}


const animationEnjoyStrokes = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyStrokes");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyStrokes");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyStrokes");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyStrokes");
            }, 2000)
        },
    }
}


const animationEnjoyLogoBig = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyLogoBig");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyLogoBig");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyLogoBig");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyLogoBig");
            }, 2000)
        },
    }
}



const animationEnjoyRectanglesTop = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyRectanglesTop");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyRectanglesTop");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyRectanglesTop");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyRectanglesTop");
            }, 2000)
        },
    }
}


const animationEnjoyDies = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyDies");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyDies");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyDies");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyDies");
            }, 2000)
        },
    }
}


const animationEnjoyTitleMain = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyTitleMain");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyTitleMain");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyTitleMain");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyTitleMain");
            }, 2000)
        },
    }
}


const animationEnjoyTitleExperiance = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenEnjoyTitleExperiance");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenEnjoyTitleExperiance");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showEnjoyTitleExperiance");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showEnjoyTitleExperiance");
            }, 2000)
        },
    }
}

const animationCopyright = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCopyright");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenCopyright");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCopyright");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showCopyright");
            }, 2000)
        },
    }
}





export const ENJOY_TITLE = 'ENJOY_TITLE';
export const ENJOY_TITLE_EXPERIENCE = 'ENJOY_TITLE_EXPERIENCE';
export const ENJOY_RECTANGLES_TOP = 'ENJOY_RECTANGLES_TOP';
export const ENJOY_RECTANGLE_YELLOW = 'ENJOY_RECTANGLE_YELLOW';
export const ENJOY_LOGO_BIG = 'ENJOY_LOGO_BIG';
export const ENJOY_DOTS = 'ENJOY_DOTS';
export const ENJOY_STROKES = 'ENJOY_STROKES';
export const ENJOY_SCRETCH = 'ENJOY_SCRETCH';
export const ENJOY_LOGO_FOOTER = 'ENJOY_LOGO_FOOTER';
export const ENJOY_FACE_IMG = 'ENJOY_FACE_IMG';
export const COPYRIGHT = 'COPYRIGHT';


export const allAnimationsEnjoyPage = {
    ENJOY_FACE_IMG: animationEnjoyFaceImg(),
    ENJOY_RECTANGLE_YELLOW: animationEnjoyRhombYellow(),
    ENJOY_DOTS: animationEnjoyDots(),
    ENJOY_STROKES: animationEnjoyStrokes(),
    ENJOY_LOGO_BIG: animationEnjoyLogoBig(),
    ENJOY_RECTANGLES_TOP: animationEnjoyRectanglesTop(),
    ENJOY_LOGO_FOOTER: animationEnjoyDies(),
    ENJOY_SCRETCH: animationEnjoyDies(),
    ENJOY_TITLE: animationEnjoyTitleMain(),
    ENJOY_TITLE_EXPERIENCE: animationEnjoyTitleExperiance(),
    COPYRIGHT: animationCopyright(),
}
