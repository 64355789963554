const animationRocheDescr = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDescrRoche");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenDescrRoche");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDescrRoche");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showDescrRoche");
            }, 1800)
        },

        // transformToTop(id) {
        //     const currentElement = document.getElementById(id);
        //     currentElement.classList.add("transformToTopQueenMaryDies");
      
        //     setTimeout(() => {
        //       currentElement.classList.remove("transformToTopQueenMaryDies");
        //       currentElement.classList.remove("flex");
        //     }, 1600)
        //   },
      
        //   transformToDown(id) {
        //     const currentElement = document.getElementById(id);
        //     currentElement.classList.add("flex");
        //     currentElement.classList.add("transformToDownQueenMaryDies");
      
        //     setTimeout(() => {
        //       currentElement.classList.remove("transformToDownQueenMaryDies");
        //     }, 2000)
        // },
    }
}


const animationRochePhone = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenPhoneRoche");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenPhoneRoche");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showPhoneRoche");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showPhoneRoche");
            }, 1800)
        },
    }
}


const animationRocheStrokes = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenStrokesRoche");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenStrokesRoche");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showStrokesRoche");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showStrokesRoche");
            }, 1800)
        },
    }
}


const animationRocheBorderRhomb = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenRhombRoche");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenRhombRoche");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showRhombRoche");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showRhombRoche");
            }, 1800)
        },
    }
}


const animationRocheDots = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDotsRoche");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDotsRoche");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDotsRoche");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDotsRoche");
            }, 1800)
        },
    }
}

const animationRocheWaves = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWavesRoche");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenWavesRoche");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWavesRoche");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showWavesRoche");
            }, 1800)
        },
    }
}





export const ROCHE_DESCR = 'ROCHE_DESCR';
export const ROCHE_RHOMB_BORDER = 'ROCHE_RHOMB_BORDER';
export const ROCHE_PHONE = 'ROCHE_PHONE';
export const ROCHE_DOTS = 'ROCHE_DOTS';
export const ROCHE_STROKES = 'ROCHE_STROKES';
export const ROCHE_WAVES = 'ROCHE_WAVES';


export const allAnimationsRochePage = {
    ROCHE_RHOMB_BORDER: animationRocheBorderRhomb(),
    ROCHE_WAVES: animationRocheWaves(),
    ROCHE_DESCR: animationRocheDescr(),
    ROCHE_DOTS: animationRocheDots(),
    ROCHE_STROKES: animationRocheStrokes(),
    ROCHE_PHONE: animationRochePhone(),
}