import React from 'react';
import './QueenMaryTablet.scss';

import LaptopDocument from '../../../images/QueenMary/queen-mary-laptop-doc.svg';
import rhomb from '../../../images/QueenMary/rhomb-tablet.svg';
import { useTranslation } from 'react-i18next';

const QueenMaryTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='queen-mary-tablet'>
      <div className='queen-mary-tablet__top-content'>
        <div className='queen-mary-tablet__titles'>
          <div className='queen-mary-tablet__titles-main'>{t('queen_mary_page.LEARNING_PLATFORM')}</div>
          <div className='queen-mary-tablet__titles-subtitle'>{t('queen_mary_page.QUEEN_MARY')}</div>
        </div>
        <div className='queen-mary-tablet__text'>
          <span>{t('queen_mary_page.TEXT_LINE_1')}</span>
          <span>{t('queen_mary_page.TEXT_LINE_2')}</span>
          <span>{t('queen_mary_page.TEXT_LINE_3')}</span>
        </div>
      </div>

      <img src={LaptopDocument} alt="laptop and document" className='queen-mary-tablet__laptop' loading="lazy"/>
      <img src={rhomb} alt="rhomb" className='queen-mary-tablet__rhomb' loading="lazy"/>
    </div>
  )
}

export default QueenMaryTablet