import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import './MainContentTitle.scss';
import { useTranslation } from 'react-i18next';

const MainContentTitle = ({id, currentPageIndex}) => {
  const { t } = useTranslation();

  const parallax = useCallback((event) => {
    document.querySelectorAll(".main-content__titles-wrapp").forEach((shift) => {
      const position = shift.getAttribute("value");  
      const x = (window.innerWidth - event.pageY * position) / 90;

      shift.style.transform = `translateX(${x}px)`;
    });
  }, [])
  
  useEffect(() => {
    if (currentPageIndex === 1) {
      document.addEventListener("mousemove", parallax);
    } else {
      document.removeEventListener("mousemove", parallax);
    }
  }, [currentPageIndex])

  
  return (
    <div className="main-content__titles" id={id}>
      <div className="main-content__titles-wrapp parallax"  value="15" id="parallax">
        <span className="main-content__title-gray">{t('main_page.CONCEPT_DEV')}</span>
        <span className="main-content__title-center">{t('main_page.CONCEPT_DEV')}</span>
        <span className="main-content__title-gray">{t('main_page.CONCEPT_DEV')}</span>
      </div>
      <div className="main-content__titles-wrapp parallax"  value="-15" id="scrollingTextCenter">
        <span className="main-content__title-gray">{t('main_page.VISUAL_DESIGN')}</span>
        <span className="main-content__title-center">{t('main_page.VISUAL_DESIGN')}</span>
        <span className="main-content__title-gray">{t('main_page.VISUAL_DESIGN')}</span>
      </div>
      <div className="main-content__titles-wrapp parallax" value="15" id="parallax">
        <span className="main-content__title-gray">{t('main_page.MARKETING_SERVICES')}</span>
        <span className="main-content__title-center">{t('main_page.MARKETING_SERVICES')}</span>
        <span className="main-content__title-gray">{t('main_page.MARKETING_SERVICES')}</span>
      </div>
    </div>
  )
}

export default MainContentTitle