import React from 'react';
import './main.scss';

import beBrave from '../../../images/be-brave.png';
import dotted from '../../../images/dotted.svg';
import dotsBlock from '../../../images/dots-block.svg';
import waves from '../../../images/waves.svg';
import Header from '../../../ui/Header/Header';

import MainRectangleTop from './MainRectangleTop/MainRectangleTop';
import MainBgRectangle from './MainBgRectangle/MainBgRectangle';
import MainContentTitle from './MainContentTitle/MainContentTitle';
import MainFooterRectangles from './MainFooterRectangles/MainFooterRectangles';
import MainImgHero from './MainImgHero/MainImgHero';

import { 
  allAnimationsInMainPage, 
  MAIN_BG_RHOMB,
  MAIN_IMG_HERO, 
  MAIN_RECTANGLE_TOP,
  MAIN_TITLES,
  MAIN_DOTS,
  MAIN_STROKES,
  MAIN_WAVES,
  MAIN_RECTANGLE_FOOTER,
  MAIN_LOGO_FOOTER,
  MAIN_TITLE
} from './utils/animation';

import { AnimationComponent } from '../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const Main = ({currentPageIndex, animationAction, prevPage, navToPage}) => {
  const { t } = useTranslation();

  return (
    <div className="main">
      <AnimationComponent 
        animationAction={animationAction} 
        animation={allAnimationsInMainPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={1}
      >
        
        <Header id="header" navToPage={navToPage} currentPageIndex={currentPageIndex}/>

        <div className="main-content__titles-main" id={MAIN_TITLE}>
          <span className="main-content__title-main">{t('main_page.EYE_COMMS')}</span>
          <span className="main-content__title-main">{t('main_page.AGENCY')}</span>
        </div>

        <MainRectangleTop id={MAIN_RECTANGLE_TOP}/>

        <MainBgRectangle id={MAIN_BG_RHOMB}/>

        <img src={dotted} alt="strokes" className="main-content__dotted-img" id={MAIN_DOTS} loading="lazy"/>

        <img src={dotsBlock} alt="dotsBlock" className="main-content__dots-img" id={MAIN_STROKES} loading="lazy"/>

        <MainContentTitle id={MAIN_TITLES} currentPageIndex={currentPageIndex}/>

        <MainImgHero id={MAIN_IMG_HERO} />

        <img src={waves} alt="waves" className="main-content__waves" id={MAIN_WAVES} loading="lazy"/>

        <MainFooterRectangles id={MAIN_RECTANGLE_FOOTER} loading="lazy"/>

        <img src={beBrave} alt="beBrave" id={MAIN_LOGO_FOOTER} className="main-content__logo-footer" loading="lazy"/>

      </AnimationComponent>
    </div>
  )
}

export default Main