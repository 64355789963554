import React from 'react';
import './QueenMary.scss';

import document from '../../../images/QueenMary/document.png';
import laptop from '../../../images/QueenMary/laptop.png';
import dots from '../../../images/QueenMary/dots.svg';
import strokes from '../../../images/QueenMary/strokes.svg';
import waves from '../../../images/QueenMary/waves.svg';

import { allAnimationsQueenMaryPage, QUEEN_MARY_DESCR, QUEEN_MARY_DOCUMENT, QUEEN_MARY_DOTS, QUEEN_MARY_LAPTOP, QUEEN_MARY_RHOMB_BORDER, QUEEN_MARY_STROKES, QUEEN_MARY_WAVES } from './utils/animations';
import { AnimationComponent } from '../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const QueenMary = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div className="queen-mary">
        <AnimationComponent           
            animationAction={animationAction} 
            animation={allAnimationsQueenMaryPage} 
            prevPage={prevPage} 
            currentPageIndex={currentPageIndex}
            pageIndex={23}
            isTransform
            isMultiAnimation
        > 
        <div className="queen-mary__rombh-border" id={QUEEN_MARY_RHOMB_BORDER}></div>

        <div className="queen-mary__title" id={QUEEN_MARY_DESCR}>
          <span className="queen-mary__title-top">{t('queen_mary_page.LEARNING_PLATFORM')}</span>
          <span className="queen-mary__title-main">{t('queen_mary_page.QUEEN_MARY')}</span>
          <div className="queen-mary__text">
             <span>{t('queen_mary_page.TEXT_LINE_1')}</span><br />
             <span>{t('queen_mary_page.TEXT_LINE_2')}</span><br />
             <span>{t('queen_mary_page.TEXT_LINE_3')}</span> </div>
          </div>     

        <img src={document} alt="document" className="queen-mary__document" id={QUEEN_MARY_DOCUMENT} loading="lazy"/>
        
        <img src={laptop} alt="laptop" className="queen-mary__laptop" id={QUEEN_MARY_LAPTOP} loading="lazy"/>
        
        <img src={dots} alt="dots" className="queen-mary__dots" id={QUEEN_MARY_DOTS} loading="lazy"/>
        
        <img src={strokes} alt="strokes" className="queen-mary__strokes" id={QUEEN_MARY_STROKES} loading="lazy"/>
                
        <img src={waves} alt="waves" className="queen-mary__waves" id={QUEEN_MARY_WAVES} loading="lazy"/>
        </AnimationComponent>
    </div>
  )
}

export default QueenMary
