import React from 'react';
import './WhoAreTablet.scss';

import playArrows from '../../../images/WhoWeAre/play-arrows.svg';
import rectanglesTop from '../../../images/WhoWeAre/rectangle-group.svg';
import logoFooter from '../../../images/WhoWeAre/who-we-are.svg';
import { useTranslation } from 'react-i18next';

const WhoAreTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='who-are-tablet' id="about-us">
      <div className='who-are-tablet__top'>
        <img src={rectanglesTop} alt="rectangles top group" className='who-are-tablet__top-rectangles' loading="lazy"/>
        <span className='who-are-tablet__top-title'>{t('who_are_page.TITLE')}</span>
      </div>
      <div className='who-are-tablet__content'>
        <span>{t('who_are_page.TEXT_LINE_1')}</span>
        <span>{t('who_are_page.TEXT_LINE_2_DESKTOP')}</span>
        <span>{t('who_are_page.TEXT_LINE_4')}</span>  
        <span>{t('who_are_page.TEXT_LINE_5')}</span>
      </div>

      <img src={playArrows} alt="play arrows" className='who-are-tablet__play-arrows' loading="lazy"/>

      <img src={logoFooter} alt="footer logo" className='who-are-tablet__logo-footer' loading="lazy"/>
    </div>
  )
}

export default WhoAreTablet