import React from 'react';
import './SwiperCreationPacks.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import creationPacksSlide from '../../../../images/CreationPacks/creation-packs-main.svg'

// import creationPacks1 from '../../../images/CreationPacks/creation-packs-1.png';
// import creationPacks2 from '../../../images/CreationPacks/creation-packs-2.png';

import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsCreationsPacksPage, CREATIONS_PACKS_SWIPER_WRAPPER, CREATIONS_PACKS_TITLE } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperCreationPacks = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsCreationsPacksPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={21}
    >
        <span className="swiper-main__title creation-packs__title" id={CREATIONS_PACKS_TITLE}>
          {t('creation_packs.CREATION')} 
          <span className="swiper-main__ampersand"> {t('AND')} </span> 
         
          <span className="swiper-title__underline"> {t('creation_packs.PRODUCTION')} {t('creation_packs.CONFERENCE_WELCOME')}</span>
        </span>

        <div className="creation-packs__wrapper main-swiper__wrapper" id={CREATIONS_PACKS_SWIPER_WRAPPER}>
          <Swiper
              speed={1000}
              freeMode={true}
              navigation={true}
              modules={[FreeMode, Pagination, Navigation]}
              className="main-swiper"
          >
            <SwiperSlide>
              <div className="creation-packs">
                {/* <img src={creationPacks1} alt="" />
                <img src={creationPacks2} alt="" /> */}
                <img src={creationPacksSlide} alt="creation packs slide" className="creation-packs-slide" loading="lazy"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        </AnimationComponent>
    </div>
  )
}


export default SwiperCreationPacks