const animationDubaiExpo = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDubaiExpo");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDubaiExpo");
            }, 1500)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDubaiExpo");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDubaiExpo");
            }, 2400)
        },
    }
}


const animationDubaiExpoTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDubaiExpoTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDubaiExpoTitle");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDubaiExpoTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDubaiExpoTitle");
            }, 2000)
        },
    }
}

const animationDubaiExpoDescrBottom = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDubaiExpoDescrBottom");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenDubaiExpoDescrBottom");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDubaiExpoDescrBottom");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showDubaiExpoDescrBottom");
            }, 2000)
        },
    }
}





export const DUBAI_EXPO_TITLE = 'DUBAI_EXPO_TITLE';
export const DUBAI_EXPO_SWIPER_WRAPPER = 'DUBAI_EXPO_SWIPER_WRAPPER';
export const DUBAI_EXPO_DESCR_BOTTOM = 'DUBAI_EXPO_DESCR_BOTTOM';


export const allAnimationsDubaiExpoPage = {
    DUBAI_EXPO_SWIPER_WRAPPER: animationDubaiExpo(),
    DUBAI_EXPO_TITLE: animationDubaiExpoTitle(),
    DUBAI_EXPO_DESCR_BOTTOM: animationDubaiExpoDescrBottom(),
}
