const animationCreationGifts = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCreationGifts");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCreationGifts");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCreationGifts");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCreationGifts");
            }, 2000)
        },
    }
}


const animationCreationGiftsTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenCreationGiftsTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenCreationGiftsTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showCreationGiftsTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showCreationGiftsTitle");
            }, 1800)
        },
    }
}





export const CREATIONS_GIFTS_TITLE = 'CREATIONS_GIFTS_TITLE';
export const CREATIONS_GIFTS_SWIPER_WRAPPER = 'CREATIONS_GIFTS_SWIPER_WRAPPER';


export const allAnimationsCreationsGiftsPage = {
    CREATIONS_GIFTS_TITLE: animationCreationGiftsTitle(),
    CREATIONS_GIFTS_SWIPER_WRAPPER: animationCreationGifts(),

}
