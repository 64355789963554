import React from 'react';
import './Content3dGraphicsTablet.scss';

import contentGraphicsImg1 from '../../../images/3dGraphics/3d-graphics-1.png';
import contentGraphicsImg2 from '../../../images/3dGraphics/3d-graphics-2.png';
import arrowRight from '../../../images/ContentAnimations/arrow-right-tablet.svg';
import play from '../../../images/play.svg';
import { useTranslation } from 'react-i18next';

const ContentGraphicsTablet = () => {
  const { t } = useTranslation();

  return (
    <div className='content-animations-tablet'>
      <div className='content-animations-tablet__content'>
        <div className="main-swiper-tablet__title">
        {t('visual_3d_page.TITLE')}
        <span className="swiper-title-tablet__underline">&nbsp;{t('visual_3d_page.TITLE_GRAPHICS')}</span>
        </div>

        <div className="main-swiper-tablet__wrapper content-animations-tablet__wrapper">
          <div className="content-graphics-tablet__wrapp">
            <a href='https://www.youtube.com/watch?v=JQ-gHnXvHfE' target="_blank" className="video-prod__wrapper-img-link" rel="noreferrer">
              <img src={contentGraphicsImg1} alt="3d graphics" className="content-animations-tablet__slide" loading="lazy"/>
              <div className="video-prod__wrapper-play-img">
                <img src={play} alt="play" className="video-prod__play-img" loading="lazy"/>
              </div>
            </a>
            <a href='https://www.youtube.com/watch?v=dRmUPE03aeY' target="_blank" className="video-prod__wrapper-img-link" rel="noreferrer">
              <img src={contentGraphicsImg2} alt="3d graphics" className="content-animations-tablet__slide" loading="lazy"/>
              <div className="video-prod__wrapper-play-img">
                <img src={play} alt="play" className="video-prod__play-img" loading="lazy"/>
              </div>
            </a>
            <a href='https://www.youtube.com/watch?v=fHcOOdwdMfo&list=PLPFysJtDmSWqFFFyq-bPeUU64x4Kun8qD' target="_blank" className="content-animations-tablet__watch-all" rel="noreferrer">
              <img src={arrowRight} alt="arrow right" className="content-animation__watch-all-arrow" loading="lazy"/>
              {t('WATCH_ALL')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentGraphicsTablet