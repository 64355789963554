const animationLogoIdentity = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenLogoIdentity");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenLogoIdentity");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showLogoIdentity");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showLogoIdentity");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopLogoIdentity");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopLogoIdentity");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownLogoIdentity");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownLogoIdentity");
            }, 2000)
        },
    }
}


const animationLogoIdentityTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenLogoIdentityTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenLogoIdentityTitle");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showLogoIdentityTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showLogoIdentityTitle");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopLogoIdentityTitle");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopLogoIdentityTitle");
              currentElement.classList.remove("block");
            }, 1600)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownLogoIdentityTitle");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownLogoIdentityTitle");
            }, 1600)
        },
    }
}





export const LOGO_IDENTITY_TITLE = 'LOGO_IDENTITY_TITLE';
export const LOGO_IDENTITY_SWIPER_WRAPPER = 'LOGO_IDENTITY_SWIPER_WRAPPER';


export const allAnimationsLogoIdentityPage = {
    LOGO_IDENTITY_SWIPER_WRAPPER: animationLogoIdentity(),
    LOGO_IDENTITY_TITLE: animationLogoIdentityTitle(),

}
