import React from 'react';
import './SwiperDubaiExpo.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import dubaiExpo1 from '../../../../images/DubaiExpo/dubai-expo-1.png';
import dubaiExpo3 from '../../../../images/DubaiExpo/dubai-gif.gif';
import dubaiExpo4 from '../../../../images/DubaiExpo/dubai-expo-4.png';
import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsDubaiExpoPage, DUBAI_EXPO_DESCR_BOTTOM, DUBAI_EXPO_SWIPER_WRAPPER, DUBAI_EXPO_TITLE } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperDubaiExpo = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsDubaiExpoPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={15}
    >
        <span className="swiper-main__title dubai-expo__title" id={DUBAI_EXPO_TITLE}>
          {t('DUBAI_EXPO')} <span className="swiper-title__underline">{t('DATA_2020')}</span> 
        </span>

        <div className="main-swiper__wrapper dubai-expo__wrapper" id={DUBAI_EXPO_SWIPER_WRAPPER}>
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper"
          >
            <SwiperSlide>
              <div className="dubai-expo">
                <div className="dubai-expo__left">
                  <img src={dubaiExpo1} alt="dubai expo" className="dubai-expo__left--1" loading="lazy"/>
                  <img src={dubaiExpo4} alt="dubai expo" className="dubai-expo__left--4" loading="lazy"/>
                </div>

                <div className="dubai-expo__right">
                  <div className="dubai-expo__right-top">
                    <div className="dubai-expo__left--2">
                      <span>{t('dubai_expo.GLOBAL_EXHIBITION')} </span>
                    </div>
                    <img src={dubaiExpo3} alt="dubai expo" className="dubai-expo__left--3" loading="lazy"/>
                  </div>
                  <div className="dubai-expo__left--5">
                    <span>{t('dubai_expo.FOR_DTEK')}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <span className="dubai-expo__descr" id={DUBAI_EXPO_DESCR_BOTTOM}>{t('EXHIBITION_INSTALLATION')}</span>
        </AnimationComponent>
    </div>
  )
}

export default SwiperDubaiExpo