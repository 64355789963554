import React from 'react';
import './PersonCard.scss';

const PersonCard = ({ img, name, position, background, id }) => {
  return (
    <div className="person-card">
        <img src={img} alt="person" className="person-card__img" loading="lazy"/>
        <div className="person-card__info" style={background} id={id}>
          <span className="person-card__info-name">{name}</span>
          <span className="person-card__info-position">{position}</span>
        </div>
    </div>
  )
}

export default PersonCard