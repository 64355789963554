import React from 'react';
import './VideoProductionTablet.scss';

import videoProdImg1 from '../../../images/VideoProd/video-prod-1.png';
import videoProdImg2 from '../../../images/VideoProd/video-prod-2.png';
import arrowRight from '../../../images/ContentAnimations/arrow-right-tablet.svg';
import {ReactComponent as Play} from '../../../images/play.svg';
import { useTranslation } from 'react-i18next';

const VideoProductionTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='video-production-tablet'>
      <div className='video-production-tablet__content'>
        <div className="main-swiper-tablet__title">
        {t('video_prod_page.VIDEO_PROD')}
        <span>{t('video_prod_page.CREATING')}<span className="swiper-title-tablet__underline">&nbsp;{t('video_prod_page.TITLE_VIDEOS')}</span></span>
        </div>

        <div className="main-swiper-tablet__wrapper video-production-tablet__wrapper">
          <div className="video-production-tablet__wrapp">
            <a href="https://www.youtube.com/watch?v=VbDoLnsFUQ8&list=PLPFysJtDmSWokC3ZHq2JZ9dVML93esMu8&index=6" target="_blank" rel="noreferrer"  className="video-prod__wrapper-img-link">
            <img src={videoProdImg1} alt="video production" className="video-production-tablet__slide" loading="lazy"/>
              <div className="video-prod__wrapper-play-img">
                <Play className="video-prod__play-img" />
              </div>
            </a>
            <a href="https://youtu.be/uuUi99pWc9U" target="_blank" rel="noreferrer"  className="video-prod__wrapper-img-link">
              <img src={videoProdImg2} alt="video production" className="video-production-tablet__slide" loading="lazy"/>
              <div className="video-prod__wrapper-play-img">
                <Play className="video-prod__play-img" />
              </div>
            </a>
            <a href='https://www.youtube.com/watch?v=uuUi99pWc9U&list=PLPFysJtDmSWokC3ZHq2JZ9dVML93esMu8' target="_blank" className="video-production-tablet__watch-all" rel="noreferrer">
              <img src={arrowRight} alt="arrow" className="content-animation__watch-all-arrow" loading="lazy"/>
              {t('WATCH_ALL')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoProductionTablet