import React from 'react';
import { AnimationComponent } from '../../AnimationComponent';
import './ConceptDev.scss';

import scretch from '../../../images/ConceptDev/scretch.svg';
import rombhGroup from '../../../images/ConceptDev/rhomb-group.svg'
import playArrows from '../../../images/ConceptDev/play-arrows.svg';
import logo from '../../../images/ConceptDev/logo-concept-dev.svg';
import { allAnimationsConceptDevPage,
        CONCEPT_DEV_BORDER_GRAY, 
        CONCEPT_DEV_BORDER_YELLOW,
        CONCEPT_DEV_LOGO_FOOTER,
        CONCEPT_DEV_PLAY_ARROWS,
        CONCEPT_DEV_RECTANGLES_TOP,
        CONCEPT_DEV_SCRATCH,
        CONCEPT_DEV_TITLE, 
        CONCEPT_DEV_RECTANGLES_BOTTOM
} from './utils/animations';
  import { useTranslation } from 'react-i18next';

export const ConceptDev = ({currentPageIndex, animationAction, prevPage}) => {
const { t } = useTranslation();

  return (
    <div>
      <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsConceptDevPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={8}
        isTransform
      >

        <div className="concept-dev__rectangles" id={CONCEPT_DEV_RECTANGLES_TOP}>
          <div className="concept-dev__rectangle-gradient"></div>
          <div className="concept-dev__rectangle-border"></div>
        </div>

        <div className="concept-dev__border-gray" id={CONCEPT_DEV_BORDER_GRAY}></div>

        <img src={rombhGroup} alt="rhomb group" className="concept-dev__border-yellow" id={CONCEPT_DEV_BORDER_YELLOW} loading="lazy"></img>

        <span className="concept-dev__title" id={CONCEPT_DEV_TITLE}>
          {t('CONCEPT_DEV_VISUAL')} 
          <span className="concept-dev__title-ampersand"> {t('AND')} </span>  
          {t('DESIGN')}
        </span>

        <img src={playArrows} alt="play arrows" className="concept-dev__play-arrows" id={CONCEPT_DEV_PLAY_ARROWS} loading="lazy"/>

        <img src={scretch} alt="scretch" className="concept-dev__scretch" id={CONCEPT_DEV_SCRATCH} loading="lazy"/>

        <img src={logo} alt="logo" className="concept-dev__logo" id={CONCEPT_DEV_LOGO_FOOTER} loading="lazy"/>

        <div className="concept-dev__rectangles-bottom" id={CONCEPT_DEV_RECTANGLES_BOTTOM}>
          <div className="concept-dev__rectangle-gradient-bottom">
            <div className="concept-dev__rectangle-border-bottom"></div>
          </div>
        </div>

      </AnimationComponent>
    </div>
  )
}

export default ConceptDev