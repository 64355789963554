import React from 'react';
import './ContentAnimationsTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import contentAnimationsImg1 from '../../../images/ContentAnimations/content-animations-1.png';
import contentAnimationsImg2 from '../../../images/ContentAnimations/content-animations-2.png';
import contentAnimationsImg3 from '../../../images/ContentAnimations/content-animations-3.png';
import contentAnimationsImg4 from '../../../images/ContentAnimations/content-animations-4.jpg';
import contentAnimationsImg5 from '../../../images/ContentAnimations/content-animations-5.jpg';
import contentAnimationsImg6 from '../../../images/ContentAnimations/content-animations-6.png';
import arrowDown from '../../../images/ContentAnimations/arrow-down-tablet.svg';
import arrowRight from '../../../images/ContentAnimations/arrow-right-tablet.svg';
import { useTranslation } from 'react-i18next';

const ContentAnimationsTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='content-animations-tablet'>
      <div className='content-animations-tablet__content'>
        <div className="main-swiper-tablet__title">
        {t('visual_brand_page.TITLE')}
        <span className="swiper-title-tablet__underline">&nbsp;{t('visual_brand_page.TITLE_ANIMATION')}</span>
        </div>

        <div className="main-swiper-tablet__wrapper content-animations-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet content-animations-tablet__swiper"
          >  
            <SwiperSlide>
              <div className="content-animations-tablet__wrapp">
                <img src={contentAnimationsImg1} alt="content animations" className="content-animations-tablet__slide" loading="lazy"/>
                <a href="https://www.youtube.com/watch?v=ii4x0C2XRpI&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u&index=5" target="_blank" className="content-animations-tablet__img-link" rel="noreferrer">
                  <img src={contentAnimationsImg2} alt="content animations" className="content-animations-tablet__slide" loading="lazy"/>
                </a>
                <img src={arrowDown} alt="arrow down" className="content-animations__arrow-down" loading="lazy"/>
                <a href="https://www.youtube.com/watch?v=qn470XWkk_o&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u" target="_blank" className="content-animations-tablet__watch-all" rel="noreferrer">
                  <img src={arrowRight} alt="arrow right" className="content-animation__watch-all-arrow" loading="lazy"/>
                  {t('visual_brand_page.WATCH_ALL')}
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="content-animations-tablet__wrapp">
                <img src={contentAnimationsImg4} alt="content animations" className="content-animations-tablet__slide" loading="lazy"/>
                <a href="https://www.youtube.com/watch?v=Bwp3yH1RIzE&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u&index=2" target="_blank" className="content-animations-tablet__img-link" rel="noreferrer">
                  <img src={contentAnimationsImg3} alt="content animations" className="content-animations-tablet__slide" loading="lazy"/>
                </a>
                <img src={arrowDown} alt="arrow down" className="content-animations__arrow-down" loading="lazy"/>
                <a href="https://www.youtube.com/watch?v=qn470XWkk_o&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u" target="_blank" className="content-animations-tablet__watch-all" rel="noreferrer">
                  <img src={arrowRight} alt="arrow right" className="content-animation__watch-all-arrow" loading="lazy"/>
                  {t('visual_brand_page.WATCH_ALL')}
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="content-animations-tablet__wrapp">
                <img src={contentAnimationsImg5} alt="content animations" className="content-animations-tablet__slide" loading="lazy"/>
                <a href="https://www.youtube.com/watch?v=mF4HM4GsylI&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u&index=3" target="_blank" className="content-animations-tablet__img-link" rel="noreferrer">
                  <img src={contentAnimationsImg6} alt="content animations" className="content-animations-tablet__slide" loading="lazy"/>
                </a>
                <img src={arrowDown} alt="arrow down" className="content-animations__arrow-down" loading="lazy"/>
                <a href="https://www.youtube.com/watch?v=qn470XWkk_o&list=PLPFysJtDmSWrtEJU-juWr9a1VD2zXM61u" target="_blank" className="content-animations-tablet__watch-all" rel="noreferrer">
                  <img src={arrowRight} alt="arrow right" className="content-animation__watch-all-arrow" loading="lazy"/>
                  {t('visual_brand_page.WATCH_ALL')}
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default ContentAnimationsTablet