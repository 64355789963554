import React from 'react';
import './MainFooterRectangles.scss';

const MainFooterRectangles = ({id}) => {
  return (
    <div className="footer__rectangle-gradient" id={id}>
      <div className="footer__rectangle-border"></div>
    </div>
  )
}

export default MainFooterRectangles