const animationSculptureMarketingServ = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenSculptureMarketingServ");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenSculptureMarketingServ");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showSculptureMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showSculptureMarketingServ");
            }, 2000)
        },
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopSculptureMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopSculptureMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownSculptureMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownSculptureMarketingServ");
            }, 2000)
        },
    }
}


const animationBorderGrayMarketingServ = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenBorderGrayMarketingServ");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenBorderGrayMarketingServ");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showBorderGrayMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showBorderGrayMarketingServ");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopBorderGrayMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopBorderGrayMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownBorderGrayMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownBorderGrayMarketingServ");
            }, 2000)
        },
    }
}


const animationTitleMarketingServ = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("transformToTopTitleMarketingServ");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("transformToTopTitleMarketingServ");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToDownTitleMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("transformToDownTitleMarketingServ");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("hiddenTitleMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("hiddenTitleMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("showTitleMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("showTitleMarketingServ");
            }, 2000)
        },
    }
}


const animationTopRectanglesMarketingServ = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenTopRectanglesMarketingServ");
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenTopRectanglesMarketingServ");
            }, 1500)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showTopRectanglesMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showTopRectanglesMarketingServ");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopRectanglesTopMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopRectanglesTopMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownRectanglesTopMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownRectanglesTopMarketingServ");
            }, 2000)
        },
    }
}


const animationLogoFooterMarketingServ= () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenFooterLogoMarketingServ");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenFooterLogoMarketingServ");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showFooterLogoMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showFooterLogoMarketingServ");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopLogoFooterMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopLogoFooterMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownLogoFooterMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownLogoFooterMarketingServ");
            }, 2000)
        },
    }
}


const animationPlayArrowsMarketingServ = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenPlayArrowsMarketingServ");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenPlayArrowsMarketingServ");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showPlayArrowsMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showPlayArrowsMarketingServ");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopPlayArrowsMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopPlayArrowsMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownPlayArrowsMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownPlayArrowsMarketingServ");
            }, 2000)
        },
    }
}


const animationScretchMarketingServ = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenScretchMarketingServ");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenScretchMarketingServ");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showScretchMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showScretchMarketingServ");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopScretchMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopScretchMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownScretchMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownScretchMarketingServ");
            }, 2000)
        },
    }
}



const animationRectanglesBottomMarketingServ = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenRectanglesBottomMarketingServ");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenRectanglesBottomMarketingServ");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showRectanglesBottomMarketingServ");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showRectanglesBottomMarketingServ");
            }, 2000)
        },
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopRectanglesBottomMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopRectanglesBottomMarketingServ");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownRectanglesBottomMarketingServ");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownRectanglesBottomMarketingServ");
            }, 2000)
        },
    }
}



export const MARKETING_SERV_SCUPLTURE = 'MARKETING_SERV_SCUPLTURE';
export const MARKETING_SERV_BORDER_GRAY = 'MARKETING_SERV_BORDER_GRAY';
export const MARKETING_SERV_TITLE = 'MARKETING_SERV_TITLE';
export const MARKETING_SERV_TOP_RECTANGLES = 'MARKETING_SERV_TOP_RECTANGLES';
export const MARKETING_SERV_PLAY_ARROWS = 'MARKETING_SERV_PLAY_ARROWS';
export const MARKETING_SERV_LOGO_FOOTER = 'MARKETING_SERV_LOGO_FOOTER';
export const MARKETING_SERV_SCRETCH = 'MARKETING_SERV_SCRETCH';
export const MARKETING_SERV_RECTANGLES_BOTTOM = 'MARKETING_SERV_RECTANGLES_BOTTOM';


export const allAnimationsMarketingServicesPage = {
    MARKETING_SERV_SCUPLTURE: animationSculptureMarketingServ(),
    MARKETING_SERV_BORDER_GRAY: animationBorderGrayMarketingServ(),
    MARKETING_SERV_TITLE: animationTitleMarketingServ(),
    MARKETING_SERV_TOP_RECTANGLES: animationTopRectanglesMarketingServ(),
    MARKETING_SERV_LOGO_FOOTER: animationLogoFooterMarketingServ(),
    MARKETING_SERV_PLAY_ARROWS: animationPlayArrowsMarketingServ(),
    MARKETING_SERV_SCRETCH: animationScretchMarketingServ(),
    MARKETING_SERV_RECTANGLES_BOTTOM: animationRectanglesBottomMarketingServ(),
}
