import React from 'react';
import './EyesEuropeTablet.scss';

import mainImg from '../../../images/EyesEurope/eyes-europe-main.png';
import logo from '../../../images/EyesEurope/eyes-europe-logo-tablet.png'
import { useTranslation } from 'react-i18next';

const EyesEuropeTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='eyes-europe-tablet' id="our-rewards">
      <div className='eyes-europe-tablet__content'>
        <img src={logo} alt="logo" className='eyes-europe-tablet__logo' loading="lazy"/>
        <div className='eyes-europe-tablet__text'>{t('our_rewards_page.TEXT')}</div>
      </div>
      <img src={mainImg} alt="mainImg" className='eyes-europe-tablet__main-img' loading="lazy"/>
    </div>
  )
}

export default EyesEuropeTablet