import React from 'react';
import './TeamTablet.scss';

import { getTeamData } from '../teamData';
import TeamTabletCard from './TeamTabletCard/TeamTabletCard';
import { useTranslation } from 'react-i18next';

const TeamTablet = () => {
  const { t } = useTranslation();

  const team = getTeamData(t);
  
	return (
		<div className='team-tablet'>
			<div className='team-tablet__content'>
					{
						team.map(item => (
							<TeamTabletCard
								key={item.id}
								{...item}
							/>
						))
					}
			</div>
		</div>
	)
}

export default TeamTablet