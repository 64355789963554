import React from 'react';
import './OurClientsTablet.scss';

import rectanglesTop from '../../../images/WhoWeAre/rectangle-group.svg';
import queenMary from '../../../images/OurClients/queen-mary-tablet.svg';
import dtek from '../../../images/OurClients/dtek-tablet.svg';
import glovo from '../../../images/OurClients/glovo-tablet.svg';
import kiyvstar from '../../../images/OurClients/kyivstar-tablet.svg';
import mediaGroup from '../../../images/OurClients/media-tablet.svg';
import nestle from '../../../images/OurClients/nestle-tablet.svg';
import nlo from '../../../images/OurClients/nlo-tv-tablet.svg';
import darnytsia from '../../../images/OurClients/darnytsia.png';
import game_world from '../../../images/OurClients/game-world.png';
import ukraine_pavillion from '../../../images/OurClients/ukraine_pavillion.png';
import roche from '../../../images/OurClients/roche-tablet.svg';
import ukraine from '../../../images/OurClients/ukraine-tablet.svg';
import yakaboo from '../../../images/OurClients/yakaboo-tablet.svg';
import room8group from '../../../images/OurClients/R8G-logo.svg';
import { useTranslation } from 'react-i18next';

const OurClientsTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='our-clients-tablet' id="our-clients">
      <div className='our-clients-tablet__top'>
        <img src={rectanglesTop} alt="rectanglesTop" className='our-clients-tablet__top-rectangles' loading="lazy"/>
        <span className='our-clients-tablet__top-title'>{t('our_clients_page.TITLE')}</span>
      </div>
      <div className='our-clients-tablet__content'>
      <span className="our-clients-tablet__content-worldwide">{t('our_clients_page.WORLDWIDE')}</span>
        <div className="our-clients-tablet__content-block-world">
          <img src={queenMary} alt="queenMary" loading="lazy"/>
          <img src={dtek} alt="dtek" loading="lazy"/>
          <img src={glovo} alt="glovo" loading="lazy"/>
          <img src={ukraine_pavillion} alt="ukraine_pavillion" loading="lazy"/>
          <img src={game_world} alt="game_world" className="our-clients__game-world" loading="lazy"/>
          <img src={room8group} alt="room8group" className="our-clients__room8group" loading="lazy"/>
        </div>
        <span className="our-clients-tablet__content-ukraine">{t('our_clients_page.UKRAINE')}</span>
        <div className="our-clients-tablet__content-block-ukraine">
          <img src={nestle} alt="nestle" loading="lazy"/>
          <img src={roche} alt="roche" loading="lazy"/>
          <img src={darnytsia} alt="darnytsia" loading="lazy" className="our-clients__darnytsia"/>
          <img src={mediaGroup} alt="mediaGroup" loading="lazy"/>
        </div>
        <div className="our-clients-tablet__content-block-general">
          <img src={kiyvstar} alt="kiyvstar" loading="lazy"/>
          <img src={yakaboo} alt="yakaboo" loading="lazy"/>
          <img src={nlo} alt="nlo" loading="lazy"/>
          <img src={ukraine} alt="ukraine" loading="lazy"/>
        </div>
      </div>
    </div>
  )
}

export default OurClientsTablet