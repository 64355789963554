import React from 'react';
import './TabletComponent.scss';
import ConceptDevTablet from '../../components/ConceptDev/Tablet/ConceptDevTablet';
import ContentGraphicsTablet from '../../components/Content3dGraphics/Tablet/Content3dGraphicsTablet';
import ContentAnimationsTablet from '../../components/ContentAnimations/Tablet/ContentAnimationsTablet';
import EyesEuropeTablet from '../../components/EyesEurope/Tablet/EyesEuropeTablet';
import MainPageTablet from '../../components/Main/Tablet/MainPageTablet';
import MarketingServicesTablet from '../../components/MarketingServices/Tablet/MarketingServicesTablet';
import CorporateBookTablet from '../../components/Swipers/SwiperCorporateBook/Tablet/CorporateBookTablet';
import CreationGiftsTablet from '../../components/Swipers/SwiperCreationGifts/Tablet/CreationGiftsTablet';
import CreationPacksTablet from '../../components/Swipers/SwiperCreationPacks/Tablet/CreationPacksTablet';
import CreationProdTablet from '../../components/Swipers/SwiperCreationProd/Tablet/CreationProdTablet';
import DesignInstallationTablet from '../../components/Swipers/SwiperDesign/Tablet/DesignInstallationTablet';
import DubaiExpoTablet from '../../components/Swipers/SwiperDubaiExpo/Tablet/DubaiExpoTablet';
import ExhibitionTablet from '../../components/Swipers/SwiperExhibition/Tablet/ExhibitionTablet';
import KeyVisualTablet from '../../components/Swipers/SwiperKeyVisual/Tablet/KeyVisualTablet';
import LogoIdentityTablet from '../../components/Swipers/SwiperLogoIdentity/Tablet/LogoIdentityTablet';
import PrintingDesignTablet from '../../components/Swipers/SwiperPrintingDesign/Tablet/PrintingDesignTablet';
import TeamTablet from '../../components/Team/Tablet/TeamTablet';
import TeamDescrTablet from '../../components/TeamDescription/Tablet/TeamDescrTablet';
import UniqueTablet from '../../components/Unique/Tablet/UniqueTablet';
import VideoProductionTablet from '../../components/VideoProduction/Tablet/VideoProductionTablet';
import WhatDoingTablet from '../../components/WhatWeDoing/Tablet/WhatDoingTablet';
import WhoAreTablet from '../../components/WhoAre/Tablet/WhoAreTablet';
import Header from '../../ui/Header/Header';
import QueenMaryTablet from '../../components/QueenMary/Tablet/QueenMaryTablet';
import PageRocheTablet from '../../components/Roche/Tablet/PageRocheTablet';
import WebsitesTablet from '../../components/Websites/Tablet/WebsitesTablet';
import OurClientsTablet from '../../components/OurClients/Tablet/OurClientsTablet';
import ContactUsTablet from '../../components/ContactUs/Tablet/ContactUsTablet';
import PageEnjoyTablet from '../../components/Enjoy/Tablet/PageEnjoyTablet';
import PopupFeedback from '../../ui/PopupFeedback/PopupFeedback';

export const TabletComponent = () => {
  return (
    <div className='tablet-component'>
      <Header /> 
      <MainPageTablet />
      <WhoAreTablet />
      <UniqueTablet />
      <TeamDescrTablet />
      <TeamTablet />
      <EyesEuropeTablet />
      <WhatDoingTablet />
      <ConceptDevTablet />
      <LogoIdentityTablet />
      <KeyVisualTablet />
      <CorporateBookTablet />
      <PrintingDesignTablet />
      <ExhibitionTablet />
      <DesignInstallationTablet />
      <DubaiExpoTablet />
      <ContentAnimationsTablet />
      <ContentGraphicsTablet />
      <VideoProductionTablet />
      <MarketingServicesTablet />
      <CreationProdTablet />
      <CreationPacksTablet />
      <CreationGiftsTablet />
      <QueenMaryTablet />
      <PageRocheTablet />
      <WebsitesTablet />
      <OurClientsTablet />
      <ContactUsTablet />
      <PageEnjoyTablet />
    </div>
  )
}
