import React from 'react';
import './CreationGiftsTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import creationGiftsSlide1 from '../../../../images/CreationGifts/creation-gifts-1.png';
import creationGiftsSlide2 from '../../../../images/CreationGifts/creation-gifts-2.png';
import creationGifts4 from '../../../../images/CreationGifts/creation-gifts-4.jpg';
import creationGifts5 from '../../../../images/CreationGifts/creation-gifts-5.png';
import { useTranslation } from 'react-i18next';

const CreationGiftsTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='creation-gifts-tablet'>
      <div className='creation-gifts-tablet__content'>
        <div className="creation-gifts-tablet__content-title main-swiper-tablet__title">
           <span>{t('CREATION')} <span className="swiper-main-tablet__ampersand">&nbsp;{t('AND')}&nbsp;</span></span>
           <span>{t('creation_gifts_page.PRODUCTION_PROMO')}<span className="swiper-title-tablet__underline"></span></span>
        </div>

        <div className="main-swiper-tablet__wrapper creation-gifts-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet"
          >  
            <SwiperSlide>
              <div className="creation-gifts-tablet__wrapp">
                <div className="creation-gifts-tablet__top-block">
                  <div className="creation-gifts-tablet__top-block-wrapp">
                    <img src={creationGiftsSlide1} alt="" className="creation-gifts-tablet__top-block-img" loading="lazy"/>
                    <div className="creation-gifts-tablet__top-block-text">{t('creation_gifts_page.TEXT_TOP_1')}</div>
                  </div>
                  <div className="creation-gifts-tablet__top-block-bottom">
                    <img src={creationGifts4} alt="" className="creation-gifts-tablet__top-block-bottom-img" loading="lazy"/>
                  </div>
                </div>

                <div className="creation-gifts-tablet__bottom-block">
                  <div className="creation-gifts-tablet__bottom-block-bottom">
                    <img src={creationGifts5} alt="" className="creation-gifts-tablet__bottom-block-bottom-img" loading="lazy"/>
                      <div className="creation-gifts-tablet__bottom-block-bottom-text">{t('creation_gifts_page.TEXT_BOTTOM')} </div>
                  </div>
                  <div className="creation-gifts-tablet__bottom-block-wrapp">
                    <img src={creationGiftsSlide2} alt="" className="creation-gifts-tablet__bottom-block-img" loading="lazy"/>
                    <div className="creation-gifts-tablet__bottom-block-text">{t('creation_gifts_page.TEXT_TOP_2')}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default CreationGiftsTablet