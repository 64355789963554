import React from 'react';
import './TeamDescription.scss';

import heroImg from '../../../images/Team/TeamHeroImage.svg';
import playArrows from '../../../images/WhoWeAre/play-arrows.svg';
import ourTeam from '../../../images/Team/our-team.svg';
import teamStickyTape from '../../../images/Team/sticky-tape-team.svg';
import rhombGroup from '../../../images/Team/rombh-group.svg';
import waves from '../../../images/waves.svg';

import { AnimationComponent } from '../../AnimationComponent';
import { allAnimationsTeamDescrPage, 
        TEAM_DESCR_WAVES, 
        TEAM_HEADER_RECTANGLES, 
        TEAM_IMG_HERO, 
        TEAM_LOGO_FOOTER, 
        TEAM_PLAY_ARROWS, 
        TEAM_RECTANGLE_BORDER, 
        TEAM_DESCR_TITLE,
        TEAM_STICKY_TAPE,
      } from './utils/animations';
import TeamDescrHeaderRectangles from '../Desktop/TeamDescrHeaderRectangles/TeamDescrHeaderRectangles';
import { useTranslation } from 'react-i18next';

export const TeamDescription = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div className="team-descr"> 
      <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsTeamDescrPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={4}
        isTransform
        isMultiAnimation
      >

        <div className="team-descr__header-rectangles" id={TEAM_HEADER_RECTANGLES}>
          <TeamDescrHeaderRectangles />
        </div>

        <img src={heroImg} alt="hero main" className="team-descr__img-hero" id={TEAM_IMG_HERO} loading="lazy"/>
    
        <img src={waves} alt="waves" className="team-descr__waves" id={TEAM_DESCR_WAVES} loading="lazy"/>

        <div className="team-descr__title-block" id={TEAM_DESCR_TITLE}>
          <span className="team-descr__title">{t('our_team_page.TITLE')}</span>
          <span className="team-descr__text">
            {t('our_team_page.DESCRIPTION_LINE_1')}<br />
            {t('our_team_page.DESCRIPTION_LINE_2')}
          </span>
        </div>

        <img src={teamStickyTape} alt="sticky tape" className="team__sticky-tape" id={TEAM_STICKY_TAPE} loading="lazy"/>

        {/* <div id={TEAM_DESCR_TEXT}>
          <span className="team-descr__text">
            {t('our_team_page.DESCRIPTION_LINE_1')}<br />
            {t('our_team_page.DESCRIPTION_LINE_2')}
          </span>
        </div> */}

        <img src={rhombGroup} alt="rhomb group" className="team-descr__rectangle-border" id={TEAM_RECTANGLE_BORDER} loading="lazy"/>

        <img src={playArrows} alt="play-arrows" className="team-descr__arrows" id={TEAM_PLAY_ARROWS} loading="lazy"/>

        <div id={TEAM_LOGO_FOOTER} className="team-descr__footer">
            <img src={ourTeam} alt="logo-footer" className="team-descr__footer-logo" loading="lazy"/>
        </div>

      </AnimationComponent>
    </div>
  )
}

export default TeamDescription