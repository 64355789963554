import React from 'react';
import './MainImgHero.scss';

import heroImage from '../../../../images/HeroImage.png';

const MainImgHero = ({id}) => {
  return (
    <img src={heroImage} alt="main__hero-img" className="main__hero-img" id={id} loading="lazy"/>
  )
}

export default MainImgHero