const animationQueenMaryBorderRhomb = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenRombhBorderQueenMary");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenRombhBorderQueenMary");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showRombhBorderQueenMary");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showRombhBorderQueenMary");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopQueenMaryDies");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownQueenMaryDies");
            }, 2000)
        },
    }
}


const animationQueenMaryWaves = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWavesQueenMary");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenWavesQueenMary");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWavesQueenMary");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showWavesQueenMary");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopQueenMaryDies");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownQueenMaryDies");
            }, 2000)
        },
    }
}


const animationQueenMaryDescr = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDescrQueenMary");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenDescrQueenMary");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDescrQueenMary");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showDescrQueenMary");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopQueenMaryDies");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownQueenMaryDies");
            }, 2000)
        },
    }
}


const animationQueenMaryDocument = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDocumentQueenMary");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDocumentQueenMary");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDocumentQueenMary");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDocumentQueenMary");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopQueenMaryDies");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownQueenMaryDies");
            }, 2000)
        },
    }
}


const animationQueenMaryDots = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDotsQueenMary");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenDotsQueenMary");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDotsQueenMary");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showDotsQueenMary");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopQueenMaryDies");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownQueenMaryDies");
            }, 2000)
        },
    }
}


const animationQueenMaryStrokes = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenStrokesQueenMary");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenStrokesQueenMary");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showStrokesQueenMary");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showStrokesQueenMary");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopQueenMaryDies");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownQueenMaryDies");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownQueenMaryDies");
            }, 2000)
        },
    }
}


const animationQueenMaryLaptop = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenLaptopQueenMary");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenLaptopQueenMary");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showLaptopQueenMary");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showLaptopQueenMary");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopQueenMaryLaptop");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopQueenMaryLaptop");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDownQueenMaryLaptop");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownQueenMaryLaptop");
            }, 1800)
        },
    }
}




export const QUEEN_MARY_DESCR = 'QUEEN_MARY_DESCR';
export const QUEEN_MARY_RHOMB_BORDER = 'QUEEN_MARY_RHOMB_BORDER';
export const QUEEN_MARY_DOCUMENT = 'QUEEN_MARY_DOCUMENT';
export const QUEEN_MARY_LAPTOP = 'QUEEN_MARY_LAPTOP';
export const QUEEN_MARY_DOTS = 'QUEEN_MARY_DOTS';
export const QUEEN_MARY_STROKES = 'QUEEN_MARY_STROKES';
export const QUEEN_MARY_WAVES = 'QUEEN_MARY_WAVES';


export const allAnimationsQueenMaryPage = {
    QUEEN_MARY_RHOMB_BORDER: animationQueenMaryBorderRhomb(),
    QUEEN_MARY_WAVES: animationQueenMaryWaves(),
    QUEEN_MARY_DESCR: animationQueenMaryDescr(),
    QUEEN_MARY_DOCUMENT: animationQueenMaryDocument(),
    QUEEN_MARY_DOTS: animationQueenMaryDots(),
    QUEEN_MARY_STROKES: animationQueenMaryStrokes(),
    QUEEN_MARY_LAPTOP: animationQueenMaryLaptop(),
}