import React from 'react';
import './WhatDoingListTablet.scss';

const WhatDoingListTablet = ({img, title, subtitle}) => {
  return (
    <div className="what-doing-tablet-list">
      <img src={img} alt="pic in paragraphs" className="what-doing-tablet-list__img" loading="lazy"/>
      <div className="what-doing-tablet-list__wrapp">
        <span className="what-doing-tablet-list__title">{title}</span>
        <span className="what-doing-tablet-list__subtitle">{subtitle}</span>
      </div>
    </div>
  )
}

export default WhatDoingListTablet