import React from 'react';
import './UniqueTablet.scss';

import playArrows from '../../../images/WhoWeAre/play-arrows.svg';
import stickyTape from '../../../images/Unique/sticky-tape.svg';
import mainImg from '../../../images/Unique/main-img-tablet.png';
import { useTranslation } from 'react-i18next';

const UniqueTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='unique-tablet'>
      <div className='unique-tablet__top'>
        <span className='unique-tablet__top-title'>{t('unique_page.TITLE')}<br/> {t('unique_page.TITLE_UNIQUE')}</span>
        <img src={stickyTape} alt="sticky tape" className='unique-tablet__top-sticky-tape' loading="lazy"/>
      </div>

      <div className='unique-tablet__content'>
        <div className='unique-tablet__content-block'>
          <span className='unique-tablet__content-text'>{t('unique_page.TEXT')}</span>
          <div className="unique-tablet-rectangles-under-text">
            <div className="unique-tablet-rectangles-under-text__border"></div>
          </div>
          <img src={playArrows} alt="play arrows" className='unique-tablet__content-play-arrows' loading="lazy"/>
        </div>
      </div>

      <img src={mainImg} alt="mainImage" className='unique-tablet__content-main-img' loading="lazy"/>
    </div>
  )
}

export default UniqueTablet