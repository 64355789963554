import React, { useCallback } from 'react';
import './WhatDoingTablet.scss';

import { getWhatDoingData } from '../WhatDoingData';
import WhatDoingContentTablet from './WhatDoingContentTablet/WhatDoingContentTablet';
import { useTranslation } from 'react-i18next';

const WhatDoingTablet = () => {
  const { t } = useTranslation();

  const whatDoingData = getWhatDoingData(t);
  
  return (
    <div className='what-doing-tablet' id="what-are-doing">
      <div className='what-doing-tablet__wrapp'>
      <div className="what-doing-tablet__title">{t('what_we_doing.TITLE_LEFT')} <span className="what-doing-tablet__title-we">{t('what_we_doing.TITLE_RIGHT')}</span></div>
        {
          whatDoingData.map(item => (
            <WhatDoingContentTablet
              {...item}
              key={item.id}
            />
          ))
        }
      </div>
    </div>
  )
}

export default WhatDoingTablet