import React from 'react';
import './UniqueDescr.scss';

import playArrows from '../../../../images/WhoWeAre/play-arrows.svg';
import { useTranslation } from 'react-i18next';

const UniqueDescr = ({id}) => {
  const { t } = useTranslation();
  
  return (
    <div className="unique__descr" id={id}>
      <span className="unique__descr-text">{t('unique_page.TEXT')}</span>
      <div className="unique-rectangles-under-text">
          <div className="unique-rectangles-under-text__border"></div>
      </div>
      <img src={playArrows} alt="play-arrows" id={id} className="unique__play-arrows" loading="lazy"/>
    </div>
  )
}

export default UniqueDescr