import React from 'react';
import './KeyVisualTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import keyVisualSlide3 from '../../../../images/KeyVisual/key-visual-tablet-3.png';
import keyVisualSlide4 from '../../../../images/KeyVisual/key-visual-tablet-4.png';

import keyVisual1 from '../../../../images/KeyVisual/key-visual-1.jpg';
import keyVisual2 from '../../../../images/KeyVisual/key-visual-2.jpg';
import keyVisual3 from '../../../../images/KeyVisual/key-visual-3.jpg';
import keyVisual4 from '../../../../images/KeyVisual/key-visual-4.jpg';
import keyVisual5 from '../../../../images/KeyVisual/key-visual-5.jpg';

import { useTranslation } from 'react-i18next';

const KeyVisualTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='key-visual-tablet'>
      <div className='key-visual-tablet__content'>
        <div className="main-swiper-tablet__title">
          {t('KEY_VISUAL')}
          <span className="swiper-main-tablet__ampersand">&nbsp;{t('AND')}&nbsp;</span>
          <span className="swiper-title-tablet__underline">{t('NAMING')}</span>
        </div>

        <div className="main-swiper-tablet__wrapper key-visual-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet"
          >  
            <SwiperSlide>
              <div className="key-visual-tablet__wrapp">
                <div className="key-visual-tablet__slide">
                  <img src={keyVisual1} alt="" className="key-visual-tablet__top" loading="lazy"/>
                  <div className="key-visual-tablet__top-bottom">
                    <img src={keyVisual2} alt="" className="key-visual-tablet__top-bottom-first" loading="lazy"/>
                    <img src={keyVisual3} alt="" className="key-visual-tablet__top-bottom-second" loading="lazy"/>
                  </div>
                </div>
                <div className="key-visual-tablet__bottom">
                  <img src={keyVisual4} alt="" className="key-visual-tablet__bottom-first" loading="lazy"/>
                  <img src={keyVisual5} alt="" className="key-visual-tablet__bottom-second" loading="lazy"/>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="key-visual-tablet__wrapp">
                <img src={keyVisualSlide3} alt="key visual" className="key-visual-tablet__slide" loading="lazy"/>
                <img src={keyVisualSlide4} alt="key visual" className="key-visual-tablet__slide" loading="lazy"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default KeyVisualTablet