import React, { useCallback } from 'react';
import './WhatWeDoing.scss';

import rectangleGroup from '../../../images/WhatWeDoing/rectangles-group.svg';

import WhatWeDoingContent from './WhatWeDoingContent/WhatWeDoingContent';
import { AnimationComponent } from '../../AnimationComponent';
import { allAnimationsWhatDoingPage, WHAT_DOING_RECTANGLE_GROUP, WHAT_DOING_TITLE, WHAT_DOING_TOP_RECTANGLES, WHAT_DOING_WRAPPER } from './utils/animations';
 import { useTranslation } from 'react-i18next';
import { getWhatDoingData } from '../WhatDoingData';

export const WhatWeDoing = ({currentPageIndex, animationAction, prevPage, navToPage}) => {
  const { t } = useTranslation();

  const whatDoingData = getWhatDoingData(t);

  const moveToPage = useCallback((moveToPageIndex) => {
    navToPage(currentPageIndex, moveToPageIndex)
  }, [currentPageIndex]);

  return (
    <div className="what-doing">
      <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsWhatDoingPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={7}
      >

        <div className="what-doing__rectangles" id={WHAT_DOING_TOP_RECTANGLES}>
          <div className="what-doing__rectangle-gradient"></div>
          <div className="what-doing__rectangle-border"></div>
        </div>

        <img src={rectangleGroup} alt="rectangles group" className="what-doing__rectangle-group" id={WHAT_DOING_RECTANGLE_GROUP} loading="lazy"/>
        
        <div className="what-doing__wrapper" id={WHAT_DOING_WRAPPER}>

        <span className="what-doing__title" id={WHAT_DOING_TITLE}>{t('what_we_doing.TITLE_LEFT')} <span className="what-doing__title-we">{t('what_we_doing.TITLE_RIGHT')}</span></span>
          {
            whatDoingData.map(item => (
              <WhatWeDoingContent
                {...item}
                key={item.id}
                moveToPage={moveToPage}
              />
            ))
          }
        </div>
      </AnimationComponent>
    </div>
  )
}

export default WhatWeDoing