import React from 'react';
import './TeamDescrTablet.scss';

import mainImg from '../../../images/Team/team-main-img-tablet.svg';
import playArrows from '../../../images/WhoWeAre/play-arrows.svg';
import logoFooter from '../../../images/Team/our-team.svg'
import { useTranslation } from 'react-i18next';

const TeamDescrTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='team-descr-tablet'>
      <div className='team-descr-tablet__container'>
        <div className='team-descr-tablet__wrapper'>
          <span className='team-descr-tablet__title'>{t('our_team_page.TITLE')}</span>
          <div className='team-descr-tablet__descr'>
            <span>{t('our_team_page.DESCRIPTION_LINE_1')}</span>  <br /> 
            <span>{t('our_team_page.DESCRIPTION_LINE_2')}</span> 
          </div>
          <img src={mainImg} alt="hero with rectangles" className='team-descr-tablet__main-img' loading="lazy"/>
        </div>
      </div>
      <div className='team-descr-tablet__footer'>
        <img src={playArrows} alt="play arrows" className='team-descr-tablet__footer-play-arrows' loading="lazy"/>
        <img src={logoFooter} alt="footer logo" className='team-descr-tablet__footer-logo' loading="lazy"/>
      </div>
    </div>
  )
}

export default TeamDescrTablet