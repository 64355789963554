import React from 'react';
import './Websites.scss';

import dots from '../../../images/Websites/dots.svg';
import strokes from '../../../images/Websites/strokes.svg';
import waves from '../../../images/Websites/waves.svg';
import laptop1 from '../../../images/Websites/websites-laptop-1.png'
import laptop2 from '../../../images/Websites/websites-laptop-2.png'
import laptop3 from '../../../images/Websites/websites-laptop-3.png'

import {allAnimationsWebsitesPage, WEBSITES_DOTS, WEBSITES_RHOMB_BORDER, WEBSITES_TITLE, WEBSITES_WAVES } from './utils/animations';
import { AnimationComponent } from '../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const Websites = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div className="websites">
      <AnimationComponent           
        animationAction={animationAction} 
        animation={allAnimationsWebsitesPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={25}
      > 
        <div className="websites__rombh-border" id={WEBSITES_RHOMB_BORDER}></div>

        <div className="websites__title" id={WEBSITES_TITLE}>
          <span className="websites__title-text">{t('websites.TITLE')}</span> 

            <div className="websites__laptops">
            <img src={laptop1} alt="laptop" className="websites__laptop" loading="lazy"/>
            <img src={laptop2} alt="laptop" className="websites__laptop" loading="lazy"/>
            <img src={laptop3} alt="laptop" className="websites__laptop" loading="lazy"/>
          </div>    
        </div>

        <img src={strokes} alt="strokes" className="websites__strokes" id={WEBSITES_TITLE} loading="lazy"/>

        <img src={dots} alt="dots" className="websites__dots" id={WEBSITES_DOTS} loading="lazy"/>
        
        <img src={waves} alt="waves" className="websites__waves" id={WEBSITES_WAVES} loading="lazy"/>
        </AnimationComponent>
    </div>
  )
}

export default Websites
