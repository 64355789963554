import React from 'react';
import './PopupFeedback.scss';
import { useTranslation } from 'react-i18next';
import closIcon from '../../images/Menu/close.svg';
import { useState } from 'react';
import { useEffect } from 'react';

const PopupFeedback = ({setIsPopupOpen}) => {
  const { t } = useTranslation();

  const [popupClose, setPopupClose] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameDirty, setNameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [nameError, setNameError] = useState(<span>{t('popup_feedback.EMPTY_NAME_FIELD')}</span>);
  const [emailError, setEmailError] = useState(<span>{t('popup_feedback.EMPTY_EMAIL_FIELD')}</span>);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (emailError || nameError) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }, [emailError, nameError]);

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'email':
        setEmailDirty(true);
        break;
      case 'name':
        setNameDirty(true);
        break
      default:
    }
  }

  const emailHandller = (e) => {
    setEmail(e.target.value);
    // eslint-disable-next-line no-useless-escape
    const re =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmailError(<span>{t('popup_feedback.INVALID_EMAIL')}</span>)
    } else {
      setEmailError('')
    }
  }

  const nameHandler = (e) => {
    setName(e.target.value)
    if (!e.target.value.length) {
      setNameError(<span>{t('popup_feedback.EMPTY_NAME_FIELD')}</span>)
    } else {
      setNameError('')
    }
  }

  return (
    <div className={`popup-feedback ${popupClose ? 'popup-feedback__none' : '' } `}>
      <div  className='popup-feedback-close'
        onClick={() => {
          setPopupClose(prev => !prev)
          setTimeout(() => {
            setIsPopupOpen()
          }, 100)
        }}
      >
        <img src={closIcon} alt="close icon" loading="lazy"/>
      </div>
      <div className='popup-feedback-container'>
        <div className='popup-feedback-title'>
          <div className='popup-feedback-caption'>
            <b style={{color: "#2B2B2B"}}>{t('popup_feedback.LETS')}</b>
            <b style={{color: "#FDB813"}}>{t('popup_feedback.WORK')}</b>
          </div>
          {/* <div className='popup-feedback-text'>{t('popup_feedback.TEXT')}</div> */}
        </div>

        <form action="/app/mailer.php" method="POST">
          <div className='inputs-top'>
            <div className='input-wrapp'>
              <span className='input-title'>{t('popup_feedback.YOUR_NAME')}</span>
              <input onChange={e => nameHandler(e)} value={name} onBlur={(e) => blurHandler(e)} name="name" type="text" maxLength="30" className='name-input'/>
              {(nameDirty && nameError) && <div className='message-error'>{nameError}</div>}
            </div>
            <div className='input-wrapp'>
              <span className='input-title'>{t('popup_feedback.YOUR_EMAIL')}</span>
              <input onChange={e => emailHandller(e)} value={email} onBlur={(e) => blurHandler(e)} name="email" type="email" maxLength="30" className='email-input'/>
              {(emailDirty && emailError) && <div className='message-error'>{emailError}</div>}
            </div>
          </div>

          <div className='textarea-wrapp'>
            <span className='input-title'>{t('popup_feedback.COMMENT')}</span>
            <textarea name="comment" id="comment" className='popup-feedback-textarea'></textarea>
          </div>
          <button disabled={!formValid} type='submit' className='send-request'>{t('popup_feedback.SEND_REQUEST')}</button>
        </form>
      </div>
    </div>
  )
}

export default PopupFeedback
