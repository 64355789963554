import React from 'react';
import './EyesEurope.scss';

import eyesEuropeMain from '../../../images/EyesEurope/eyes-europe-main.png';
import eyesEuropeLogo from '../../../images/EyesEurope/eyes-europe-logo.svg';

import playArrows from '../../../images/WhoWeAre/play-arrows.svg';
import dots from '../../../images/dots-block.svg';
import strokes from '../../../images/dotted.svg';
import waves from '../../../images/waves.svg';

import { allAnimationsEyesEuropePage, EYES_EUROPE_DOTS, EYES_EUROPE_MAIN_IMG, EYES_EUROPE_PLAY_ARROW, EYES_EUROPE_ROMBH_BORDER, EYES_EUROPE_STROKES, EYES_EUROPE_TITLE, EYES_EUROPE_WAVES } from './utils/animations';
import { AnimationComponent } from '../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const EyesEurope = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div className="eyes-europe">
        <AnimationComponent           
          animationAction={animationAction} 
          animation={allAnimationsEyesEuropePage} 
          prevPage={prevPage} 
          currentPageIndex={currentPageIndex}
          pageIndex={6}
          isTransform
          isMultiAnimation
          isEndMultiAnimation
        > 
        <div className="eyes-europe__rombh-border" id={EYES_EUROPE_ROMBH_BORDER}></div>

        <div className="eyes-europe__title" id={EYES_EUROPE_TITLE}>
          <img src={eyesEuropeLogo} alt="eyes-europe-logo"  className="eyes-europe__logo" loading="lazy"/>
          <span className="eyes-europe__text">{t('our_rewards_page.TEXT')}</span>
          <img src={eyesEuropeMain} alt="eyes-europe-main-img" className="eyes-europe__main-img-tablet" loading="lazy"/>
        </div>     

        <img src={eyesEuropeMain} alt="eyes-europe-main-img" className="eyes-europe__main-img" id={EYES_EUROPE_MAIN_IMG} loading="lazy"/>
        
        <img src={playArrows} alt="play-arrows" className="eyes-europe__play-arrows" id={EYES_EUROPE_PLAY_ARROW} loading="lazy"/>

        <img src={dots} alt="dots" className="eyes-europe__dots" id={EYES_EUROPE_DOTS} loading="lazy"/>
        
        <img src={strokes} alt="strokes" className="eyes-europe__strokes" id={EYES_EUROPE_STROKES} loading="lazy"/>
                
        <img src={waves} alt="waves" className="eyes-europe__waves" id={EYES_EUROPE_WAVES} loading="lazy"/>
        </AnimationComponent>
    </div>
  )
}

export default EyesEurope
