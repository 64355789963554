import React from 'react';
import './TeamDescrHeaderRectangles.scss';

const TeamDescrHeaderRectangles = ({id}) => {
  return (
    <div className="header__rectangles" id={id}>
      <div className="header__rectangle-gradient"></div>
      <div className="header__rectangle-border"></div>
    </div>
  )
}

export default TeamDescrHeaderRectangles