const animationPrintingDesign = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenPrintingDesign");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenPrintingDesign");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showPrintingDesign");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showPrintingDesign");
            }, 2000)
        },
    }
}


const animationPrintingDesignTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenPrintingDesignTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenPrintingDesignTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showPrintingDesignTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showPrintingDesignTitle");
            }, 1800)
        },
    }
}





export const PRINTING_DESIGN_TITLE = 'PRINTING_DESIGN_TITLE';
export const PRINTING_DESIGN_SWIPER_WRAPPER = 'PRINTING_DESIGN_SWIPER_WRAPPER';


export const allAnimationsPrintingDesignPage = {
    PRINTING_DESIGN_SWIPER_WRAPPER: animationPrintingDesign(),
    PRINTING_DESIGN_TITLE: animationPrintingDesignTitle(),
}
