import React from 'react';
import './SwiperCreationProd.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import creationProd1 from '../../../../images/CreationProd/creation-prod-1.png';
import creationProd2 from '../../../../images/CreationProd/creation-prod-2.png';
import creationProd3 from '../../../../images/CreationProd/creation-prod-3.png';
import creationProd4 from '../../../../images/CreationProd/creation-prod-4.png';

import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsCreationProdPage, CREATION_PROD_TITLE, CREATION_PROD_WRAPPER } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperCreationProd = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsCreationProdPage} 
        prevPage={prevPage}
        currentPageIndex={currentPageIndex}
        pageIndex={20}
        isTransform
    >
        <span className="swiper-main__title creation-prod__title" id={CREATION_PROD_TITLE}>
          {t('creation_prod_page.TITLE_CREATION')}
          <span className="swiper-main__ampersand"> {t('AND')} </span>
          <span className="swiper-title__underline">{t('creation_prod_page.TITLE_PRODUCTION')}</span><br/> 
          {t('creation_prod_page.TITLE_B2B')}
        </span>

        <div className="main-swiper__wrapper creation-prod__wrapper" id={CREATION_PROD_WRAPPER}>
          <Swiper
              speed={1000}
              freeMode={true}
              navigation={true}
              modules={[FreeMode, Pagination, Navigation]}
              className="main-swiper"
          >
            <SwiperSlide>
              <div className="creation-prod">
                  <div className="creation-prod__left">
                      <img src={creationProd1} alt="" className="creation-prod__left-img" loading="lazy"/>
                      <div className="creation-prod__left-block">
                          <span>{t('DESIGN_LOWER_CASE')}</span>
                      </div>
                  </div>
                  <div className="creation-prod__right">
                      <img src={creationProd2} alt="" loading="lazy" />
                      <div className="creation-prod__right-block">
                        <span>{t('PRODUCTION')}</span>
                      </div>
                  </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="creation-prod-second">
                  <div className="creation-prod-second__left">
                      <img src={creationProd3} alt="" className="creation-prod-second__left-img" loading="lazy"/>
                      <div className="creation-prod-second__left-block">
                          <span>{t('DESIGN_LOWER_CASE')}</span>
                      </div>
                  </div>
                  <div className="creation-prod-second__right">
                      <img src={creationProd4} alt="" loading="lazy"/>
                      <div className="creation-prod-second__right-block">
                        <span>{t('PRODUCTION')}</span>
                      </div>
                  </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </AnimationComponent>
    </div>
  )
}


export default SwiperCreationProd