import React from 'react';
import './SwiperKeyVisual.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import keyVisualSlide2 from '../../../../images/KeyVisual/key-visual-slide2.svg';
import keyVisual1 from '../../../../images/KeyVisual/key-visual-1.jpg';
import keyVisual2 from '../../../../images/KeyVisual/key-visual-2.jpg';
import keyVisual3 from '../../../../images/KeyVisual/key-visual-3.jpg';
import keyVisual4 from '../../../../images/KeyVisual/key-visual-4.jpg';
import keyVisual5 from '../../../../images/KeyVisual/key-visual-5.jpg';

import { allAnimationsKeyVisualPage, KEY_VISUAL_SWIPER_WRAPPER, KEY_VISUAL_TITLE } from './utils/animations';
import { AnimationComponent } from '../../../AnimationComponent';
import { useTranslation } from 'react-i18next';

export const SwiperKeyVisual = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsKeyVisualPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={10}
        // isTransform
    >
        <span className="swiper-main__title key-visual__title" id={KEY_VISUAL_TITLE}>{t('KEY_VISUAL')} <span className="swiper-main__ampersand"> {t('AND')} </span><span className="swiper-title__underline">{t('NAMING')}</span></span>

        <div className="main-swiper__wrapper" id={KEY_VISUAL_SWIPER_WRAPPER}>
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper key-visual-swiper"
          >
            <SwiperSlide>
              <div className="key-visual">
                <div className="key-visual__left">
                    <img src={keyVisual1} alt="" className="key-visual__left-top" loading="lazy"/>
                    <div className="key-visual__left-bottom">
                        <img src={keyVisual2} alt="" className="key-visual__left-bottom-first" loading="lazy"/>
                        <img src={keyVisual3} alt="" className="key-visual__left-bottom-second" loading="lazy"/>
                    </div>
                </div>

                <div className="key-visual__right">
                    <img src={keyVisual4} alt="" className="key-visual__right-top" loading="lazy"/>
                    <img src={keyVisual5} alt="" className="key-visual__right-bottom" loading="lazy"/>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="key-visual-second">
                <img src={keyVisualSlide2} alt="" className="key-visual-slide-second" loading="lazy"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </AnimationComponent>
    </div>
  )
}


export default SwiperKeyVisual