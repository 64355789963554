export const EN_TRANSLATION = {
  AND: '&',
  ANNUAL_REPORT: 'Annual report',
  BOOTH: 'BOOTH',
  CALENDARS: 'Calendars',
  CREATION: 'CREATION',
  CONCEPT_DEV_VISUAL: 'Concept development, visual',
  CONCEPT: 'Concept',
  CORPORATE_BOOK: 'CORPORATE BOOK',
  DESIGN: 'DESIGN',
  DESIGNS: 'DESIGNS',
  DESIGN_LOWER_CASE: 'Designs',
  DEVELOPMENT: 'development',
  DUBAI_EXPO: 'DUBAI EXPO',
  DATA_2020 : '2020',
  EXHIBITION: 'EXHIBITION',
  EXHIBITION_INSTALLATION: 'Exhibition instalations',
  IDENTITY: 'IDENTITY',
  INSTALLATIONS: 'INSTALLATIONS',
  KEY_VISUAL: 'KEY VISUAL',
  LOGO: 'LOGO',
  MENU_BUTTON: 'MENU',
  NAMING: 'NAMING',
  PRINTING: 'PRINTING',
  PRINTING_DESIGN: 'PRINTING DESIGN',
  PRODUCTION: 'Production',
  PHOTO_ZONES: 'Photo zones',
  POSM: 'POSM',
  SEE_MORE: 'See more',
  VISUAL: 'visual',
  WATCH_ALL: 'Watch all videos',

  menu: { 
    ITEM_MENU_1: 'About us',
    ITEM_MENU_2: 'Our rewards',
    ITEM_MENU_3: 'What are we doing',
    ITEM_MENU_4: 'Concept development, visual & design',
    ITEM_MENU_5: 'Marketing services',
    ITEM_MENU_6: 'Our Clients',
    ITEM_MENU_7: 'Contacts',
  },
  main_page: {
    EYE_COMMS: "EYE COMMS",
    AGENCY: "AGENCY",
    CONCEPT_DEV: 'Concept development',
    VISUAL_DESIGN: 'Visual&design',
    MARKETING_SERVICES: 'Marketing services'
  },
  who_are_page: {
    TITLE: 'WHO WE ARE',
    TEXT_LINE_1: 'Hey, we are from Ukraine!',
    TEXT_LINE_2: `We are from a country of strong people in love with life. We have freedom of thought, creativity, independence, courage, and the ability to see beauty`,
    TEXT_LINE_2_DESKTOP: 'We are from a country of strong people in love with life. We have freedom of thought, creativity, independence, brave, and the ability to see beauty everywhere in our Ukrainian DNA.',
    TEXT_LINE_3: 'everywhere in our Ukrainian DNA.',
    TEXT_LINE_4: 'All these values provide the foundation for each of our projects.',
    TEXT_LINE_5: 'Each of our UA team members contributes to the cultural diversity in the world. So, EYE COMMS, as a MARKETING COMMUNICATION agency, is happy to further contribute to the cultural diversity of the world with our UA creativity.',
  },
  unique_page: {
    TITLE: 'WHAT MAKES US',
    TITLE_UNIQUE: 'UNIQUE',
    TEXT: 'Our agency provides a unique combination of creativity, traditions, and effective management. Once you work with us, you will never have to worry about details and implementation. Our projects always exceed expectations'
  },
  our_team_page: {
    TITLE: 'OUR TEAM',
    DESCRIPTION_LINE_1: 'Our team is a group of individuals with different hobbies and lifestyles.',
    DESCRIPTION_LINE_2: 'One thing we have in common is our passion for creating successful communication projects, love for our work and satisfaction with the result.'
  },
  team_people: {
    person_1: 'Yevheniia Tymofieieva',
    person_2: 'Olexandra Shtoiko',
    person_3: 'Olga Tereshko',
    person_4: 'Vladyslav Slyvka',
    person_5: 'Olena Didkivska',
    person_6: 'Olga Vysotska',
    person_7: 'Viktor Sionskyi',
    person_8: 'Viktoria Indychenko',
    person_9: 'Tamara Ovcharenko',
    person_10: 'Olexander Martyniuk',
    person_11: 'Olexander Hrytsenko',
    person_12: 'Anastasiia Babenko',
    person_13: 'Dmytro Khudolii',
    person_14: 'Vlad Ponomarenko',
    person_15: 'Vlad Trusov',
  },
  our_rewards_page: {
    TEXT: 'Eye Comms took first place in the Eyes&Ears Awards international competition in the SPECIALS & CROSS-MEDIA category, B2B HYBRID CONFERENCE, Best live 2021 with the project "The new strategy of DTEK 2030". The Eyes & Ears Awards is an international design and communications prize celebrating design, digital communications, promotion, cross-media communications, and craft projects. '
  },
  what_we_doing: {
    TITLE_LEFT: 'WHAT ARE',
    TITLE_RIGHT: 'WE DOING',
    CONCEPT_DEV_TITLE: 'Concept development, visual & design',
    MARKETING_SERVICES_TITLE: 'marketing services',
    BRAND_IDENTITY: 'Brand identity / ',
    NAMING_KEY_VISUAL: 'naming / key visual',
    MATERIAL_DESIGN: 'Material design',
    MATERIAL_DESIGN_ETC: '(printing, banners, exhibition booth, installation, etc)',
    VISUAL_BRAND_CONTENT: 'Visual brand content & Video production',
    VISUAL_BRAND_CONTENT_ETC: '(video motion, 3d graphics, animation)',
    CREATION_PRODUCTION: 'Creation & Production promo gifts',
    WEBSITES_LEARNING: 'Websites & learning platforms',
    EVENT_ORGANIZATION: 'Event organization'
  },
  logo_identity: {
    TEXT_TOP: 'The logo of  DTEK Academy was created as event key visual for the grand opening of the Academy.',
    TEXT_BOTTOM: 'Later, the logo became the basis for the identity of the Academy.'
  },
  visual_brand_page: {
    TITLE: 'VISUAL BRAND CONTENT/',
    TITLE_ANIMATION: 'ANIMATION',
    WATCH_ALL: 'Watch all videos'
  },
  visual_3d_page: {
    TITLE: 'VISUAL BRAND CONTENT/ 3D',
    TITLE_GRAPHICS: 'GRAPHICS',
  },
  video_prod_page: {
    TITLE: 'VIDEO PRODUCTION / CREATING',
    TITLE_VIDEOS: 'VIDEOS',
    VIDEO_PROD: 'VIDEO PRODUCTION /',
    CREATING: 'CREATING'
  },
  marketing_serv: {
    TITLE_MARKETING: 'Marketing',
    TITLE_SERVICES: 'services'
  },
  creation_prod_page: {
    TITLE_CREATION: 'CREATION',
    TITLE_PRODUCTION: 'PRODUCTION',
    TITLE_B2B: 'B2B PRESENTS',
    B2B: 'B2B',
    PRESENTS: 'PRESENTS'
  },
  creation_packs: {
    CREATION: 'CREATION',
    PRODUCTION: 'PRODUCTION',
    CONFERENCE_WELCOME: 'CONFERENCE WELCOME PACKS',
  },
  creation_gifts_page: {
    CREATION: 'CREATION',
    PRODUCTION_PROMO: 'PRODUCTION PROMO GIFTS',
    TEXT_TOP_1: 'New Year gift set with special kind of tee, term bottle with temperature indicator in luxury gift box with logo',
    TEXT_TOP_2: 'Term bottle with creative design – promo gift that is always needed',
    TEXT_BOTTOM: 'Creating and production of special pin as a reward for best employees ',
  },
  dubai_expo: {
    GLOBAL_EXHIBITION: 'The global exhibition is a unique world-wide event showcasing the latest technologies and innovations.',
    FOR_DTEK: 'For DTEK Group - the largest private investor in the Ukrainian energy sector - has been created and installed picturesque windmills at the Ukrainian pavilion at Expo 2020 Dubai as a symbol of our sustainable future. Renewable energy sources, which DTEK  is deploying across Ukraine, are the key path to reducing, and eventually transforming the energy sector so it can become CO2-free'
  },
  queen_mary_page: {
    QUEEN_MARY: 'QUEEN MARY',
    LEARNING_PLATFORM: 'Learning platform',
    TEXT_LINE_1: 'Training platform was developed in compliance with the requirements of improved security.',
    TEXT_LINE_2: 'Training was conducted for 150 people with the participation of lecturers from 7 countries.',
    TEXT_LINE_3: 'The platform gave participants access to personal accounts, online classes, Q&A, testing. There were 15 days of training over four months',
  },
  roche_page: {
    ROCHE: 'ROCHE',
    LEARNING_PLATFORM: 'Learning platform',
    TEXT_LINE_1: 'The Legal Training Platform was created as a united information portal with a separate unit - the School of Patient Organizations.',
    TEXT_LINE_2: 'After registering on the platform, the user has access to a personal account, the opportunity to tag interesting materials and join lectures and school sessions',
  },
  websites: {
    TITLE: 'Websites'
  },
  our_clients_page: {
    TITLE: 'OUR CLIENTS',
    WORLDWIDE: 'Eye Comms worldwide',
    UKRAINE: 'Eye Comms in Ukraine'
  },
  contact_us_page: {
    TITLE: 'Contact us',
    MAIN_OFFICE: 'main office',
    ADDRESS: '04077, Kyiv, Obolonska street, 29 of.205',
    WORK_TOGETHER: 'Let’s work together',
    CLIENT_SERVICE_DEPARTMENT: 'NEW BUSINESS DIRECTOR',
    MANAGING_DIRECTOR: 'Managing Director',
    ISPIRATION_LEADER: 'ISPIRATION LEADER, CO-OWNER',
    CREATIVE_DEPARTAMENT: 'CREATIVE DEPARTMENT',
    PERSON_NAME_1: 'Yevheniia Tymofieieva',
    PERSON_NAME_2: 'Olga Vysotskaya',
    PERSON_NAME_3: 'Viktor Sionskyi',
    PERSON_NAME_4: 'Oleksandra Shtoiko',
  },
  popup_feedback: {
    YOUR_NAME: 'Your name',
    YOUR_EMAIL: 'E-mail',
    COMMENT: 'Comment',
    SEND_REQUEST: 'Send Request',
    LETS: 'Let’s work',
    WORK: 'together',
    TEXT: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl diam mauris massa aenean eget. Aenean sed interdum risus nunc morbi magna. Accumsan mattis aliquet eu odio vulputate leo eget.',
    EMPTY_NAME_FIELD: `The name field can't be empty`,
    EMPTY_EMAIL_FIELD: `The email field can't be empty`,
    INVALID_EMAIL: 'Invalid email',
  },
  enjoy_page: {
    ENJOY_YOUR: 'ENJOY YOUR',
    EXPERIENCE: 'EXPERIENCE!',
    ENJOY: 'ENJOY',
    YOUR: 'YOUR',
  },
  copyright: {
    COPYRIGHT: '© All rights reserved. 2023',
    DEVELOPED: 'Website development',
  }
};
