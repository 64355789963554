import React, { useState } from 'react';
import './ContactUsTablet.scss';

import rectanglesGroup from '../../../images/OurClients/rectangles-group.svg';
import facebook from '../../../images/ContactUs/facebook.svg';
import linkedin from '../../../images/ContactUs/linkedin.svg';
import message from '../../../images/ContactUs/message.svg';

import { useTranslation } from 'react-i18next';
import PopupFeedback from '../../../ui/PopupFeedback/PopupFeedback';

export const ContactUsTablet = () => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="contact-us-tablet" id="contacts">
      <div className='contact-us-tablet__top'>
        <img src={rectanglesGroup} alt="rectangles group" className='contact-us-tablet__top-rectangles' loading="lazy"/>
        <span className='contact-us-tablet__top-title'>{t('contact_us_page.TITLE')}</span>
      </div>
      <div className="contact-us-tablet__content">
        <div className="contact-us-tablet__place">
          <div className="contact-us-tablet__place-title-main">
            <span className="contact-us-tablet__place-title">{t('contact_us_page.MAIN_OFFICE')}</span>
            <span className="contact-us-tablet__place-address">{t('contact_us_page.ADDRESS')}</span>
          </div>

            <div className="contact-us-tablet__sites">
              <div className="contact-us-tablet__sites-site">
                <img src={message} alt="message" loading="lazy"/>
                <a href="mailto:info@eye-c.com.ua" className="contact-us-tablet__email">info@eye-c.com.ua</a>
              </div>
              <a href='https://www.facebook.com/eyecommsagency/' target="_blank" className="contact-us-tablet__sites-site" rel="noreferrer">
                <img src={facebook} alt="facebook" loading="lazy"/>
                <span className="contact-us-tablet__sites-name">Facebook</span>
              </a>
              <div className="contact-us-tablet__sites-site">
                <img src={linkedin} alt="linkedin" loading="lazy"/>
                <span className="contact-us-tablet__sites-name">LinkedIn</span>
              </div>
            </div>
            <button className="contact-us-tablet__button-connection"
              onClick={() => {
                setIsPopupOpen(prev => !prev)
              }}
            >
              {t('contact_us_page.WORK_TOGETHER')}
            </button>
        </div>

        <div className="contact-us-tablet__person-block">
          <div className="contact-us-tablet__person-for-connection">
            <span className="contact-us-tablet__position">{t('contact_us_page.CLIENT_SERVICE_DEPARTMENT')}</span>
            <span className="contact-us-tablet__name">{t('contact_us_page.PERSON_NAME_1')}</span>
            <div className="contact-us-tablet__sites-site">
              <img src={message} alt="message" loading="lazy"/>
              <a href="mailto:m.saharova@eye-c.com.ua" className="contact-us-tablet__email">y.tymofieieva@eye-c.com.ua</a>
            </div>
          </div>
          <div className="contact-us-tablet__person-for-connection">
            <span className="contact-us-tablet__position">{t('contact_us_page.MANAGING_DIRECTOR')}</span>
            <span className="contact-us-tablet__name">{t('contact_us_page.PERSON_NAME_3')}</span>
            <div className="contact-us-tablet__sites-site">
              <img src={message} alt="message" loading="lazy"/>
              <a href="mailto:v.sionskyi@eye-c.com.ua" className="contact-us-tablet__email">v.sionskyi@eye-c.com.ua</a>
            </div>
          </div>
          <div className="contact-us-tablet__person-for-connection">
            <span className="contact-us-tablet__position">{t('contact_us_page.ISPIRATION_LEADER')}</span>
            <span className="contact-us-tablet__name">{t('contact_us_page.PERSON_NAME_2')}</span>
            <div className="contact-us-tablet__sites-site">
              <img src={message} alt="message" loading="lazy"/>
              <a href="mailto:o.vysotskaya@eye-c.com.ua" className="contact-us-tablet__email">o.vysotskaya@eye-c.com.ua</a>
            </div>
          </div>
          <div className="contact-us-tablet__person-for-connection">
            <span className="contact-us-tablet__position">{t('contact_us_page.ISPIRATION_LEADER')}</span>
            <span className="contact-us-tablet__name">{t('contact_us_page.PERSON_NAME_4')}</span>
            <div className="contact-us-tablet__sites-site">
              <img src={message} alt="message" loading="lazy"/>
              <a href="mailto:s.shtoiko@eye-c.com.ua" className="contact-us-tablet__email">s.shtoiko@eye-c.com.ua</a>
            </div>
          </div>
          <button className="contact-us-tablet__button-connection-tablet"
            onClick={() => {
              setIsPopupOpen(prev => !prev)
            }}
          >
            {t('contact_us_page.WORK_TOGETHER')}
          </button>
        </div>
        <div>
          {
            isPopupOpen && (
              <PopupFeedback
                setIsPopupOpen={() => setIsPopupOpen(prev => !prev)}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}



export default ContactUsTablet