import React from 'react';
import './ContactUs.scss';

import rectanglesGroup from '../../../images/OurClients/rectangles-group.svg';
import facebook from '../../../images/ContactUs/facebook.svg';
import linkedin from '../../../images/ContactUs/linkedin.svg';
import message from '../../../images/ContactUs/message.svg';

import { AnimationComponent } from '../../AnimationComponent';
import { allAnimationsOurContactsPage, OUR_CONTACTS_CONTENT, OUR_CONTACTS_RECTANGLES_TOP, OUR_CONTACTS_TITLE } from './utils/animations';
 import { useTranslation } from 'react-i18next';
import PopupFeedback from '../../../ui/PopupFeedback/PopupFeedback';
import { useState } from 'react';
 
export const ContactUs = ({currentPageIndex, animationAction, prevPage}) => {
const { t } = useTranslation();
const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div className="our-contacts">
      <AnimationComponent 
        animationAction={animationAction} 
        animation={allAnimationsOurContactsPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={27}
      >
        <img src={rectanglesGroup} alt="rectangles group" id={OUR_CONTACTS_RECTANGLES_TOP} className="our-contacts__rectangles-top" loading="lazy"/>
        <span className="our-contacts__title" id={OUR_CONTACTS_TITLE}>{t('contact_us_page.TITLE')}</span>

        <div className="our-contacts__content" id={OUR_CONTACTS_CONTENT}>
          <div className="our-contacts__place">
            <div className="our-contacts__place-title-main">
              <span className="our-contacts__place-title">{t('contact_us_page.MAIN_OFFICE')}</span>
              <span className="our-contacts__place-address">{t('contact_us_page.ADDRESS')}</span>
            </div>
            <div className="our-contacts__sites">
              <div className="our-contacts__sites-site" loading="lazy">
                <img src={message} alt="message" />
                <a href="mailto:info@eye-c.com.ua" className="our-contacts__email">info@eye-c.com.ua</a>
              </div>
              <div className="our-contacts__separator"></div>
              <a href='https://www.facebook.com/eyecommsagency/' target="_blank" className="our-contacts__sites-site" rel="noreferrer">
                <img src={facebook} alt="facebook" loading="lazy"/>
                <span className="our-contacts__sites-name">Facebook</span>
              </a>
              <div className="our-contacts__separator"></div>
              <div className="our-contacts__sites-site">
                <img src={linkedin} alt="linkedin" loading="lazy"/>
                <span className="our-contacts__sites-name">LinkedIn</span>
              </div>
            </div>
            <button className="our-contacts__button-connection"
              onClick={() => {
                setIsPopupOpen(prev => !prev)
              }}>
                {t('contact_us_page.WORK_TOGETHER')}
            </button>
          </div>

          <div className="our-contacts__person-block">
            <div className="our-contacts__person-for-connection">
              <span className="our-contacts__position">{t('contact_us_page.CLIENT_SERVICE_DEPARTMENT')}</span>
              <span className="our-contacts__name">{t('contact_us_page.PERSON_NAME_1')}</span>
              <div className="our-contacts__sites-site">
                <img src={message} alt="message" loading="lazy"/>
                <a href="mailto:m.saharova@eye-c.com.ua" className="our-contacts__email">y.tymofieieva@eye-c.com.ua</a>
              </div>
            </div>
            <div className="our-contacts__person-for-connection">
              <span className="our-contacts__position">{t('contact_us_page.MANAGING_DIRECTOR')}</span>
              <span className="our-contacts__name">{t('contact_us_page.PERSON_NAME_3')}</span>
              <div className="our-contacts__sites-site">
                <img src={message} alt="message" loading="lazy"/>
                <a href="mailto: v.sionskyi@eye-c.com.ua" className="our-contacts__email">v.sionskyi@eye-c.com.ua</a>
              </div>
            </div>
            <div className="our-contacts__person-for-connection">
              <span className="our-contacts__position">{t('contact_us_page.ISPIRATION_LEADER')}</span>
              <span className="our-contacts__name">{t('contact_us_page.PERSON_NAME_2')}</span>
              <div className="our-contacts__sites-site">
                <img src={message} alt="message" loading="lazy"/>
                <a href="mailto:o.vysotskaya@eye-c.com.ua" className="our-contacts__email">o.vysotskaya@eye-c.com.ua</a>
              </div>
            </div>
            <div className="our-contacts__person-for-connection">
              <span className="our-contacts__position">{t('contact_us_page.ISPIRATION_LEADER')}</span>
              <span className="our-contacts__name">{t('contact_us_page.PERSON_NAME_4')}</span>
              <div className="our-contacts__sites-site">
                <img src={message} alt="message" loading="lazy"/>
                <a href="mailto: s.shtoiko@eye-c.com.ua" className="our-contacts__email">s.shtoiko@eye-c.com.ua</a>
              </div>
            </div>
          </div>
        </div>
      </AnimationComponent>
        {
          isPopupOpen && (
            <PopupFeedback
              setIsPopupOpen={() => setIsPopupOpen(prev => !prev)}
            />
          )
         }
    </div>
  )
}



export default ContactUs