import React from 'react';
import './MainPageTablet.scss';

import mainHero from '../../../images/HeroImage.png';
import rectanglesTablet from '../../../images/Main/rectangles-tablet.svg';
import logoFooter from '../../../images/be-brave.png';
import { useTranslation } from 'react-i18next';

const MainPageTablet = () => {
  const { t } = useTranslation();

  return (
    <div className='main-page-tablet'>
      <div className='main-page-tablet__title'>
        <div className='main-page-tablet__title-main'>
          <span>{t('main_page.EYE_COMMS')}</span>
          <span>{t('main_page.AGENCY')}</span>
        </div>
        <div className='main-page-tablet__title-wrapp'>
          <span className='main-page-tablet__title-gray'>{t('main_page.CONCEPT_DEV')}</span>
          <span className='main-page-tablet__title-center'>{t('main_page.CONCEPT_DEV')}</span>
          <span className='main-page-tablet__title-gray'>{t('main_page.CONCEPT_DEV')}</span>
        </div>
        <div className='main-page-tablet__title-wrapp'>
          <span className='main-page-tablet__title-gray'>{t('main_page.VISUAL_DESIGN')}</span>
          <span className='main-page-tablet__title-center'>{t('main_page.VISUAL_DESIGN')}</span>
          <span className='main-page-tablet__title-gray'>{t('main_page.VISUAL_DESIGN')}</span>
        </div>
        <div className='main-page-tablet__title-wrapp'>
          <span className='main-page-tablet__title-gray'>{t('main_page.MARKETING_SERVICES')}</span>
          <span className='main-page-tablet__title-center'>{t('main_page.MARKETING_SERVICES')}</span>
          <span className='main-page-tablet__title-gray'>{t('main_page.MARKETING_SERVICES')}</span>
        </div>
      </div>

      <div className='main-page-tablet__rhomb'></div>

      <img src={mainHero} alt="mainHero" className='main-page-tablet__main-img' loading='lazy'/>

      <img src={rectanglesTablet} alt="rectangles" className='main-page-tablet__rectangles-bottom' loading='lazy'/>

      <img src={logoFooter} alt="footer logo" className='main-page-tablet__logo-footer' loading="lazy"/>
    </div>
  )
}

export default MainPageTablet