const animationTeamPage = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("animateTeamPageDown");

      setTimeout(() => {
        currentElement.classList.remove("flex");
        currentElement.classList.remove("animateTeamPageDown");
      }, 2000)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("animateTeamPageUp");
      currentElement.classList.add("flex");

    setTimeout(() => {
      currentElement.classList.remove("animateTeamPageUp");
    }, 2000)
    },

    transformToTop(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("transformTeamPageUp");
      
      setTimeout(() => {
        currentElement.classList.remove("transformTeamPageUp");
        currentElement.classList.remove("flex");
      }, 1800)
    },
    
    transformToDown(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("flex");
      currentElement.classList.add("transformTeamPageDown");

      setTimeout(() => {
        currentElement.classList.remove("transformTeamPageDown");
      }, 1800)
    }
  }
}


const animationTeamCardInfo = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("animateTeamCardInfoHidden");
    },

    appearance(id) {
      const currentElement = document.getElementById(id);

      setTimeout(() => {
        currentElement.classList.add("animateTeamCardInfoBlock");
        currentElement.classList.add("flex");
      }, 2000)
    }
  }
}


export const TEAM_WRAPP = 'TEAM_WRAPP';
export const TEAM_CARD_INFO = 'TEAM_CARD_INFO';


export const allAnimationsTeamPage = {
  TEAM_WRAPP: animationTeamPage(),
  TEAM_CARD_INFO: animationTeamCardInfo(),
}
