import React from 'react';
import './LogoIdentityTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import logoIdentitySlide1 from '../../../../images/LogoIdentity/logo-identity-tablet-1.png';
import logoIdentitySlide2 from '../../../../images/LogoIdentity/logo-identity-tablet-2.png';
// import logoIdentitySlide3 from '../../../../images/LogoIdentity/logo-identity-tablet-3.png';
import logoIdentitySlide7 from '../../../../images/LogoIdentity/logo-identity-7.png';
import { useTranslation } from 'react-i18next';

import logoIdentity5 from '../../../../images/LogoIdentity/logo-identity-5.png';
import leftBottomGroup from '../../../../images/LogoIdentity/left-bottom-group.svg'

const LogoIdentityTablet = () => {
  const { t } = useTranslation();

  return (
    <div className='logo-identity-tablet'>
      <div className='logo-identity-tablet__content'>
        <div className="main-swiper-tablet__title">
          {t('LOGO')} 
          <span className="swiper-main-tablet__ampersand">&nbsp;{t('AND')}&nbsp;</span>
          <span className="swiper-title-tablet__underline">{t('IDENTITY')}</span>
        </div>

        <div className="main-swiper-tablet__wrapper logo-identity-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet"
          >  
            <SwiperSlide>
              <div className="logo-identity-tablet__wrapp">
                <img src={logoIdentitySlide1} alt="logo identity" className="logo-identity-tablet__slide" loading="lazy"/>
                <img src={logoIdentitySlide2} alt="logo identity" className="logo-identity-tablet__slide" loading="lazy"/>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="logo-identity-tablet__wrapp-second">
                <div className="logo-identity-tablet__slide">
                    <div className="logo-identity-second__top">
                        <img src={logoIdentity5} alt="logoIdentity" loading="lazy"/>
                        <div className="logo-identity-second__top-block">
                          <div className="logo-identity-second__top-block-text">{t('logo_identity.TEXT_TOP')}</div>
                        </div>
                    </div>
                    <img src={leftBottomGroup} alt="logoIdentity" className="logo-identity-second__top-bottom" loading="lazy"/>
                </div>

                <div className="logo-identity-tablet__slide logo-identity-tablet__slide-bottom">
                  <div className="logo-identity-second__bottom">
                    <div className="logo-identity-second__bottom-text">{t('logo_identity.TEXT_BOTTOM')}</div>
                  </div>
                  <img src={logoIdentitySlide7} alt="" loading="lazy"/>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default LogoIdentityTablet