const animationOurClientTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenOurClientTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenOurClientTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showOurClientTitle");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showOurClientTitle");
            }, 1800)
        },
    }
}


const animationOurClientContent = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenOurClientsContent");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenOurClientsContent");
            }, 1200)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showOurClientsContent");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showOurClientsContent");
            }, 1800)
        },
    }
}

  export const OUR_CLIENTS_TITLE = 'OUR_CLIENTS_TITLE';
  export const OUR_CLIENTS_CONTENT = 'OUR_CLIENTS_CONTENT';

  
  export const allAnimationsOurClientsPage = {
    OUR_CLIENTS_TITLE: animationOurClientTitle(),
    OUR_CLIENTS_CONTENT: animationOurClientContent(),
  }
  