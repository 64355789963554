const animation3dGraphics = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hidden3dGraphics");
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hidden3dGraphics");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("show3dGraphics");
            currentElement.classList.add("flex");
    
            setTimeout(() => {
                currentElement.classList.remove("show3dGraphics");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTop3dGraphics");

            setTimeout(() => {
              currentElement.classList.remove("transformToTop3dGraphics");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDown3dGraphics");

            setTimeout(() => {
              currentElement.classList.remove("transformToDown3dGraphics");
            }, 2000)
        },
    }
}

const animation3dGraphicsTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hidden3dGraphicsTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hidden3dGraphicsTitle");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("show3dGraphicsTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("show3dGraphicsTitle");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTop3dGraphicsTitle");
    
            setTimeout(() => {
              currentElement.classList.remove("transformToTop3dGraphicsTitle");
              currentElement.classList.remove("block");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("block");
            currentElement.classList.add("transformToDown3dGraphicsTitle");

            setTimeout(() => {
              currentElement.classList.remove("transformToDown3dGraphicsTitle");
            }, 2000)
        },
    }
}


const animation3dGraphicsButton = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hidden3dGraphicsButton");
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hidden3dGraphicsButton");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("show3dGraphicsButton");
            currentElement.classList.add("flex");
    
            setTimeout(() => {
                currentElement.classList.remove("show3dGraphicsButton");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTop3dGraphicsButton");

            setTimeout(() => {
              currentElement.classList.remove("transformToTop3dGraphicsButton");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDown3dGraphicsButton");

            setTimeout(() => {
              currentElement.classList.remove("transformToDown3dGraphicsButton");
            }, 2000)
        },
    }
}

export const CONTENT_3d_GRAPHICS_TITLE = 'CONTENT_3d_GRAPHICS_TITLE';
export const CONTENT_3d_GRAPHICS_WRAPPER = 'CONTENT_3d_GRAPHICS_WRAPPER';
export const CONTENT_3d_GRAPHICS_BUTTON = 'CONTENT_3d_GRAPHICS_BUTTON';


export const allAnimationsContent3dGraphicsPage = {
    CONTENT_3d_GRAPHICS_TITLE: animation3dGraphicsTitle(),
    CONTENT_3d_GRAPHICS_WRAPPER: animation3dGraphics(),
    CONTENT_3d_GRAPHICS_BUTTON: animation3dGraphicsButton(),
}
