const animationTitleTeam = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("hiddenTitleTeam");

      setTimeout(() => {
        currentElement.classList.remove("flex");
        currentElement.classList.remove("hiddenTitleTeam");
      }, 1800)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("showTitleTeam");
      currentElement.classList.add("flex");

      setTimeout(() => {
        currentElement.classList.remove("showTitleTeam");
      }, 1800)
    },

    transformToTop(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("transformToTopTitleTeam");

      setTimeout(() => {
        currentElement.classList.remove("transformToTopTitleTeam");
        currentElement.classList.remove("flex");
      }, 2000)
    },

    transformToDown(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("flex");
      currentElement.classList.add("transformToDownTitleTeam");

      setTimeout(() => {
        currentElement.classList.remove("transformToDownTitleTeam");
      }, 2000)
    }
  }
}

const animationSculptureTeam = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("hiddenSculptureTeam");

      setTimeout(() => {
        currentElement.classList.remove("block");
        currentElement.classList.remove("hiddenSculptureTeam");
      }, 2000)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("showSculptureTeam");
      currentElement.classList.add("block");

      setTimeout(() => {
        currentElement.classList.remove("showSculptureTeam");
      }, 1800)
    },

    transformToTop(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("transformToTopSculptureTeam");
      
      setTimeout(() => {
        currentElement.classList.remove("transformToTopSculptureTeam");
        currentElement.classList.remove("block");
      }, 2000)
    },
    
    transformToDown(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("block");
      currentElement.classList.add("transformToDownSculptureTeam");
      
      setTimeout(() => {
        currentElement.classList.remove("transformToDownSculptureTeam");
      }, 2000)
    }
  }
}


  const animationRectagleTopTeam = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenRectanglesTeam");

        setTimeout(() => {
          currentElement.classList.remove("block");
          currentElement.classList.remove("hiddenRectanglesTeam");
        }, 2000)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showRectanglesTeam");
        currentElement.classList.add("block");

        setTimeout(() => {
          currentElement.classList.remove("showRectanglesTeam");
        }, 1800)
      },

      transformToTop(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("transformToTopRectanglesTeam");
        
        setTimeout(() => {
          currentElement.classList.remove("transformToTopRectanglesTeam");
          currentElement.classList.remove("block");
        }, 2000)
      },
      
      transformToDown(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("block");
        currentElement.classList.add("transformToDownRectanglesTeam");
        
        setTimeout(() => {
          currentElement.classList.remove("transformToDownRectanglesTeam");
        }, 2000)
      }
    }
  }




  const animationRectagleBorderTeam = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenRectangleBorderTeam");

        setTimeout(() => {
          currentElement.classList.remove("block");
          currentElement.classList.remove("hiddenRectangleBorderTeam");
        }, 800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showRectangleBorderTeam");
        currentElement.classList.add("block");

      setTimeout(() => {
        currentElement.classList.remove("showRectangleBorderTeam");
      }, 1800)
      },

      
      transformToTop(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("transformToTopRombhTeam");
      
      setTimeout(() => {
        currentElement.classList.remove("transformToTopRombhTeam");
        currentElement.classList.remove("block");
      }, 2000)
    },
    
    transformToDown(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("block");
      currentElement.classList.add("transformToDownRombhTeam");
      
      setTimeout(() => {
        currentElement.classList.remove("transformToDownRombhTeam");
      }, 2000)
    }
    }
  }

  
  const animationStickyTape = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("animateStickyReverse");

        setTimeout(() => {
          currentElement.classList.remove("block");
          currentElement.classList.remove("animateStickyReverse");
        }, 800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("animateStickyTape");
        currentElement.classList.add("block");

        setTimeout(() => {
          currentElement.classList.add("transformTeamStickyTape");
          currentElement.classList.remove("animateStickyTape");
        }, 1800)
      },

      transformToTop(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("transformToTopStickyTapeTeam");
        
        setTimeout(() => {
          currentElement.classList.remove("transformToTopStickyTapeTeam");
          currentElement.classList.remove("block");
        }, 2000)
      },
      
      transformToDown(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("block");
        currentElement.classList.add("transformToDownStickyTapeTeam");
        
        setTimeout(() => {
          currentElement.classList.remove("transformToDownStickyTapeTeam");
        }, 2000)
      }
    }
  }




  const animationTeamDescrPage = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("animateTeamDescrPageUp");

        setTimeout(() => {
          currentElement.classList.remove("block");
          currentElement.classList.remove("animateTeamDescrPageUp");
        }, 2000)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("animateTeamDescrPageDown");
        currentElement.classList.add("block");
      }
    }
  }

export const TEAM_DESCR_TITLE = 'TEAM_DESCR_TITLE';
export const TEAM_HEADER_RECTANGLES = 'TEAM_HEADER_RECTANGLES';
export const TEAM_RECTANGLE_BORDER = 'TEAM_RECTANGLE_BORDER';
export const TEAM_IMG_HERO = 'TEAM_IMG_HERO';
export const TEAM_DESCR_STROKES = 'TEAM_DESCR_STROKES';
export const TEAM_DESCR_DOTS = 'TEAM_DESCR_DOTS';
export const TEAM_DESCR_WAVES = 'TEAM_DESCR_WAVES';
export const TEAM_DESCR_TEXT = 'TEAM_DESCR_TEXT';
export const TEAM_PLAY_ARROWS = 'TEAM_PLAY_ARROWS';
export const TEAM_LOGO_FOOTER = 'TEAM_LOGO_FOOTER';
export const TEAM_STICKY_TAPE = 'TEAM_STICKY_TAPE';

export const PAGE_TEAM = 'PAGE_TEAM';

export const TEAM_DESCR_WRAPPER = 'TEAM_DESCR_WRAPPER';

const TRANSFORM_CLASSES_BY_IDS = {
  TEAM_DESCR_STROKES: {
    TOP: "transformToTopStrokesTeam",
    DOWN: "transformToDownStrokesTeam"
  },
  TEAM_DESCR_WAVES:  {
    TOP: "transformToTopWavesTeam",
    DOWN: "transformToDownWavesTeam"
  },
  TEAM_DESCR_DOTS: {
    TOP: "transformToTopDotsTeam",
    DOWN: "transformToDownDotsTeam"
  },
  TEAM_DESCR_TEXT: {
    TOP: "transformToTopDescrTextTeam",
    DOWN: "transformToDownDescrTextTeam"
  },
  TEAM_PLAY_ARROWS: {
    TOP: "transformToTopPlayArrowsTeam",
    DOWN: "transformToDownPlayArrowsTeam"
  },
  TEAM_LOGO_FOOTER: {
    TOP: "transformToTopFooterLogoTeam",
    DOWN: "transformToDownFooterLogoTeam"
  }

}


const animationHiddenDiesTeam = () => {
  return {
    hide(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.add("hiddenDiesTeam");

      setTimeout(() => {
        currentElement.classList.remove("flex");
        currentElement.classList.remove("hiddenDiesTeam");
      }, 0)
    },

    appearance(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.add("hiddenDiesTeam");

      setTimeout(() => {
        currentElement.classList.add("flex");
      }, 1800)

      setTimeout(() => {
        currentElement.classList.remove("showDiesTeam");
        currentElement.classList.remove("hiddenDiesTeam");
      }, 2000)
    },

    transformToTop(id) {
      const currentElement = document.getElementById(id);
      const currentClass = TRANSFORM_CLASSES_BY_IDS[id]?.TOP;
      
      if (currentClass) {
        currentElement.classList.add(currentClass);
      }
      
      setTimeout(() => {
        currentElement.classList.remove('flex');
        currentElement.classList.remove(currentClass);
      }, 2000)
    },

    transformToDown(id) {
      const currentElement = document.getElementById(id);
      const currentClass = TRANSFORM_CLASSES_BY_IDS[id]?.DOWN;
      currentElement.classList.add('flex');

      if (currentClass) {
        currentElement.classList.add(currentClass);
      } 

      setTimeout(() => {
        currentElement.classList.remove(currentClass);
      }, 2000)
    }
  }
}


export const allAnimationsTeamDescrPage = {
    TEAM_DESCR_TITLE: animationTitleTeam(),
    TEAM_HEADER_RECTANGLES: animationRectagleTopTeam(),
    TEAM_RECTANGLE_BORDER: animationRectagleBorderTeam(),
    TEAM_IMG_HERO: animationSculptureTeam(),
    TEAM_DESCR_STROKES: animationHiddenDiesTeam(),
    TEAM_DESCR_DOTS: animationHiddenDiesTeam(),
    TEAM_DESCR_WAVES: animationHiddenDiesTeam(),
    TEAM_DESCR_TEXT: animationHiddenDiesTeam(),
    TEAM_PLAY_ARROWS: animationHiddenDiesTeam(),
    TEAM_LOGO_FOOTER: animationHiddenDiesTeam(),
    TEAM_STICKY_TAPE: animationStickyTape(),

    TEAM_DESCR_WRAPPER: animationTeamDescrPage()
}
