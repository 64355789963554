import React from 'react';
import './SwiperCorporateBook.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";

import "swiper/css/free-mode";

import corporateBook1 from '../../../../images/CorporateBook/corporate-book-1.jpg';
import corporateBook2 from '../../../../images/CorporateBook/corporate-book-2.jpg';
import corporateBook3 from '../../../../images/CorporateBook/corporate-book-3.jpg';
import corporateBook4 from '../../../../images/CorporateBook/corporate-book-4.jpg';

import { AnimationComponent } from '../../../AnimationComponent';
import { allAnimationsCorporateBookPage, CORPORATE_BOOK_SWIPER_WRAPPER, CORPORATE_BOOK_TITLE } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const SwiperCorporateBook = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div>
     <AnimationComponent
        animationAction={animationAction} 
        animation={allAnimationsCorporateBookPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={11}
    >
        <span className="swiper-main__title corporate-book__title" id={CORPORATE_BOOK_TITLE}>{t('PRINTING_DESIGN')} <span className="swiper-title__underline"></span> {t('CORPORATE_BOOK')}</span>

        <div className="main-swiper__wrapper corporate-book__wrapper" id={CORPORATE_BOOK_SWIPER_WRAPPER}>
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper corporate-book-swiper"
          >
            <SwiperSlide>
              <div className="corporate-book">
                  <div className="corporate-book__left">
                      <img src={corporateBook1} alt="" className="corporate-book__left-one" loading="lazy"/>
                      <div className="corporate-book__left-bottom">
                          <img src={corporateBook2} alt="" className="corporate-book__left-bottom-first" loading="lazy"/>
                          <img src={corporateBook3} alt="" className="corporate-book__left-bottom-second" loading="lazy"/>
                      </div>
                  </div>
                  <img src={corporateBook4} alt="" className="corporate-book__right" loading="lazy"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </AnimationComponent>
    </div>
  )
}

export default SwiperCorporateBook
