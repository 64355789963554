import React from 'react'
import './TeamTabletCard.scss';

const TeamTabletCard = ({ img, name, position, background }) => {
  return (
    <div className='team-tablet-card'>
       <img src={img} alt="person" className="team-tablet-card__img" loading="lazy"/>
        <div className="team-tablet-card__info" style={background}>
          <span className="team-tablet-card__info-name">{name}</span>
          <span className="team-tablet-card__info-position">{position}</span>
        </div>
    </div>
  )
}

export default TeamTabletCard