import React from 'react';
import './OurClients.scss';

import queenMary from '../../../images/OurClients/queen-mary.svg';
import dtek from '../../../images/OurClients/dtek.svg';
import glovo from '../../../images/OurClients/glovo.svg';
import kiyvstar from '../../../images/OurClients/kiyvstar.svg';
import mediaGroup from '../../../images/OurClients/media-group.svg';
import nestle from '../../../images/OurClients/nestle.svg';
import nlo from '../../../images/OurClients/nlo-tv.svg';
import darnytsia from '../../../images/OurClients/darnytsia.png';
import game_world from '../../../images/OurClients/game-world.png';
import ukraine_pavillion from '../../../images/OurClients/ukraine_pavillion.png';
import roche from '../../../images/OurClients/roche.svg';
import ukraine from '../../../images/OurClients/ukraine.svg';
import yakaboo from '../../../images/OurClients/yakaboo.svg';
import rectanglesGroup from '../../../images/OurClients/rectangles-group.svg';
import room8group from '../../../images/OurClients/R8G-logo.svg';

import { AnimationComponent } from '../../AnimationComponent';
import { allAnimationsOurClientsPage, OUR_CLIENTS_CONTENT, OUR_CLIENTS_TITLE } from './utils/animations';
import { useTranslation } from 'react-i18next';

export const OurClients = ({currentPageIndex, animationAction, prevPage}) => {
  const { t } = useTranslation();
  
  return (
    <div className="our-clients">
      <AnimationComponent 
        animationAction={animationAction} 
        animation={allAnimationsOurClientsPage} 
        prevPage={prevPage} 
        currentPageIndex={currentPageIndex}
        pageIndex={26}
      >
        <div id={OUR_CLIENTS_TITLE} className="our-clients__title">
          <img src={rectanglesGroup} alt="rectangles group" className="our-clients__rect-group" loading="lazy"/>
          <span className="our-clients__title-text">{t('our_clients_page.TITLE')}</span>
        </div>

        <div className="our-clients__content" id={OUR_CLIENTS_CONTENT}>
            <span className="our-clients__content-worldwide">{t('our_clients_page.WORLDWIDE')}</span>
            <div className="our-clients__content-block-world">
              <img src={queenMary} alt="queenMary" loading="lazy"/>
              <img src={dtek} alt="dtek" loading="lazy"/>
              <img src={glovo} alt="glovo" loading="lazy"/>
              <img src={ukraine_pavillion} alt="ukraine_pavillion" loading="lazy"/>
              <img src={game_world} alt="game_world" className="our-clients__game-world" loading="lazy"/>
              <img src={room8group} alt="room8group" className="our-clients__room8group" loading="lazy"/>
            </div>
            <span className="our-clients__content-ukraine">{t('our_clients_page.UKRAINE')}</span>
            <div className="our-clients__content-block-ukraine">
              <img src={nestle} alt="nestle" loading="lazy"/>
              <img src={roche} alt="roche" loading="lazy"/>
              <img src={darnytsia} alt="darnytsia" loading="lazy" className="our-clients__darnytsia"/>
              <img src={mediaGroup} alt="mediaGroup" loading="lazy"/>
            </div>
            <div className="our-clients__content-block-general">
              <img src={yakaboo} alt="yakaboo" loading="lazy"/>
              <img src={kiyvstar} alt="kiyvstar" loading="lazy"/>
              <img src={nlo} alt="nlo" loading="lazy"/>
              <img src={ukraine} alt="ukraine" loading="lazy"/>
            </div>
        </div>

      </AnimationComponent>
    </div>
  )
}



export default OurClients