const animationTopTitle = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id)
        currentElement.classList.add("hiddenTitle");

        setTimeout(() => {
          currentElement.classList.remove("flex");
          currentElement.classList.remove("hiddenTitle");
        }, 1800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showTitle");
        currentElement.classList.add("flex");

      setTimeout(() => {
        currentElement.classList.remove("showTitle");
      }, 2000)
      }
    }
  }

  const animationContentText = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenContentText");

        setTimeout(() => {
            currentElement.classList.remove("flex");
            currentElement.classList.remove("hiddenContentText");
        }, 800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showContentText");

        setTimeout(() => {
            currentElement.classList.add("flex");
        }, 800)
  
        setTimeout(() => {
          currentElement.classList.remove("showContentText");
        }, 2000)
      }
    }
  }


  const animationHiddenDies = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id)
        currentElement.classList.add("hiddenDiesWho");

        setTimeout(() => {
          currentElement.classList.remove("block");
          currentElement.classList.remove("hiddenDiesWho");
        }, 0)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenDiesWho");
  
        setTimeout(() => {
          currentElement.classList.add("block");
        }, 1800)

        setTimeout(() => {
          currentElement.classList.remove("showDiesWho");
        }, 2000)
      }
    }
  }

  
  
  export const WHO_TOP = 'WHO_TOP';
  export const WHO_CONTENT_TEXT = 'WHO_CONTENT_TEXT';
  export const WHO_PLAY_ARROWS = 'WHO_PLAY_ARROWS';
  export const WHO_LOGO_FOOTER = 'WHO_LOGO_FOOTER';

  
  export const allAnimationsInWhoArePage = {
    WHO_TOP: animationTopTitle(),
    WHO_CONTENT_TEXT: animationContentText(),
    WHO_PLAY_ARROWS: animationHiddenDies(),
    WHO_LOGO_FOOTER: animationHiddenDies(),
  }
  