import React from 'react';
import './CreationProdTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import creationProdSlide1 from '../../../../images/CreationProd/creation-prod-1.png';
import creationProdSlide2 from '../../../../images/CreationProd/creation-prod-2.png';
import creationProdSlide3 from '../../../../images/CreationProd/creation-prod-3.png';
import creationProdSlide4 from '../../../../images/CreationProd/creation-prod-4.png';
import { useTranslation } from 'react-i18next';

const CreationProdTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='creation-prod-tablet'>
      <div className='creation-prod-tablet__content'>
        <div className="main-swiper-tablet__title creation-prod-tablet__title">
          <span>{t('creation_prod_page.TITLE_CREATION')} <span className="swiper-main-tablet__ampersand"> {t('AND')} </span> {t('creation_prod_page.TITLE_PRODUCTION')}</span> 
           <span>{t('creation_prod_page.TITLE_B2B')}<span className="swiper-title-tablet__underline"></span></span> 
        </div>

        <div className="main-swiper-tablet__wrapper creation-prod-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet"
          >  
            <SwiperSlide>
              <div className="creation-prod-tablet__wrapp">
                <div className="creation-prod-tablet__slide-top">
                  <img className="creation-prod-tablet__slide-top-img" src={creationProdSlide1} alt="" loading="lazy"/>
                  <div className="creation-prod-tablet__slide-top-text-block">{t('DESIGN_LOWER_CASE')}</div>
                </div>
                
                <div className="creation-prod-tablet__slide-bottom">
                  <img className="creation-prod-tablet__slide-bottom-img" src={creationProdSlide2} alt="" loading="lazy"/>
                  <div className="creation-prod-tablet__slide-bottom-text-block">{t('PRODUCTION')}</div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
            <div className="creation-prod-tablet__wrapp">
                <div className="creation-prod-tablet__slide-top">
                  <img className="creation-prod-tablet__slide-top-img creation-prod-tablet__slide-top-img-next" src={creationProdSlide3} alt="" loading="lazy"/>
                  <div className="creation-prod-tablet__slide-top-text-block">{t('DESIGN_LOWER_CASE')}</div>
                </div>
                
                <div className="creation-prod-tablet__slide-bottom">
                  <img className="creation-prod-tablet__slide-bottom-img" src={creationProdSlide4} alt="" loading="lazy"/>
                  <div className="creation-prod-tablet__slide-bottom-text-block">{t('PRODUCTION')}</div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default CreationProdTablet