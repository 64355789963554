import React from 'react';
import './ExhibitionTablet.scss';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation } from "swiper";
import "swiper/css/free-mode";

import exhibition1 from '../../../../images/Exhibition/exhibition-slide2-1.jpg';
import exhibition2 from '../../../../images/Exhibition/exhibition-slide2-2.jpg';
import exhibition3 from '../../../../images/Exhibition/exhibition-slide2-3.jpg';

import exhibitionSlide1 from '../../../../images/Exhibition/exhibition-1.jpg';
import exhibitionSlide2 from '../../../../images/Exhibition/exhibition-2.jpg';
import exhibitionSlide3 from '../../../../images/Exhibition/exhibition-3.jpg';

import { useTranslation } from 'react-i18next';

const ExhibitionTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='exhibition-tablet'>
      <div className='exhibition-tablet__content'>
        <div className="main-swiper-tablet__title">
          <span>{t('DESIGNS')}<span className="swiper-main-tablet__ampersand"> {t('AND')} </span></span>
          <span>{t('EXHIBITION')} {t('BOOTH')}<span className="swiper-title-tablet__underline"></span></span> 
        </div>

        <div className="main-swiper-tablet__wrapper exhibition-tablet__wrapper">
          <Swiper
            speed={1000}
            freeMode={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="main-swiper-tablet"
          >  
            <SwiperSlide>
              <div className="exhibition-tablet__wrapp">
                <img src={exhibition1} alt="exhibition slide" className="exhibition-tablet__slide-first" loading="lazy"/>
                <img src={exhibition2} alt="exhibition slide" className="exhibition-tablet__slide-first" loading="lazy"/>
                <img src={exhibition3} alt="exhibition slide" className="exhibition-tablet__slide-first" loading="lazy"/>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="exhibition-tablet__wrapp">
                <img src={exhibitionSlide1} alt="exhibition slide" className="exhibition-tablet__slide" loading="lazy"/>
                <img src={exhibitionSlide2} alt="exhibition slide" className="exhibition-tablet__slide" loading="lazy"/>
                <img src={exhibitionSlide3} alt="exhibition slide" className="exhibition-tablet__slide" loading="lazy"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default ExhibitionTablet