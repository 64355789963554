import React from 'react';
import './MarketingServicesTablet.scss';

import rhombWhisDies from '../../../images/MarketingServices/marketing-serv-sculpture-tablet.svg';
import rectanglesBottom from '../../../images/ConceptDev/rectangles-bottom-tablet.svg';
import logoFooter from '../../../images/MarketingServices/marketing-services-logo.svg';
import rhombGray from '../../../images/MarketingServices/marketing-rhomb-gray.svg';
import playArrows from '../../../images/ConceptDev/play-arrows.svg';
import waves from '../../../images/MarketingServices/waves-tablet.svg';
import { useTranslation } from 'react-i18next';

const MarketingServicesTablet = () => {
  const { t } = useTranslation();

  return (
    <div className='marketing-services-tablet' id="marketing-services">
			<img src={rhombGray} alt="rhombGray" className='marketing-services-tablet__rhomb-gray' loading="lazy"/>
			<div className='marketing-services-tablet__title'>{t('marketing_serv.TITLE_MARKETING')} <br />{t('marketing_serv.TITLE_SERVICES')}</div>
			<img src={rhombWhisDies} alt="rhomb whis dies" className='marketing-services-tablet__rhomb-dies' loading="lazy"/>
			<img src={rectanglesBottom} alt="rectangles bottom" className='marketing-services-tablet__rectangles-bottom' loading="lazy"/>
			<img src={logoFooter} alt="logo footer" className='marketing-services-tablet__logo-footer' loading="lazy"/>
      <img src={playArrows} alt="play arrows" className='marketing-services-tablet__play-arrows' loading="lazy"/>
      <img src={waves} alt="waves" className='marketing-services-tablet__waves' loading="lazy"/>
		</div>
  )
}

export default MarketingServicesTablet
