import brand from '../../images/WhatWeDoing/brand.png';
import material from '../../images/WhatWeDoing/material.png';
import visual from '../../images/WhatWeDoing/visual.png';
import creation from '../../images/WhatWeDoing/creation.png';
import websites from '../../images/WhatWeDoing/websites.png';
import event_organization from '../../images/WhatWeDoing/event-organization.jpg';

export const getWhatDoingData = (t) => {
  return [
    {
      title: <div>{t('CONCEPT_DEV_VISUAL')} <span className='what-doing-tablet__title-ampersand'>{t('AND')}</span>  <span className='what-doing-tablet__title-last-word'>{t('DESIGN')}</span></div> ,
      id: 1,
      moveToPageIndex: 8,
      moveToPageTablet: "#concept-development",
      itemsList: [
        {
          img: brand,
          id: 1,
          title: <span>{t('what_we_doing.BRAND_IDENTITY')} <br /> {t('what_we_doing.NAMING_KEY_VISUAL')}</span> ,  
        },
        {
          img: material,
          id: 2,
          title: <span>{t('what_we_doing.MATERIAL_DESIGN')}</span>,
          subtitle: <span>{t('what_we_doing.MATERIAL_DESIGN_ETC')}</span>,  
        },
        {
          img: visual,
          id: 3,
          title: <span>{t('what_we_doing.VISUAL_BRAND_CONTENT')}</span>,
          subtitle: <span>{t('what_we_doing.VISUAL_BRAND_CONTENT_ETC')}</span>,  
        },
      ]
    },
    {
      title: <span>{t('what_we_doing.MARKETING_SERVICES_TITLE')} <span className='what-doing-tablet__title-last-word'></span></span>,
      id: 2,
      moveToPageIndex: 19,
      moveToPageTablet: "#marketing-services",
      itemsList: [
        {
          img: creation,
          id: 4,
          title: <span>{t('what_we_doing.CREATION_PRODUCTION')}</span>,  
        },
        {
          img: websites,
          id: 5,
          title: <span>{t('what_we_doing.WEBSITES_LEARNING')}</span>,
        },
        {
          img: event_organization,
          id: 6,
          title: <span>{t('what_we_doing.EVENT_ORGANIZATION')}</span>,
        },
      ]
    },
  ]
}
