const animationMainImgEyesEurope = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenImgEyesEurope");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenImgEyesEurope");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showImgEyesEurope");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showImgEyesEurope");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopTitleEyesEurope");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownTitleEyesEurope");
            }, 2000)
        },
    }
}


const animationTitleEyesEurope = () => {
    return {
        
        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopTitleEyesEurope");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownTitleEyesEurope");
            }, 2000)
        },

        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenTitleEyesEurope");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenTitleEyesEurope");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showTitleEyesEurope");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showTitleEyesEurope");
            }, 2000)
        },

    }
}


const animationRombhBorderEyesEurope = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenRombhBorderEyesEurope");
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenRombhBorderEyesEurope");
            }, 1500)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showRombhBorderEyesEurope");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showRombhBorderEyesEurope");
            }, 1800)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopTitleEyesEurope");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownTitleEyesEurope");
            }, 2000)
        },
    }
}


const animationPlayArrowsEyesEurope = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenPlayArrowsEyesEurope");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenPlayArrowsEyesEurope");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showPlayArrowsEyesEurope");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showPlayArrowsEyesEurope");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopTitleEyesEurope");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownTitleEyesEurope");
            }, 2000)
        },
    }
}

  
const animationStrokesEyesEurope = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenStrokesEyesEurope");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenStrokesEyesEurope");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showStrokesEyesEurope");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showStrokesEyesEurope");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopTitleEyesEurope");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownTitleEyesEurope");
            }, 2000)
        },
    }
}

  
const animationDotsEyesEurope = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenDotsEyesEurope");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenDotsEyesEurope");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showDotsEyesEurope");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showDotsEyesEurope");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopTitleEyesEurope");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownTitleEyesEurope");
            }, 2000)
        },
    }
}


const animationWavesEyesEurope = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenWavesEyesEurope");
        
            setTimeout(() => {
                currentElement.classList.remove("flex");
                currentElement.classList.remove("hiddenWavesEyesEurope");
            }, 2000)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showWavesEyesEurope");
            currentElement.classList.add("flex");
        
            setTimeout(() => {
                currentElement.classList.remove("showWavesEyesEurope");
            }, 2000)
        },

        transformToTop(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("transformToTopTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToTopTitleEyesEurope");
              currentElement.classList.remove("flex");
            }, 2000)
          },
      
          transformToDown(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("flex");
            currentElement.classList.add("transformToDownTitleEyesEurope");
      
            setTimeout(() => {
              currentElement.classList.remove("transformToDownTitleEyesEurope");
            }, 2000)
        },
    }
}
  
  



export const EYES_EUROPE_ROMBH_BORDER = 'EYES_EUROPE_ROMBH_BORDER';
export const EYES_EUROPE_TITLE = 'EYES_EUROPE_TITLE';
export const EYES_EUROPE_MAIN_IMG = 'EYES_EUROPE_MAIN_IMG';
export const EYES_EUROPE_PLAY_ARROW = 'EYES_EUROPE_PLAY_ARROW';
export const EYES_EUROPE_DOTS = 'EYES_EUROPE_DOTS';
export const EYES_EUROPE_STROKES = 'EYES_EUROPE_STROKES';
export const EYES_EUROPE_WAVES = 'EYES_EUROPE_WAVES';



export const allAnimationsEyesEuropePage = {
    EYES_EUROPE_ROMBH_BORDER: animationRombhBorderEyesEurope(),
    EYES_EUROPE_TITLE: animationTitleEyesEurope(),
    EYES_EUROPE_MAIN_IMG: animationMainImgEyesEurope(),
    EYES_EUROPE_PLAY_ARROW: animationPlayArrowsEyesEurope(),
    EYES_EUROPE_DOTS: animationDotsEyesEurope(),
    EYES_EUROPE_STROKES: animationStrokesEyesEurope(),
    EYES_EUROPE_WAVES: animationWavesEyesEurope(),
}
