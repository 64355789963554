const animationHeroImg = () => {
  return {
    appearance(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.remove("none");
      currentElement.classList.add("showSculpture");
    },

    hide(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.remove("showSculpture");
      currentElement.classList.add("hiddenSculpture");

      setTimeout(() => {
        currentElement.classList.add("none");
        currentElement.classList.remove("hiddenSculpture");
      }, 1800)
    }
  }
}

const animationBgRhomb = () => {
  return {
    appearance(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.remove("none");
      currentElement.classList.add("showRhomb");
    },

    hide(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.remove("showRhomb");
      currentElement.classList.add("hiddenRhomb");

      setTimeout(() => {
        currentElement.classList.add("none");
        currentElement.classList.remove("hiddenRhomb");
      }, 2000)
    }
  }
}


const animationHidingDies = () => {
  return {
    appearance(id) {
      const currentElement = document.getElementById(id)
      currentElement.classList.remove("none");
      currentElement.classList.add("showDies");
    },

    hide(id) {
      const currentElement = document.getElementById(id);
      currentElement.classList.remove("showDies");
      currentElement.classList.add("hiddenDies");

      setTimeout(() => {
        currentElement.classList.add("none");
        currentElement.classList.remove("hiddenDies");
      }, 900)
    }
  }
}


export const MAIN_IMG_HERO = 'MAIN_IMG_HERO';
export const MAIN_BG_RHOMB = 'MAIN_BG_RHOMB';
export const MAIN_TITLES = 'MAIN_TITLES';
export const MAIN_RECTANGLE_TOP = 'MAIN_RECTANGLE_TOP';
export const MAIN_DOTS = 'MAIN_DOTS';
export const MAIN_STROKES = 'MAIN_STROKES';
export const MAIN_WAVES = 'MAIN_WAVES';
export const MAIN_RECTANGLE_FOOTER = 'MAIN_RECTANGLE_FOOTER';
export const MAIN_LOGO_FOOTER = 'MAIN_LOGO_FOOTER';
export const MAIN_TITLE = 'MAIN_TITLE'


export const allAnimationsInMainPage = {
  MAIN_IMG_HERO: animationHeroImg(),
  MAIN_BG_RHOMB: animationBgRhomb(),
  MAIN_TITLE: animationHidingDies(),
  MAIN_TITLES: animationHidingDies(),
  MAIN_RECTANGLE_TOP: animationHidingDies(),
  MAIN_DOTS: animationHidingDies(),
  MAIN_STROKES: animationHidingDies(),
  MAIN_WAVES: animationHidingDies(),
  MAIN_RECTANGLE_FOOTER: animationHidingDies(),
  MAIN_LOGO_FOOTER: animationHidingDies()
}
