import React from 'react';
import './MainRectangleTop.scss';

const MainRectangleTop = ({id}) => {
  return (
    <div className="main__rectangle-top" id={id}>
      <div className="header__rectangles">
        <div className="header__rectangle-gradient"></div>
        <div className="header__rectangle-border"></div>
      </div>
    </div>
  )
}

export default MainRectangleTop