const animationExhibition = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenExhibition");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenExhibition");
            }, 1600)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showExhibition");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showExhibition");
            }, 2000)
        },
    }
}


const animationExhibitionTitle = () => {
    return {
        hide(id) {
            const currentElement = document.getElementById(id)
            currentElement.classList.add("hiddenExhibitionTitle");
        
            setTimeout(() => {
                currentElement.classList.remove("block");
                currentElement.classList.remove("hiddenExhibitionTitle");
            }, 1800)
        },
        
        appearance(id) {
            const currentElement = document.getElementById(id);
            currentElement.classList.add("showExhibitionTitle");
            currentElement.classList.add("block");
        
            setTimeout(() => {
                currentElement.classList.remove("showExhibitionTitle");
            }, 1800)
        },
    }
}





export const EXHIBITION_TITLE = 'EXHIBITION_TITLE';
export const EXHIBITION_SWIPER_WRAPPER = 'EXHIBITION_SWIPER_WRAPPER';


export const allAnimationsExhibitionPage = {
    EXHIBITION_SWIPER_WRAPPER: animationExhibition(),
    EXHIBITION_TITLE: animationExhibitionTitle(),
}
