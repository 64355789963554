import person1 from '../../images/Team/person-1.png';
import person2 from '../../images/Team/person-2.png';
import square from '../../images/Team/square.png';
import person3 from '../../images/Team/person-3.png';
import person4 from '../../images/Team/person-4.png';
import person5 from '../../images/Team/person-5.png';
import person6 from '../../images/Team/person-6.png';
import person7 from '../../images/Team/person-7.png';
import person8 from '../../images/Team/person-8.png';
import person9 from '../../images/Team/person-9.png';
import person10 from '../../images/Team/person-10.png';
import person11 from '../../images/Team/person-11.png';
import person12 from '../../images/Team/person-12.png';

import ukrLogo from '../../images/Team/ukraine-logo-12.png';

import person13 from '../../images/Team/person-13.png';
import person14 from '../../images/Team/person-14.png';
import person15 from '../../images/Team/person-15.png';


export const getTeamData = (t) => {
  return [
    { 
      id: 7,
      img: person6,
      name: <span>{t('team_people.person_6')}</span>,
      position: "Inspiration Leader, Partner",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 2,
      img: person2,
      name: <span>{t('team_people.person_2')}</span>,
      position: "Inspiration Leader, Partner",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 3,
      img: square,
    },
    { 
      id: 1,
      img: person1,
      name: <span>{t('team_people.person_1')}</span>,
      position: "New business director",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 8,
      img: person7,
      name: <span>{t('team_people.person_7')}</span>,
      position: "Managing Director",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 6,
      img: person5,
      name: <span>{t('team_people.person_5')}</span>,
      position: "Designer",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 4,
      img: person3,
      name: <span>{t('team_people.person_3')}</span>,
      position: "Copywriter, scenarist",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 5,
      img: person4,
      name: <span>{t('team_people.person_4')}</span>,
      position: "Art Director",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 9,
      img: person8,
      name: <span>{t('team_people.person_8')}</span>,
      position: "Account Executive Manager",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 10,
      img: person9,
      name: <span>{t('team_people.person_9')}</span>,
      position: "Account Manager",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 11,
      img: ukrLogo,
    },
    { 
      id: 12,
      img: person10,
      name: <span>{t('team_people.person_10')}</span>,
      position: "Visual content producer",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 13,
      img: person11,
      name: <span>{t('team_people.person_11')}</span>,
      position: "Video director, editor",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 14,
      img: person12,
      name: <span>{t('team_people.person_12')}</span>,
      position: "Account Executive Manager",
      background: {backgroundColor: "#FDB813"}
    },

    { 
      id: 15,
      img: person13,
      name: <span>{t('team_people.person_13')}</span>,
      position: "Web/app developer",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 16,
      img: person14,
      name: <span>{t('team_people.person_14')}</span>,
      position: "UX/UI Designer",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 17,
      img: person15,
      name: <span>{t('team_people.person_15')}</span>,
      position: "BA",
      background: {backgroundColor: "#FDB813"}
    },
    { 
      id: 18,
      img: square,
    },
  ]
}
