const animationContentWhatDoing = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenContentWhatDoing");
  
        setTimeout(() => {
          currentElement.classList.remove("flex");
          currentElement.classList.remove("hiddenContentWhatDoing");
        }, 1800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showContentWhatDoing");
        currentElement.classList.add("flex");
  
        setTimeout(() => {
          currentElement.classList.remove("showContentWhatDoing");
        }, 1800)
      },
    }
  }

  const animationContentTitleWhatDoing = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenContentTitleWhatDoing");
  
        setTimeout(() => {
          currentElement.classList.remove("flex");
          currentElement.classList.remove("hiddenContentTitleWhatDoing");
        }, 1800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showContentTitleWhatDoing");
        currentElement.classList.add("flex");
  
        setTimeout(() => {
          currentElement.classList.remove("showContentTitleWhatDoing");
        }, 1800)
      },
    }
  }


  const animationRombhWithLinesWhatDoing = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenRombhWithLinesWhatDoing");
  
        setTimeout(() => {
          currentElement.classList.remove("flex");
          currentElement.classList.remove("hiddenRombhWithLinesWhatDoing");
        }, 1800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showRombhWithLinesWhatDoing");
        currentElement.classList.add("flex");
  
        setTimeout(() => {
          currentElement.classList.remove("showRombhWithLinesWhatDoing");
        }, 1800)
      },
    }
  }


  const animationTopRectanglesWhatDoing = () => {
    return {
      hide(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("hiddenTopRectanglesWhatDoing");
  
        setTimeout(() => {
          currentElement.classList.remove("flex");
          currentElement.classList.remove("hiddenTopRectanglesWhatDoing");
        }, 1800)
      },
  
      appearance(id) {
        const currentElement = document.getElementById(id);
        currentElement.classList.add("showTopRectanglesWhatDoing");
        currentElement.classList.add("flex");
  
        setTimeout(() => {
          currentElement.classList.remove("showTopRectanglesWhatDoing");
        }, 1800)
      },
    }
  }


export const WHAT_DOING_RECTANGLE_GROUP = 'WHAT_DOING_RECTANGLE_GROUP';
export const WHAT_DOING_WRAPPER = 'WHAT_DOING_WRAPPER';
export const WHAT_DOING_TITLE = 'WHAT_DOING_TITLE';
export const WHAT_DOING_TOP_RECTANGLES = 'WHAT_DOING_TOP_RECTANGLES';


export const allAnimationsWhatDoingPage = {
    WHAT_DOING_WRAPPER: animationContentWhatDoing(),
    WHAT_DOING_TITLE: animationContentTitleWhatDoing(),
    WHAT_DOING_RECTANGLE_GROUP: animationRombhWithLinesWhatDoing(),
    WHAT_DOING_TOP_RECTANGLES: animationTopRectanglesWhatDoing()
}
