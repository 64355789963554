import { useLayoutEffect, useState } from 'react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import './App.scss';
import { DesktopComponent } from './views/DesktopComponent/DesktopComponent';
import { TabletComponent } from './views/TabletComponent/TabletComponent';
import { initReactI18next } from 'react-i18next';
import { EN_TRANSLATION } from './langs/en/translation';
import { UK_TRANSLATION } from './langs/uk/translation';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    // lng: 'en', // Після перезавантаження встановлює мову 
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    resources: {
      en: {
        translation: EN_TRANSLATION
      },
      uk: {
        translation: UK_TRANSLATION
      },
    },
    // backend: {
    //   loadPath: './langs/{{lng}}/translation.json',
    // },
    // react: {
    //   useSuspense: false
    // }
  });

const App = () => {
  const [width] = useWindowSize();
  const isTablet = width <= 1200;

  if (isTablet) {
    return <TabletComponent />
  }

  return (
    <DesktopComponent />
  )
}

export default App;
