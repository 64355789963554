import React from 'react';
import './WebsitesTablet.scss';

import laptop1 from '../../../images/Websites/websites-laptop-1.png';
import laptop2 from '../../../images/Websites/websites-laptop-2.png';
import laptop3 from '../../../images/Websites/websites-laptop-3.png';
import rhombDots from '../../../images/Websites/rhomb-dots-tablet.svg';
import { useTranslation } from 'react-i18next';

const WebsitesTablet = () => {
  const { t } = useTranslation();
  
  return (
    <div className='websites-tablet'>
      <div className='websites-tablet__top'>
        <span className='websites-tablet__top-title'>{t('websites.TITLE')}</span>
      </div>

      <img src={rhombDots} alt="rhomb with dots" className='websites-tablet__rhomb' loading="lazy"/>

      <div className='websites-tablet__content'>
        <img src={laptop1} alt="laptop" className='websites-tablet__laptop' loading="lazy"/>
        <img src={laptop2} alt="laptop" className='websites-tablet__laptop' loading="lazy"/>
        <img src={laptop3} alt="laptop" className='websites-tablet__laptop' loading="lazy"/>
      </div>
    </div>
  )
}

export default WebsitesTablet
